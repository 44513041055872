import React, { useState } from 'react';
import { useSigner } from 'wagmi';
import { Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import store from 'store';
import { setAppLoading } from "store/app/reducer";
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import { INFTDetailSale } from "../../../../models/NFT";
import { StyledTableCell, StyledTableRow2 } from '../sale-history/_styles';

interface IProps {
  offers: INFTDetailSale[];
  matches?: boolean;
  onRefresh?: () => void;
  address: string;
  tokenId: number;
}

const NFTOffers: React.FunctionComponent<IProps> = ({ offers, matches, onRefresh, address, tokenId }) => {
  const { data } = useSigner();
  const [open, setOpen] = useState(false);
  const [selectedSaleId, setSelectedSaleId] = useState<number | undefined>(undefined);

  const onAccept = async () => {
    if (!selectedSaleId || !data) {
      return;
    }
    store.dispatch(setAppLoading(true));
  };

  return (
    <>
      <CMPButton
        rounded
        onClick={() => setOpen(true)}
        sx={{
          height: matches ? 58 : 68,
          width: matches ? '100%' : 300,
          mt: 4,
          fontSize: '18px',
          ml: 2,
        }}
      >
        {Strings.offers}
      </CMPButton>
      <DialogModal open={open} onClose={() => setOpen(false)} title={Strings.offers}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">BUYER</StyledTableCell>
              <StyledTableCell align="center">PRICE</StyledTableCell>
              <StyledTableCell align="center">ACTION</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.map(offer => {
              const price = Utils.removeRC20Decimal(+offer.price);
              return (
                <StyledTableRow2 key={offer.saleId}>
                  <StyledTableCell align="center">
                    <Typography variant="body1" component="span" fontWeight="bold">
                      {offer.buyer.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography variant="body1" component="span" fontWeight="bold">
                      {price}
                    </Typography>
                    <Typography variant="body2" component="span" fontWeight="bold" sx={{ ml: 1, mb: '2px' }}>
                      {offer.bidToken.symbol}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CMPButton onClick={() => setSelectedSaleId(offer.saleId)}>{Strings.acceptOffers}</CMPButton>
                  </StyledTableCell>
                </StyledTableRow2>
              );
            })}
          </TableBody>
        </Table>
      </DialogModal>
      <DialogModal
        maxWidth="xs"
        open={selectedSaleId !== undefined}
        onClose={() => setSelectedSaleId(undefined)}
        title="Confirmation"
        contentProps={{ className: 'd-flex justify-content-center flex-column' }}
      >
        <Typography textAlign="center" fontWeight="bold">
          Are you sure you want to accept this offer?
        </Typography>
        <CMPButton onClick={onAccept} sx={{ height: 58, mt: 4 }}>
          {Strings.confirm}
        </CMPButton>
      </DialogModal>
    </>
  );
};
export default NFTOffers;
