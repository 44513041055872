import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Strings from 'utils/Strings';
import FilterContainer from 'components/common/filter-container';
import CMPInput from 'components/global/input';
import {useAppSelector} from "../../../../../store/hooks";
import {setCharacterId} from "../../../../../store/marketplace/reducer";

interface IProps {
  name?: string;
}

const CharacterID: React.FunctionComponent<IProps> = ({ name = Strings.characterId }) => {
  const dispatch = useDispatch();
  const { characterId } = useAppSelector(state => state.marketplace);

  const onFilter = (value: string) => {
    dispatch(setCharacterId(value));
  };

  return (
    <FilterContainer
      className="mt-3"
      title={name}
      body={<CMPInput filled fullWidth placeholder={name} value={characterId} onChanged={onFilter} className="my-3" />}
    />
  );
};
export default CharacterID;
