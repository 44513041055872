import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { InputAdornment, OutlinedInputProps } from '@mui/material';
import ColorPalette from 'utils/ColorPalette';
import StylesCMPInput from './_styles';
import {useAppSelector} from "../../../store/hooks";

export interface ICMPInputProps extends OutlinedInputProps {
  onChanged?: (val: string) => void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  hasError?: boolean;
  filled?: boolean;
  defaultVariant?: boolean;
  prevent?: boolean;
}

const CMPInput: React.FunctionComponent<ICMPInputProps> = ({
  onChanged,
  startIcon,
  endIcon,
  hasError,
  filled,
  defaultVariant = true,
  prevent,
  ...props
}) => {
  const appTheme = useAppSelector(state => state.app.appTheme);
  const changed = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (prevent) {
      event.preventDefault();
      event.stopPropagation();
    }
    const val = event.target.value;
    if (onChanged) {
      onChanged(val);
    }
  };

  return (
    <StylesCMPInput
      onChange={changed}
      onFocus={event => {
        if (prevent) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
      onClick={event => {
        if (prevent) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
      sx={{
        borderColor: hasError ? ColorPalette.error : 'transparent',
        backgroundColor: theme =>
          filled
            ? defaultVariant
              ? theme.palette.mode === 'dark'
                ? theme.palette.background.default
                : theme.palette.filterCard
              : theme.palette.inputBg
            : undefined,
      }}
      classes={{
        root: appTheme === 'dark' ? 'input-dark' : 'input-light',
        notchedOutline: clsx({ 'border-0': filled }),
      }}
      endAdornment={startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>}
      startAdornment={endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>}
      {...props}
    />
  );
};
export default CMPInput;
