import React, { FC } from 'react';
import { Button, SxProps, Theme } from '@mui/material';

interface Props {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
}

const StakingTableToolbarAction: FC<Props> = ({ title, disabled, onClick, sx }) => {
  return (
    <Button variant="outlined" size="small" onClick={onClick} sx={{ marginRight: '10px', ...sx }} disabled={disabled}>
      {title}
    </Button>
  );
};

export default StakingTableToolbarAction;
