import React from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import store from 'store';
import { ISnackbar } from 'models/@types';
import {setSnackbar} from "../../../store/app/reducer";
import {useAppSelector} from "../../../store/hooks";

export const showSnackbar = (data: ISnackbar): void => {
  store.dispatch(setSnackbar(data));
};

const CMPSnackbar: React.FunctionComponent = () => {
  const snackbar = useAppSelector(state => state.app.snackbar);
  if (!snackbar) {
    return null;
  }

  const onClose = () => {
    store.dispatch(setSnackbar(null));
  };

  return (
    <Snackbar
      open
      autoHideDuration={snackbar.message.length < 50 ? 3000 : 6000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={onClose}
      sx={{
        minWidth: 320,
        boxSizing: 'content-box',
        marginTop: 2,
        '@media screen and (min-width: 600px)': { paddingInlineStart: '84px', marginTop: 0 },
        '& .MuiPaper-root': { borderRadius: '18px', padding: '12px', alignItems: 'center' },
      }}
    >
      <Alert severity={snackbar.severity} sx={{ width: '100%', fontWeight: 'bold' }} variant="filled">
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};
export default CMPSnackbar;
