import React, { FC } from 'react';
import { useAccount, useSigner } from 'wagmi';
import { BigNumber } from 'ethers';
import { Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ClaimLP } from 'services/Farms';
import CMPButton from 'components/global/button';
import { selectFarmPools } from '../../../store/farm/hooks';
import { selectGlobalMagicPrice } from '../../../store/global/hooks';
import { useAppSelector } from '../../../store/hooks';
import { newBalance } from "../../../store/wallet/reducer";
import { CleanBN, CleanLocaleBN } from '../functions';

interface StatLineProps {
  text: string;
  value: BigNumber;
}

const StatLine: FC<StatLineProps> = ({ text, value }) => {
  const magic = useAppSelector(selectGlobalMagicPrice);
  return (
    <Card sx={{ borderRadius: '24px' }}>
      <CardHeader title={text} sx={{ textAlign: 'center' }} />
      <CardContent sx={{ paddingTop: 0, paddingBottom: '16px !important', textAlign: 'center' }}>
        <Typography variant="body1">{CleanLocaleBN(value).toLocaleString()}</Typography>
        <Typography variant="body1" fontSize="0.8rem">
          {'$' + (CleanBN(value) * magic).toLocaleString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

const MagicRewardsCard: FC = () => {
  const { address } = useAccount();
  const { data } = useSigner();
  const pools = useAppSelector(selectFarmPools);
  const walletMagic = useAppSelector(state => state.wallet.tokens.MAGIC);
  const magic = walletMagic || newBalance();
  const handleClaim = async () => {
    if (!data) {
      return;
    }
    await ClaimLP(
      pools.filter(p => p.balance.gt(0)).map(p => p.pid),
      data,
    );
  };
  return (
    <Card
      sx={{
        borderRadius: '24px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        ...(address ? {} : { pointerEvents: 'none', userSelect: 'none', filter: 'blur(10px)' }),
      }}
    >
      <CardHeader title="MAGICK Rewards" sx={{ textAlign: 'center' }} />
      <CardContent sx={{ paddingBottom: 0, paddingTop: 0, marginBottom: 'auto' }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <StatLine text="In Wallet" value={magic.balance} />
          </Grid>
          <Grid item xs={6}>
            <StatLine
              text="To Harvest"
              value={pools.length > 0 ? pools.map(p => p.earned).reduce((p, c) => p.add(c)) : BigNumber.from(0)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ marginTop: 'auto' }}>
        <CMPButton
          fullWidth
          disabled={pools.length === 0 || pools.reduce((n, p) => n.add(p.earned), BigNumber.from(0)).eq(0)}
          onClick={handleClaim}
        >
          Harvest All
        </CMPButton>
      </CardActions>
    </Card>
  );
};

export default MagicRewardsCard;
