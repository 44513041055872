import React, { FC, useState } from 'react';
import { Button, Card, CardContent, FormControlLabel, Switch, Link } from '@mui/material';
import { Pool } from '../../../store/farm/types';
import ManageLiquidityModal from './modals/ManageLiquidityModal';
import SwapIFrameModal from './modals/SwapIFrameModal';
import { useAccount } from 'wagmi';

interface Props {
  staked: boolean;
  setStaked: (staked: boolean) => void;
  pool: Pool;
  add: boolean;
  addOpen: boolean;
  setAddOpen: (open: boolean, add: boolean) => void;
}

const ActionBar: FC<Props> = ({ staked, setStaked, pool, addOpen, setAddOpen, add }) => {
  const [swapOpen, setSwapOpen] = useState<boolean>(false);
  const { address } = useAccount();
  return (
    <Card sx={{ borderRadius: '24px' }}>
      <CardContent
        sx={{
          padding: 0,
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '0 !important',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel
          control={<Switch />}
          checked={staked}
          onChange={() => setStaked(!staked)}
          label="Staked"
          disabled={!address}
        />
        <Button
          component={Link}
          sx={{ borderRadius: '24px', marginTop: '6px', marginBottom: '6px' }}
          size="small"
          href="https://traderjoexyz.com/avalanche/trade?outputCurrency=0x9a8e0217cd870783c3f2317985c57bf570969153"
          target="_blank"
        >
          Swap
        </Button>
        <Button
          component={Link}
          sx={{ borderRadius: '24px', marginTop: '6px', marginBottom: '6px' }}
          size="small"
          href="https://traderjoexyz.com/avalanche/pool/v1/0x9a8e0217cd870783c3f2317985c57bf570969153/AVAX"
          target="_blank"
        >
          Add Liquidity
        </Button>
      </CardContent>
      <SwapIFrameModal open={swapOpen} setOpen={setSwapOpen} />
      <ManageLiquidityModal open={addOpen} setOpen={(open: boolean) => setAddOpen(open, add)} pool={pool} add={add} />
    </Card>
  );
};

export default ActionBar;
