import React from 'react';
import { Close } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Theme,
  useTheme,
  useMediaQuery,
  styled,
  Dialog,
  IconButton,
} from '@mui/material';
import ButtonBorder from 'assets/images/button-border.svg';
import Divider from 'assets/images/divider.svg';
import ElfMale from 'assets/images/nfts/elf-4286.png';
import CMPImage from 'components/global/image';
import MintDetails from '../components/MintDetails';

const StyledMintBtnContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: '14px 32px 21px 32px',
}));

const StyledMintBtn = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: 0,
  backgroundColor: '#000',
  color: theme.palette.golden,
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: 1,
  '&:disabled': {
    backgroundColor: '#00000020',
  },
  '&:hover': {
    backgroundColor: '#15120d',
  },
}));

interface IProps {
  open: boolean;
  onMint: () => void;
  onClose: () => void;
}

const ElvesMintReceiptModal: React.FunctionComponent<IProps> = ({ open, onMint, onClose }) => {
  const hiddenSm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const theme = useTheme();

  const onConfirm = () => {
    onMint();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { backgroundColor: `transparent`, backgroundImage: 'none' } }}
      componentsProps={{ backdrop: { style: { backgroundColor: 'rgba(0,0,0,0.9)' } } }}
    >
      <Card
        sx={{
          overflow: 'unset',
          borderRadius: '16px',
          background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
        }}
      >
        <IconButton sx={{ position: 'absolute', right: '16px', top: '16px' }} onClick={onClose}>
          <Close />
        </IconButton>
        <CardContent sx={{ padding: 4.5 }}>
          <Grid container spacing={8} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} md={6} hidden={hiddenSm}>
              <CMPImage
                asset={{
                  src: ElfMale,
                  alt: 'male',
                }}
                width="100%"
                height="100%"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: 28, textAlign: 'left' }}>You&apos;re almost there!</Typography>
              <CMPImage
                asset={{
                  src: Divider,
                  alt: 'devider',
                }}
                width="100%"
                style={{ margin: '16px 0' }}
              />
              <Typography sx={{ fontSize: 18, textAlign: 'left' }}>Here is a review of your mint details:</Typography>
              <MintDetails />
              <Box sx={{ position: 'relative', margin: 'auto', width: 240 }}>
                <CMPImage
                  asset={{
                    src: ButtonBorder,
                    alt: 'button boder',
                  }}
                  width="100%"
                />
                <StyledMintBtnContainer>
                  <StyledMintBtn onClick={onConfirm}>CONFIRM</StyledMintBtn>
                </StyledMintBtnContainer>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default ElvesMintReceiptModal;
