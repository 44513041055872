import { PaletteMode } from "@mui/material";
import { AppStoreState } from './types';
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISnackbar} from "../../models/@types";
import {Category} from "../../models/Category";

const initialState: AppStoreState = {
  appLoading: false,
  appTheme: 'dark',
  snackbar: null,
  categories: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setAppTheme: (state, action: PayloadAction<PaletteMode>) => {
      state.appTheme = action.payload;
    },
    setSnackbar: (state, action: PayloadAction<ISnackbar | null>) => {
      state.snackbar = action.payload;
    },
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
  }
});

export const { setAppLoading, setAppTheme, setSnackbar, setCategories } = appSlice.actions;

export default appSlice.reducer;