import React from 'react';
import {
  Box,
  Stack,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Theme,
  styled,
} from '@mui/material';
import ButtonBorder from 'assets/images/button-border.svg';
import Div from 'components/global/div';
import CMPImage from 'components/global/image';
import ArrowListDesktop from '../../../assets/images/arrow-list-desktop.svg';
import ArrowListMobile from '../../../assets/images/arrow-list-mobile.svg';
import CardBorderBottom from '../../../assets/images/character-card-border-bottom.svg';
import CardBorderTop from '../../../assets/images/character-card-border-top.svg';
import DiamondBoxCrystal from '../../../assets/images/diamond-box-crystal.svg';
import DiamondBoxHome from '../../../assets/images/diamond-box-home.svg';
import DividerReverse from '../../../assets/images/divider-reversed.svg';
import Divider from '../../../assets/images/divider.svg';
import Elf113 from '../../../assets/images/nfts/elf-113-crop.png';

const StyledMintBtnContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: '20px 46px 27px 46px',
}));

const StyledMintBtn = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: '#000',
  color: theme.palette.golden,
}));

const ElvesCard = () => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
  const hiddenSm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const theme = useTheme();

  return (
    <Box>
      {matches && (
        <CMPImage
          asset={{
            src: CardBorderTop,
            alt: 'card boder top',
          }}
          width="100%"
          style={{ marginBottom: -10 }}
        />
      )}
      <Box
        sx={{
          padding: '40px',
          borderRadius: matches ? 0 : 4,
          background: theme.palette.mintBg,
        }}
      >
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: matches ? 'flex' : 'none', alignItems: 'flex-end', justifyContent: 'center' }}
          >
            <CMPImage
              asset={{
                src: Elf113,
                alt: 'elf113',
              }}
              width="100%"
              style={{ maxWidth: 480, marginBottom: -40 }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
            <Stack direction="column" spacing={matches ? 9 : 4.5} sx={{ width: '100%', alignItems: 'center' }}>
              {matches ? (
                <Box sx={{ position: 'relative', width: 340 }} hidden={hiddenSm}>
                  <CMPImage
                    asset={{
                      src: ButtonBorder,
                      alt: 'button boder',
                    }}
                    width="100%"
                  />
                  <StyledMintBtnContainer>
                    <StyledMintBtn>
                      <Typography
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          fontSize: 40,
                          fontWeight: 'bold',
                          lineHeight: 1,
                        }}
                      >
                        ELVES
                      </Typography>
                    </StyledMintBtn>
                  </StyledMintBtnContainer>
                </Box>
              ) : (
                <Typography sx={{ fontSize: 28, textAlign: 'left' }}>
                  <b style={{ color: '#f9d089' }}>Elves</b> are created by Kaze, the Air Elemental, and have a strong
                  affinity toward nature.
                </Typography>
              )}
              {matches ? (
                <Stack direction={{ lg: 'row', md: 'column' }} spacing={{ lg: 3, md: 1 }}>
                  <Typography sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sx={{ fontSize: 20, marginRight: 1 }}>Homeland:</Typography>
                    <Typography sx={{ fontSize: 28, fontWeight: 'medium' }}>Aelfaria</Typography>
                  </Typography>
                  <Typography sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sx={{ fontSize: 20, marginRight: 1 }}>Crystal:</Typography>
                    <Typography sx={{ fontSize: 28, fontWeight: 'medium' }}>Air Shard</Typography>
                  </Typography>
                </Stack>
              ) : (
                <Div>
                  <CMPImage
                    asset={{
                      src: Divider,
                      alt: 'devider',
                    }}
                    width="100%"
                    style={{ marginBottom: 40, maxWidth: 380 }}
                  />
                  <Div style={{ marginBottom: 40 }}>
                    <CMPImage
                      asset={{
                        src: DiamondBoxHome,
                        alt: 'home',
                      }}
                      width="150"
                      style={{ marginBottom: 28 }}
                    />
                    <Typography>
                      <Typography sx={{ fontSize: 28, fontWeight: 'medium' }}>Homeland:</Typography>
                      <Typography sx={{ fontSize: 20 }}>Aelfaria</Typography>
                    </Typography>
                  </Div>
                  <Div>
                    <CMPImage
                      asset={{
                        src: DiamondBoxCrystal,
                        alt: 'crystal',
                      }}
                      width="150"
                      style={{ marginBottom: 28 }}
                    />
                    <Typography>
                      <Typography sx={{ fontSize: 28, fontWeight: 'medium' }}>Crystal:</Typography>
                      <Typography sx={{ fontSize: 20 }}>Air Shard</Typography>
                    </Typography>
                  </Div>
                  <CMPImage
                    asset={{
                      src: DividerReverse,
                      alt: 'devider',
                    }}
                    width="100%"
                    style={{ marginTop: 40, maxWidth: 380 }}
                  />
                </Div>
              )}
              <List>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: matches ? 56 : 45 }}>
                    <CMPImage
                      asset={{
                        src: matches ? ArrowListDesktop : ArrowListMobile,
                        alt: 'arrow',
                      }}
                      width="27"
                      height="13"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Elegant and regal" primaryTypographyProps={{ fontSize: '18px' }} />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: matches ? 56 : 45 }}>
                    <CMPImage
                      asset={{
                        src: matches ? ArrowListDesktop : ArrowListMobile,
                        alt: 'arrow',
                      }}
                      width="27"
                      height="13"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Accelerated mana regeneration" primaryTypographyProps={{ fontSize: '18px' }} />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: matches ? 56 : 45 }}>
                    <CMPImage
                      asset={{
                        src: matches ? ArrowListDesktop : ArrowListMobile,
                        alt: 'arrow',
                      }}
                      width="27"
                      height="13"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Experts in attuning to artifact powers"
                    primaryTypographyProps={{ fontSize: '18px' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: matches ? 56 : 45 }}>
                    <CMPImage
                      asset={{
                        src: matches ? ArrowListDesktop : ArrowListMobile,
                        alt: 'arrow',
                      }}
                      width="27"
                      height="13"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Advanced wielders of magic" primaryTypographyProps={{ fontSize: '18px' }} />
                </ListItem>
              </List>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {matches && (
        <CMPImage
          asset={{
            src: CardBorderBottom,
            alt: 'card boder bottom',
          }}
          width="100%"
          style={{ marginTop: -10 }}
        />
      )}
    </Box>
  );
};

export default ElvesCard;
