import { SaleType } from 'store/market/types';

export interface ISaleType {
  text: string;
  type: SaleType;
}

export const saleTypesItems: ISaleType[] = [
  { type: SaleType.AUCTION, text: 'Auction' },
  { type: SaleType.FIXED, text: 'Fixed Price' },
];
