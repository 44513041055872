import React from 'react';
import moment from 'moment';
import { Theme, useMediaQuery } from '@mui/material';
import Description from 'components/global/description';
import Div from 'components/global/div';
import GDivider from 'components/global/divider';
import ProgressBar from 'components/global/progress-bar';
import AuctionTimerIcon from 'components/icons/main/AuctionTimer';
interface IProps {
  description: {
    icon: React.ReactNode;
    value: string;
    price: string;
  };
  progressBar?: {
    highest: string;
    lowest: string;
    // progressPercentage: number;
  };
}

const InformationDescription: React.FunctionComponent<IProps> = ({ description, progressBar }) => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'), {
    noSsr: true,
  });

  const getRemainDate = (endDate: string) => {
    const endTime = moment(parseInt(endDate));
    const now = moment();
    const remainingTime = moment.duration(endTime.diff(now));

    let remainingDate = '';
    if (remainingTime.seconds() > 0) {
      if (remainingTime.minutes() > 0) {
        remainingDate += remainingTime.minutes().toString() + ` min${remainingTime.minutes() > 1 ? 's' : ''}`;
      }
      if (remainingTime.hours() > 0) {
        remainingDate = remainingTime.hours().toString() + ` hour${remainingTime.hours() > 1 ? 's' : ''}`;
      }
      if (remainingTime.days() > 0) {
        remainingDate = remainingTime.days().toString() + ` day${remainingTime.days() > 1 ? 's' : ''}`;
      }
      remainingDate += ' remaining';
    } else {
      remainingDate = 'sale expired';
    }

    return remainingDate;
  };

  const getPercentage = (startTime: string, endTime: string) => {
    const now = moment().format('x');
    const totalDuration = parseInt(endTime) - parseInt(startTime);
    const remainDuration = parseInt(endTime) - parseInt(now);
    const remainPercentage = (1 - remainDuration / totalDuration) * 100;
    return remainPercentage;
  };

  return (
    <Div
      className={`d-flex ${matches ? 'flex-column align-items-start justify-content-start' : 'align-items-center'}`}
      sx={{ mt: matches ? 2 : 4, width: '100%', maxWidth: 444 }}
    >
      <Description
        icon={description.icon}
        value={description.value}
        price={description.price}
        valueProps={{ variant: 'h5', fontSize: '30px', sx: { mx: 1 } }}
        priceProps={{ sx: { pt: 1 } }}
        hasPrice
      />
      {progressBar ? (
        <>
          <GDivider sx={{ mx: 4 }} variant="fullWidth" />
          <ProgressBar
            remain={getRemainDate(progressBar.lowest)}
            durationIcon={<AuctionTimerIcon />}
            barProps={{
              sx: { height: 7, width: matches ? '160px' : '220px' },
              value: getPercentage(progressBar.highest, progressBar.lowest),
            }}
          />
        </>
      ) : null}
    </Div>
  );
};

export default InformationDescription;
