import React from 'react';
import { CustomIconProps } from '../index';

const FarmIcon: React.FunctionComponent<CustomIconProps> = ({ active }) => {
  const black = '#111111'
  const gold = '#ffd58c'
  const white = '#E9E9E9'

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="36"
      height="36"
      viewBox="0 0 600 600"
      enableBackground="new 0 0 600 600"
      xmlSpace="preserve"
    >
      <g id="Plants">
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M30.7135201,122.7824478 c0,0,64.1778107-3.027916,67.1170349,33.5663834C97.8305588,156.3488312,68.5484314,184.5001678,30.7135201,122.7824478z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M98.7269592,13.4677811 c0,0,32.1612396,55.6202621,1.0033569,75.0359344C99.7303162,88.5037155,61.2759666,75.41922,98.7269592,13.4677811z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M30.7135201,268.2465515 c0,0,64.1778107-3.0279236,67.1170349,33.5663757C97.8305588,301.8129272,68.5484314,329.9642639,30.7135201,268.2465515z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M27.5134048,195.0078583 c0,0,64.177803-3.0279083,67.1170349,33.566391C94.6304398,228.5742493,65.3483124,256.7255859,27.5134048,195.0078583z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M97.8305588,377.0005188V88.5037155 V377.0005188z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M164.9475861,155.9868622 c0,0-64.177803-3.0279083-67.1170273,33.566391C97.8305588,189.5532532,127.1126785,217.7045898,164.9475861,155.9868622z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M164.9475861,301.4509583 c0,0-64.177803-3.0279236-67.1170273,33.5663757C97.8305588,335.017334,127.1126785,363.1686707,164.9475861,301.4509583z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M168.1477051,228.2122803 c0,0-64.177803-3.0279236-67.1170349,33.5663757C101.0306702,261.778656,130.3127899,289.9299927,168.1477051,228.2122803z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M435.0523987,133.0398102 c0,0,64.1778259-3.0279236,67.1170349,33.5663757C502.1694336,166.6061859,472.8873291,194.7575378,435.0523987,133.0398102z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M503.0658569,23.7251396 c0,0,32.1612549,55.620266,1.0033569,75.0359344C504.0692139,98.7610779,465.6148682,85.6765823,503.0658569,23.7251396z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M435.0523987,278.5039062 c0,0,64.1778259-3.0279236,67.1170349,33.5663757C502.1694336,312.070282,472.8873291,340.2216187,435.0523987,278.5039062z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M431.8522949,205.265213 c0,0,64.1777954-3.0279083,67.1170349,33.566391C498.9693298,238.831604,469.6871948,266.9829407,431.8522949,205.265213z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M502.1694336,387.2578735V98.7610779 V387.2578735z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M569.286499,166.2442322 c0,0-64.1778259-3.0279236-67.1170654,33.5663757C502.1694336,199.8106079,531.4515991,227.9619446,569.286499,166.2442322z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M569.286499,311.708313 c0,0-64.1778259-3.0279236-67.1170654,33.5663757C502.1694336,345.2746887,531.4515991,373.4260254,569.286499,311.708313z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M572.4865723,238.469635 c0,0-64.1777954-3.0279083-67.1170044,33.5663757C505.3695679,272.0360107,534.6516724,300.1873779,572.4865723,238.469635z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M368.7171021,160.300415 c0,0-64.1778259-3.0279083-67.1170349,33.566391C301.6000671,193.866806,330.8821716,222.0181427,368.7171021,160.300415z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M300.7036438,50.9857483 c0,0-32.1612244,55.6202698-1.0033569,75.0359421C299.7002869,126.0216904,338.1546631,112.9371948,300.7036438,50.9857483z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M368.7171021,305.7644958 c0,0-64.1778259-3.0278931-67.1170349,33.5664062C301.6000671,339.3309021,330.8821716,367.4822388,368.7171021,305.7644958z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M371.9172058,232.5258331 c0,0-64.1777954-3.0279236-67.1170349,33.566391C304.8001709,266.0922241,334.0823059,294.2435608,371.9172058,232.5258331z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M301.6000671,414.5184937V126.0216904 V414.5184937z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M234.483017,193.504837 c0,0,64.1778107-3.0279236,67.1170502,33.566391C301.6000671,227.071228,272.3179321,255.2225647,234.483017,193.504837z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M234.483017,338.9689331 c0,0,64.1778107-3.0279236,67.1170502,33.5663757C301.6000671,372.5353088,272.3179321,400.6866455,234.483017,338.9689331z"
        />
        <path
          fill={active ? gold : white}
          stroke="#E9E9E9"
          strokeWidth="11"
          strokeMiterlimit="10"
          d="M231.2829132,265.7302551 c0,0,64.1778107-3.0279236,67.1170197,33.5663757C298.3999329,299.2966309,269.1178284,327.4479675,231.2829132,265.7302551z"
        />
      </g>
      <g id="Field">
        <path
          fill={active ? black : gold}
          stroke="#000000"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M4.7145596,382.154541 c0,0-22.4723644,210.8112793,185.7346039,212.5292358c208.2069702,1.7180176,241.5973511-0.4147339,241.5973511-0.4147339 s158.09021,12.1891479,164.7244873-207.4495544c0,0-263.955658-29.0925293-339.8459778,42.8227234 C256.9250183,429.6422119,176.2636414,357.8196106,4.7145596,382.154541z"
        />
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M147.0009308,383.8272705L8.5924673,446.8093872 L147.0009308,383.8272705z"
        />
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M249.7123413,424.1696472L44.2895317,534.534668 L249.7123413,424.1696472z"
        />
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M342.5058594,481.430603 L164.4798126,593.8384399L342.5058594,481.430603z"
        />
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M448.8833923,532.0209351l-90.697998,62.7010498
		L448.8833923,532.0209351z"
        />
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M338.2550354,393.5240784 l239.0741272,102.0847168L338.2550354,393.5240784z"
        />
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M471.5949402,381.2930908l119.658905,46.4320374 L471.5949402,381.2930908z"
        />
        <path fill="#FFFFFF" stroke="#000000" strokeWidth="6" strokeMiterlimit="10" d="M4.3133535,488.2518921" />
        <path
          fill="none"
          stroke="#000000"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M258.5720825,431.1446228 c0,0,131.4302368,91.122467,269.7885132,129.1477966"
        />
      </g>
    </svg>
  );
};

export default FarmIcon;
