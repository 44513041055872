import { ComponentType } from 'react';
import { CustomIconProps } from 'components/icons';
import AboutIcon from 'components/icons/side-bar/AboutIcon';
import BankIcon from 'components/icons/side-bar/BankIcon';
import FarmIcon from 'components/icons/side-bar/FarmIcon';
import HomeIcon from 'components/icons/side-bar/HomeIcon';
import MapIcon from 'components/icons/side-bar/MapIcon';
import MarketIcon from 'components/icons/side-bar/MarketIcon';
import StakingIcon from 'components/icons/side-bar/StakingIcon';
import { DEV_MODE } from '../../../../constants/Statics';
import BridgeIcon from '../../../icons/side-bar/BridgeIcon';
import MintIcon from '../../../icons/side-bar/MintIcon';
// import DexIcon from 'components/icons/side-bar/DexIcon';

export interface ISideBarItem {
  icon: ComponentType<CustomIconProps>;
  text: string;
  path: string;
  type: 'INTERNAL' | 'EXTERNAL';
  disabled?: boolean;
}

const sidebarItems: ISideBarItem[] = [
  //{
  //  path: 'https://cosmicuniverse.one/',
  //  icon: HomeIcon,
  //  text: 'Home',
  //  type: 'EXTERNAL',
  //},
  {
    path: '/gallery',
    icon: MarketIcon,
    text: 'Gallery',
    type: 'INTERNAL',
  },
  //{
  //  path: '/bridge',
  //  icon: BridgeIcon,
  //  text: 'Bridge',
  //  type: 'INTERNAL',
  //},
  //{
  //  path: '/market/land/map',
  //  icon: MapIcon,
  //  text: 'Map',
  //  type: 'INTERNAL',
  //  disabled: true,
  //},
  // {
  //   path: '/dex',
  //   icon: DexIcon,
  //   text: 'DEX',
  //   type: 'INTERNAL',
  // },
  {
    path: '/staking',
    icon: StakingIcon,
    text: 'Staking',
    type: 'INTERNAL',
  },
  {
    path: '/bank',
    icon: BankIcon,
    text: 'Bank',
    type: 'INTERNAL',
  },
  {
    path: '/farms',
    icon: FarmIcon,
    text: 'Farm',
    type: 'INTERNAL',
  },
  {
    path: '/mint',
    icon: MintIcon,
    text: 'Mint',
    type: 'INTERNAL',
  },
  {
    path: 'https://info.cosmicuniverse.io/',
    icon: AboutIcon,
    text: 'About',
    type: 'EXTERNAL',
  },
];
export default sidebarItems;
