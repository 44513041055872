import { styled } from '@mui/material';

export const classes = {
  filtersCol: 'filters-col',
  listCol: 'list-col',
  odd: 'odd',
  even: 'even',
};

const NFTListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  [`& .${classes.filtersCol}`]: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '35%',
      paddingInlineEnd: 8,
    },
    [theme.breakpoints.up('lg')]: {
      width: '22%',
    },
  },
  [`& .${classes.listCol}`]: {
    width: '100%',
    marginTop: 16,
    [theme.breakpoints.up('md')]: {
      width: '65%',
      marginTop: 0,
      paddingInlineStart: 8,
    },
    [theme.breakpoints.up('lg')]: {
      width: '78%',
    },
  },
  [`& .${classes.odd}`]: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  [`& .${classes.even}`]: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
}));
export default NFTListContainer;
