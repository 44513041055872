import { styled } from '@mui/material';

export const classes = {
  icon: 'icon',
  open: 'open',
  close: 'close',
};

const DropDownDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 52,
  borderRadius: 18,
  background: theme.palette.filterBg,
  border: theme.palette.mode === 'light' ? `1px solid ${theme.palette.divider}` : undefined,
  padding: '0 16px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    height: 68,
    borderRadius: 24,
  },
  WebkitTapHighlightColor: 'transparent',
  [`& .${classes.icon}`]: {
    width: 16,
    height: 16,
    transitionDuration: 300,
  },
  [`& .${classes.open}`]: {
    transform: 'rotate(-180deg)',
    transition: '300ms ease all',
  },
  [`& .${classes.close}`]: {
    transform: 'rotate(0)',
    transition: '300ms ease all',
  },
}));
export default DropDownDiv;
