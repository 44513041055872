import React from 'react';
import Strings from 'utils/Strings';
import FilterContainer from 'components/common/filter-container';
import CMPButton from 'components/global/button';
import CMPInput from 'components/global/input';

const PetID: React.FunctionComponent = () => {
  return (
    <FilterContainer
      className="mt-3"
      title={Strings.petId}
      body={
        <div className="d-flex flex-column align-items-end my-3">
          <CMPInput filled fullWidth placeholder={Strings.petId} />
          <CMPButton className="mt-3" sx={{ width: 138, height: 48 }}>
            {Strings.apply}
          </CMPButton>
        </div>
      }
    />
  );
};
export default PetID;
