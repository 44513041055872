import appReducer from './app/reducer';
import constantsReducer from './constants/reducer';
import farmReducer from './farm/reducer';
import { dexScreenerApi } from './global/actions';
import globalReducer from './global/reducer';
import marketReducer from './market/reducer';
import marketplaceReducer from './marketplace/reducer';
import mintReducer from './mint/reducer';
import stakingReducer from './staking/reducer';
import walletReducer from './wallet/reducer';
import {configureStore} from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    app: appReducer,
    marketplace: marketplaceReducer,
    constants: constantsReducer,
    wallet: walletReducer,
    [dexScreenerApi.reducerPath]: dexScreenerApi.reducer,
    global: globalReducer,
    staking: stakingReducer,
    farm: farmReducer,
    mint: mintReducer,
    market: marketReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false}).concat(dexScreenerApi.middleware)
})

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

