import React, { MouseEvent, useState } from "react";
import TextPopover, { TextPopoverProps } from "./TextPopover";

type usePopoverProps = Omit<TextPopoverProps, 'handlePopoverClose' | 'anchorEl'>
export const usePopover = ({ title, text }: usePopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return {
    popover: <TextPopover
      title={ title }
      text={ text }
      handlePopoverClose={ handlePopoverClose }
      anchorEl={ anchorEl }
    />,
    handleOpen: handlePopoverOpen,
    handleClose: handlePopoverClose,
  }
}