import React from 'react';
import { LinearProgressProps, Typography, TypographyProps } from '@mui/material';
import Div from '../div';
import BorderLinearProgress from './_style';

interface IProps {
  // highest: string;
  // lowest: string;
  remain: string;
  durationIcon: React.ReactNode;
  barProps?: LinearProgressProps;
  priceProps?: TypographyProps;
}

// const ProgressBar: React.FunctionComponent<IProps> = ({ highest, lowest, durationIcon, barProps, priceProps }) => {
const ProgressBar: React.FunctionComponent<IProps> = ({ remain, durationIcon, barProps, priceProps }) => {
  const { sx: barSx, ...restBarProps } = barProps ?? {};

  return (
    <Div className="d-flex align-items-center">
      {durationIcon && <div className="d-flex align-items-center me-2">{durationIcon}</div>}
      <Div className="d-flex align-items-center" sx={{ mr: 2 }}>
        <Typography variant="caption" noWrap {...priceProps}>
          {remain}
        </Typography>
        <BorderLinearProgress sx={{ mx: 1, ...barSx }} variant="determinate" {...restBarProps} />
        {/* <Typography variant="caption" noWrap {...priceProps}>
          {lowest}
        </Typography> */}
      </Div>
    </Div>
  );
};

export default ProgressBar;
