import React, { useEffect, useState } from 'react';
import { utils } from 'ethers';
import { ItemTransferService, NFTTransferService } from 'services/TransferNFT';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import { NFT } from '../../../../../models/NFT';
import { useSigner } from 'wagmi';
import { Input } from '@mui/material';
import InputRow from '../InputRow';

interface IProp {
  open: boolean;
  setOpen: (open: boolean) => void;
  nft: NFT;
}

const TransferModal: React.FunctionComponent<IProp> = ({ open, setOpen, nft }) => {
  const { data } = useSigner();
  const [toAddress, setToAddress] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [qIsValid, setQIsValid] = useState<boolean>(false);

  const handleSend = async () => {
    if (!data) {
      return;
    }
    if (nft.type === 'ERC1155') {
      await ItemTransferService(nft, toAddress, Number(quantity), data);
    } else {
      await NFTTransferService(nft, toAddress, undefined, data);
    }
  };
  useEffect(() => {
    if (toAddress === '') {
      setIsValid(false);
    }
    try {
      utils.getAddress(toAddress);
      setIsValid(true);
    } catch (e) {
      setIsValid(false);
    }
  }, [toAddress, isValid]);
  useEffect(() => {
    if (quantity === '') {
      setQIsValid(false);
    } else if (isNaN(Number(quantity))) {
      setQIsValid(false);
    } else if (quantity.includes('.')) {
      setQIsValid(false);
    } else if (Number(quantity) < 1 || Number(quantity) > (nft.balance || 0)) {
      setQIsValid(false);
    } else {
      setQIsValid(true);
    }
  }, [quantity, qIsValid]);

  return (
    <DialogModal title="Transfer" open={open} onClose={() => setOpen(false)}>
      <InputRow
        title="To Address"
        inputValue={toAddress}
        onInputChange={setToAddress}
        error={toAddress !== '' && !isValid}
      />
      {nft.type === 'ERC1155' && (
        <InputRow
          title="Quantity"
          inputValue={quantity}
          onInputChange={setQuantity}
          error={quantity !== '' && !qIsValid}
        />
      )}
      <CMPButton
        fullWidth
        sx={{ minHeight: 56, marginTop: '2rem' }}
        onClick={handleSend}
        disabled={!isValid || !qIsValid}
      >
        Send
      </CMPButton>
    </DialogModal>
  );
};

export default TransferModal;
