import * as React from "react";
import { Box, Chip, Tooltip, Typography, TypographyProps } from "@mui/material";
import Strings, { stringToCapitalise } from "utils/Strings";
import { CardBody, CardDescription, CardProfessions } from "models/@types";
import Div from "components/global/div";
import FragmentWrapper from "components/template/fragment-wrapper";
import { GetProfessionIcon } from "../../../pages/nft/components/nft-body/professions/profession";
import GDivider from "../divider";

interface IProps {
  title?: string;
  name?: string;
  rank?: number;
  balance?: number;
  body?: CardBody;
  description?: CardDescription;
  titleProps?: TypographyProps<"div">;
  classic?: boolean;
  action?: React.ReactNode;
  professions?: CardProfessions;
}

const CMPCardContent: React.FunctionComponent<IProps> = ({
  title,
  name,
  body,
  rank,
  balance,
  description,
  titleProps,
  classic = false,
  action,
  professions,
}) => {
  return (
    <Div className="row" sx={{ height: "100%" }}>
      <Div className="d-flex flex-column justify-content-around col">
        {title && name ? (
          <Div>
            <Typography
              noWrap
              sx={{
                mb: "0.2rem",
                maxWidth: "100%",
                textAlign: classic ? "left" : "center",
                display: "block",
              }}
              variant="body1"
              component="span"
              {...titleProps}
            >
              {name}
            </Typography>
            <Typography
              noWrap
              sx={{
                mb: "0.6rem",
                maxWidth: "100%",
                textAlign: classic ? "left" : "center",
                display: "block",
                fontSize: "15px",
              }}
              variant="body1"
              component="span"
              {...titleProps}
            >
              {title}
            </Typography>
          </Div>
        ) : (
          title && (
            <Typography
              noWrap
              sx={{
                mb: "0.6rem",
                maxWidth: "100%",
                textAlign: classic ? "left" : "center",
              }}
              variant="body1"
              component="span"
              {...titleProps}
            >
              {title}
            </Typography>
          )
        )}
        {body && (
          <Div
            className={`d-flex align-items-center justify-content-${
              classic ? "start" : "center"
            }`}
            style={{ marginBottom: "0.75rem" }}
          >
            {body
              .filter(Boolean)
              .map(({ icon, title: bodyTitle }, index, arr) => (
                <FragmentWrapper key={`${bodyTitle}-${index}`}>
                  {icon}
                  <Typography sx={{ mx: 1 }} variant="body2" color={bodyTitle}>
                    {bodyTitle}
                  </Typography>
                  {arr.length - 1 !== index ? (
                    <GDivider sx={{ mx: 1 }} variant="fullWidth" />
                  ) : null}
                </FragmentWrapper>
              ))}
          </Div>
        )}
        {rank !== undefined && (
          <Div
            className={`d-flex align-items-center justify-content-${
              classic ? "start" : "center"
            }`}
            style={{ marginBottom: "0.75rem" }}
          >
            <Typography color="textSecondary">{Strings.rank}:</Typography>
            <Typography className="ms-3">
              {rank ? rank.toString() : "Minting"}
            </Typography>
          </Div>
        )}
        {balance && (
          <Div
            className={`d-flex align-items-center justify-content-${
              classic ? "start" : "center"
            }`}
            style={{ marginBottom: "0.75rem" }}
          >
            <Typography color="textSecondary">Owned:</Typography>
            <Typography className="ms-3">{balance}</Typography>
          </Div>
        )}
        {!classic && professions && (
          <Div
            className="d-flex align-items-center justify-content-around"
            style={{ marginBottom: "0.5rem", minHeight: 103 }}
          >
            {professions.map((profession, i) => (
              <Tooltip
                title={stringToCapitalise(profession.name)}
                key={i}
                sx={{ zIndex: 5 }}
              >
                <Box
                  className={`d-flex align-items-center justify-content-center flex-${
                    classic ? "row" : "column"
                  }`}
                >
                  <Div>{GetProfessionIcon(profession.name)}</Div>
                  <Chip
                    label={`${profession.level}`}
                    color="primary"
                    sx={{
                      paddingLeft: 1,
                      paddingRight: 1,
                      justifySelf: "center",
                      color: "white",
                      fontWeight: 800,
                    }}
                  />
                </Box>
              </Tooltip>
            ))}
          </Div>
        )}
        {description && (
          <Div
            className={`d-flex align-items-center justify-content-${
              classic ? "start" : "center"
            } flex-wrap text-center`}
          >
            {description.icon}
            <Typography
              sx={{ ml: 0.5, mr: 1.5, textAlign: "center" }}
              variant="h6"
              color="text.primary"
            >
              {description.value}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              {`$${description.price}`}
            </Typography>
          </Div>
        )}
      </Div>
      {classic && (
        <Div className="d-flex flex-column align-items-center col">
          <Div className="w-100 h-100 d-flex flex-column justify-content-between">
            {professions &&
              professions.map((profession, i) => (
                <Tooltip
                  title={stringToCapitalise(profession.name)}
                  key={i}
                  sx={{ zIndex: 5 }}
                >
                  <Box
                    className={`d-flex align-items-center justify-content-start flex-row`}
                  >
                    <Div style={{ marginRight: "1rem" }}>
                      {GetProfessionIcon(profession.name, 44)}
                    </Div>
                    <Chip
                      label={`${profession.level}`}
                      color="primary"
                      sx={{
                        paddingLeft: 1,
                        paddingRight: 1,
                        justifySelf: "center",
                        color: "white",
                        backgroundColor: (theme) => theme.palette.primary.main,
                        fontWeight: 800,
                      }}
                    />
                  </Box>
                </Tooltip>
              ))}
          </Div>
          {/* <Div className="w-100" sx={{ marginTop: -2 }}>
            {action}
          </Div> */}
        </Div>
      )}
      {!classic && (
        <Div className="w-100" style={{ marginTop: "auto" }}>
          {action}
        </Div>
      )}
    </Div>
  );
};

export default CMPCardContent;
