import { NFTAttributes, RawAttribute } from 'models/NFT';
import ElvesTraits from './elves';

export type FullAttribute = {
  type: string;
  attribute: string;
  rarity: number;
};

export const RawElfAttributesMap = (gender: string, attributes: RawAttribute[]): NFTAttributes => {
  return attributes.reduce((o, { trait_type, value }) => {
    switch (trait_type) {
      case 'Gender':
      case 'Skin Tone':
      case 'Headwear':
      case 'Hair':
      case 'Eyebrows':
      case 'Eyes':
      case 'Ears':
      case 'Mouth':
      case 'Attire':
      case 'Jewelry':
      case 'Staff': {
        o[trait_type.toLowerCase()] = value;
      }
    }
    return o;
  }, {} as NFTAttributes);
};

export const GetElfAttributeRarity = (gender: string, type: string, attribute: string): number => {
  return ElvesTraits[gender]?.[type.toLowerCase()]?.[attribute] || 100;
}


export const GetRarestElfAttribute = (attributes: NFTAttributes): FullAttribute => {
  const gender = attributes.gender;
  return Object.entries(attributes).reduce((o, [t, v]) => {
    const a = {
      type: t,
      attribute: v,
      rarity: t.toLowerCase() === 'gender' ? 50 : GetElfAttributeRarity(gender, t, v),
    };
    if (!o.rarity || o.rarity > a.rarity) {
      o = a;
    }
    return o;
  }, {} as FullAttribute);
};
