import React from 'react';
import CMPImage from 'components/global/image';

interface IProps {
  size?: 'small' | 'medium' | 'large';
  purple?: boolean;
  onClick?: () => void;
}

const Logo: React.FunctionComponent<IProps> = ({ size = 'medium', purple, onClick }) => {
  const s = size === 'small' ? 20 : 'medium' ? 50 : 50;
  return (
    <CMPImage
      asset={{
        src: `${process.env.PUBLIC_URL}/images/Logo-Gold.png`,
        alt: 'Cosmic Market Place',
      }}
      width={s}
      height={s}
      onClick={onClick}
    />
  );
};
export default Logo;
