import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Divider, Theme, Typography, useMediaQuery } from '@mui/material';
import Strings from 'utils/Strings';
import { NFTCategory } from 'models/@types';
import Categories from 'components/common/categories';
import CMPButton from 'components/global/button';
import Div from 'components/global/div';
import CMPDrawer from 'components/global/drawer';
import FilterIcon from 'components/icons/main/FilterIcon';
import Sort from '../sort';
import Filters from './components';
import {useAppSelector} from "../../../../store/hooks";
import {clearFilters} from "../../../../store/marketplace/reducer";

interface IFiltersBodyProps {
  handleApplyFilter: () => void;
}
interface IFiltersSectionProps {
  mobileView?: boolean;
  showCategories?: boolean;
  buttonSize?: 'medium' | 'small';
  handleFilter: () => void;
}

const FiltersBody: React.FunctionComponent<IFiltersBodyProps> = ({ handleApplyFilter }) => {
  const filters = useAppSelector(state => state.marketplace)
  const { category } = filters;
  const dispatch = useDispatch();

  const showClearFilter =
    !filters.forSale ||
    filters.races.length > 0 ||
    filters.genders.length > 0 ||
    filters.attributes.length > 0 ||
    filters.types.length > 0 ||
    filters.rarities.length > 0 ||
    filters.structureTypes.length > 0 ||
    filters.resourceTypes.length > 0 ||
    filters.regions.length > 0 ||
    filters.characterId !== '' ||
    filters.professions.length > 0;

  const onClear = () => {
    dispatch(clearFilters(true));
  };

  return (
    <>
      <div className="d-flex align-items-center px-1" style={{ height: 60 }}>
        <FilterIcon />
        <Typography className="ms-2" fontWeight="bold">
          {Strings.filter}
        </Typography>
        {showClearFilter && (
          <CMPButton variant="text" className="ms-3" color="primary" onClick={onClear}>
            {Strings.clear}
          </CMPButton>
        )}
      </div>
      <Filters.Blockchains />
      {category === 'character' ? (
        <>
          <Filters.CharacterID />
          <Filters.Races />
          <Filters.Professions />
          {/*
          <Filters.CharacterAttributes
            attrs={attributes?.find(item => item.category === 'character')?.attributes || []}
          />
          <Filters.CosmicRewardsBonus />
          <Filters.Gender /> */}
        </>
      ) : category === 'companion' ? (
        <>
          <Filters.PetID />
          {/*
          <Filters.PetAttributes attrs={attributes?.find(item => item.category === 'pet')?.attributes || []} />*/}
        </>
      ) : category === 'land' ? (
        <>
          <Filters.CharacterID name={Strings.landId} />
          <Filters.Regions />
        </>
      ) : category === 'item' ? (
        <>
          <Filters.ItemTypes />
          {/*
          <Filters.CharacterAttributes
            attrs={
              attributes?.find(item => item.category === 'item' && item.subCategory !== 'framed-wizards')?.attributes ||
              []
            }
          />*/}
        </>
      ) : category === 'resource' ? (
        <>
          <Filters.ResourcesTypes />
          <Filters.Rarity />
        </>
      ) : category === 'structure' ? (
        <>
          <Filters.StructuresTypes />
        </>
      ) : (
        <>{null}</>
      )}
      <CMPButton
        sx={{
          width: '100%',
          mt: 2,
          height: 48,
        }}
        onClick={handleApplyFilter}
      >
        {Strings.applyFilters}
      </CMPButton>
    </>
  );
};

const FiltersSection: React.FunctionComponent<IFiltersSectionProps> = ({
  mobileView,
  showCategories = true,
  buttonSize = 'small',
  handleFilter,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { category } = useAppSelector(state => state.marketplace)
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const onChangeCategory = (cat: NFTCategory) => {
    navigate(`/gallery/${cat}`);
  };

  if (matches && !mobileView) {
    return <FiltersBody handleApplyFilter={handleFilter} />;
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-2">
        <CMPButton
          variant="text"
          color="inherit"
          startIcon={<FilterIcon />}
          style={{ borderRadius: buttonSize === 'small' ? '18px' : '24px' }}
          sx={{
            width: 100,
            height: buttonSize === 'small' ? 52 : 68,
            borderColor: 'divider',
            backgroundColor: 'filterBg',
            fontWeight: 'bold',
            border: theme => (theme.palette.mode === 'light' ? `1px solid ${theme.palette.divider}` : undefined),
          }}
          onClick={() => setOpen(true)}
        >
          {Strings.filter}
        </CMPButton>
        {showCategories && <Categories activeItem={category} onChangeCategory={onChangeCategory} />}
      </div>
      <CMPDrawer open={open} onOpenChange={setOpen} onClose={() => setOpen(false)}>
        <Div sx={{ width: '82vw', maxWidth: '360px', padding: '16px' }}>
          <div className={clsx('d-flex align-items-center', { 'mt-2': !matches })}>
            <Sort minWidth="calc(82vw - 32px)" maxWidth="calc(360px - 32px)" withPaddingEnd={false} />
          </div>
          <Divider className="mt-3" />
          <FiltersBody handleApplyFilter={handleFilter} />
        </Div>
      </CMPDrawer>
    </>
  );
};
export default FiltersSection;
