import React, { useState } from 'react';
import { useAccount } from 'wagmi';
import { useLocation } from 'react-router-dom';
import { IconButton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { setAppLoading } from "store/app/reducer";
import Strings from 'utils/Strings';
import CMPButton from 'components/global/button';
import MagickIcon from 'components/icons/magick-icon';
import CopyIcon from 'components/icons/profile/CopyIcon';
import EditIcon from 'components/icons/profile/EditIcon';
import Utils from '../../../../../../libs/Utils';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { selectMagicWalletBalance } from '../../../../../../store/wallet/hooks';
import { BnToLocale } from '../../../../../../utils/BigNumbers';

const ProfileSummaryInformation: React.FunctionComponent = () => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const { address } = useAccount();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const magic = useAppSelector(selectMagicWalletBalance);

  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const page = () => {
    const s = location.pathname.split('/');
    return s[s.length - 1];
  };

  const isSelf = () => [address, 'profile'].includes(page());

  const wallet = page() === 'profile' ? address : page();

  return (
    <>
      <div className="d-flex align-items-center mt-4">
        <Typography variant="h4" className="mr-2" textAlign="center" sx={{ marginBottom: '4px', fontSize: 36 }}>
          {'--'}
        </Typography>
        {!address && (
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon color={palette.primary.main} />
          </IconButton>
        )}
      </div>
      {isSelf() && (
        <div className="d-flex align-items-center mt-4">
          <Typography component="span" color="textSecondary" variant="body1">
            MAGICK:
          </Typography>
          <Typography component="span" variant="h6" className="ms-2">
            {BnToLocale(magic?.balance || 0)}
          </Typography>
        </div>
      )}
      <div className={`d-flex align-items-center ${matches ? 'flex-row my-4' : 'flex-column my-2'}`}>
        <div className={`d-flex align-items-center ${matches ? 'mr-5' : ''}`}>
          <MagickIcon />
          <Typography className="ml-2">{Utils.minimizeAddress(wallet as string)}</Typography>
        </div>
        <CMPButton
          variant="text"
          onClick={() => Utils.copyToClipboard(wallet || '')}
          startIcon={<CopyIcon color={palette.text.secondary} />}
          color="inherit"
          sx={{ width: 84, height: 32 }}
        >
          <Typography color="text.secondary">{Strings.copy}</Typography>
        </CMPButton>
      </div>
    </>
  );
};

export default ProfileSummaryInformation;
