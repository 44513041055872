import { INFTProfessions, NFTAttributes, NFTStaking, RawAttribute } from "models/NFT";
import { RawElfAttributesMap } from './elves/functions';
import { RawWizardAttributesMap } from './wizards/functions';

const genderMap: { [index: string]: string } = {
  '0': 'male',
  '1': 'female',
};

export const RawNumericAttributesMap = (subCategory: string, attributes: RawAttribute[]): NFTAttributes => {
  const gender = genderMap[attributes.find(a => a.trait_type.toLowerCase() === 'gender')?.value];
  return attributes.reduce((o, { trait_type, value }) => {
    // check if it is a gender number or a gender string
    if (trait_type.toLowerCase() === 'gender') {
      o.gender = gender;
    } else {
      o[trait_type.toLowerCase()] = value;
    }

    return o;
  }, {} as NFTAttributes);
};

export const RawAttributesMap = (subCategory: string, attributes: RawAttribute[]): NFTAttributes => {
  switch (subCategory) {
    case 'Wizards': {
      return RawWizardAttributesMap(attributes);
    }
    case 'Elves': {
      const gender = attributes.find(a => a.trait_type === 'Gender')?.value;
      return RawElfAttributesMap(gender, attributes);
    }
    default: {
      return attributes.reduce((o, { trait_type, value }) => {
        o[trait_type.toLowerCase()] = value;
        return o;
      }, {} as NFTAttributes);
    }
  }
};

export const RawAttributesProfessionsMap = (attributes: RawAttribute[]): INFTProfessions | undefined => {
  if (!attributes.find(a => a.trait_type === 'Alchemy')) {
    return undefined;
  }
  return {
    tailoring: Number(attributes.find(a => a.trait_type === 'Tailoring')?.value),
    alchemy: Number(attributes.find(a => a.trait_type === 'Alchemy')?.value),
    architecture: Number(attributes.find(a => a.trait_type === 'Architecture')?.value),
    carpentry: Number(attributes.find(a => a.trait_type === 'Carpentry')?.value),
    cooking: Number(attributes.find(a => a.trait_type === 'Cooking')?.value),
    crystalExtraction: Number(attributes.find(a => a.trait_type === 'Crystal Extraction')?.value),
    farming: Number(attributes.find(a => a.trait_type === 'Farming')?.value),
    fishing: Number(attributes.find(a => a.trait_type === 'Fishing')?.value),
    gemCutting: Number(attributes.find(a => a.trait_type === 'Gem Cutting')?.value),
    herbalism: Number(attributes.find(a => a.trait_type === 'Herbalism')?.value),
    mining: Number(attributes.find(a => a.trait_type === 'Mining')?.value),
    woodcutting: Number(attributes.find(a => a.trait_type === 'Woodcutting')?.value),
  };
};

export const RawAttributesStakingMap = (attributes: RawAttribute[]): NFTStaking | undefined => {
  if (!attributes.find(a => String(a.value).toLowerCase().includes("stake"))) {
    return undefined;
  }
  return {
    staked: !!attributes.find(a => a.value === 'Staked'),
    unlocked: !!attributes.find(a => a.value === 'Staking Unlocked'),
  };
};

export const RawAttributesChestsClaimed = (attributes: RawAttribute[]): number => {
  const claimed = attributes.find(a => a.trait_type === 'Chests Claimed')?.value;
  return claimed || 0;
};
