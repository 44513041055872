import { FC, useEffect } from 'react';
import GetLogger from '../../../components/global/Logger';
import { CleanSymbol } from '../../../utils/Contracts';
import { useAppDispatch } from '../../hooks';
import { useFetchPairDataQuery } from '../actions';
import { setTokenPrice } from '../reducer';
import { SetTokenPricePayload } from '../types';

interface PairUpdaterProps {
  symbol: string;
  address: string;
}

const GlobalPairUpdater: FC<PairUpdaterProps> = ({ address }): null => {
  const log = GetLogger('Pair Updater');
  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useFetchPairDataQuery(address, { pollingInterval: 30000 });
  useEffect(() => {
    if (error) {
      log.error('Error fetching Token Data:', error);
      return;
    }
    if (!isLoading && data) {
      const quoteToken = CleanSymbol(data.quoteToken.symbol);
      const token: SetTokenPricePayload = {
        token: `${CleanSymbol(data.baseToken.symbol)}_${quoteToken}`,
        details: {
          price: Number(data.priceUsd),
          liquidity: Number(data.liquidity.usd),
        },
      };
      if (token.token === 'MAGIC_COSMIC') {
        token.token = 'COSMIC_MAGIC';
      }
      //log.debug(token);
      dispatch(setTokenPrice(token));
    }
  }, [data]);

  return null;
};

export default GlobalPairUpdater;
