import { providers, Signer } from 'ethers';
import store from 'store';
import { setAppLoading } from "store/app/reducer";
import { CreateAssetCustomizationContract } from 'services/CreateContract';
import { showSnackbar } from 'components/global/snackbar';
import { NFT } from "../models/NFT";
import { magicApproval } from './StakeNFT';

export const NameNFTService = async (nft: NFT, name: string, signer: Signer): Promise<boolean> => {
  const contract = CreateAssetCustomizationContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer, contract.address);
    const tx = await contract.setNameOf(nft.contract, nft.tokenId, name);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const ResetNameNFTService = async (nft: NFT, signer: Signer): Promise<boolean> => {
  const contract = CreateAssetCustomizationContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.resetNameOf(nft.contract, nft.tokenId);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};
