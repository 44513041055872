import React, { FC, Fragment, SyntheticEvent, useEffect, useState } from "react";
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  LinearProgress,
  Typography
} from "@mui/material";
import { selectFarmPools } from '../../../../store/farm/hooks';
import { Pool } from '../../../../store/farm/types';
import { useAppSelector } from '../../../../store/hooks';
import AddStakeModal from '../modals/AddStakeModal';
import RemoveStakeModal from '../modals/RemoveStakeModal';
import PoolDetails from './PoolDetails';
import PoolSummary from './PoolSummary';

interface Props {
  staked: boolean;
  pool: Pool;
  setPool: (pool: Pool) => void;
  setAddOpen: (open: boolean, add: boolean) => void;
}

const PoolsCard: FC<Props> = ({ staked, pool, setPool, setAddOpen }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [addStakeOpen, setAddStakeOpen] = useState<boolean>(false);
  const [removeStakeOpen, setRemoveStakeOpen] = useState<boolean>(false);

  const pools = useAppSelector(selectFarmPools);
  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Card sx={{ borderRadius: '24px' }}>
      <CardContent>
        {pools.length === 0 ? (
          <Fragment>
            <Typography textAlign="center" variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }}>
              Fetching pool info...
            </Typography>
            <LinearProgress />
          </Fragment>
        ) : (
          pools
            .filter(p => !staked || p.balance.gt(0))
            .filter(p => p.emissionRate.gt(0) || p.balance.gt(0))
            .map((p, i) => (
              <Accordion
                key={p.name}
                expanded={expanded === p.name}
                onChange={handleChange(p.name)}
                elevation={0}
                sx={{ backgroundColor: 'transparent' }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <PoolSummary pool={p} />
                </AccordionSummary>
                <AccordionDetails>
                  <PoolDetails
                    pool={p}
                    openAdd={(open: boolean) => {
                      setPool(p);
                      setAddStakeOpen(open);
                    }}
                    openRemove={(open: boolean) => {
                      setPool(p);
                      setRemoveStakeOpen(open);
                    }}
                    openLiquidity={(open: boolean, a: boolean) => {
                      setPool(p);
                      setAddOpen(open, a);
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            ))
        )}
      </CardContent>
      <AddStakeModal open={addStakeOpen} setOpen={setAddStakeOpen} pool={pool} />
      <RemoveStakeModal open={removeStakeOpen} setOpen={setRemoveStakeOpen} pool={pool} />
    </Card>
  );
};

export default PoolsCard;
