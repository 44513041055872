import { Popover, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";

export type TextPopoverProps = {
  title: string,
  text: ReactNode | string,
  handlePopoverClose: () => void;
  anchorEl: HTMLElement | null;

}
const TextPopover: FC<TextPopoverProps> = ({ title, text, handlePopoverClose, anchorEl }) => {
  const open = Boolean(anchorEl);
  return (
    <Popover
      id={`${title.toLowerCase().replace(' ', '-')}-text-popover`}
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center', }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      {typeof text === 'string' ? <Typography sx={{ p: 1 }}>{text}</Typography> : text}
    </Popover>
  )
}

export default TextPopover;


