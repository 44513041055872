import { styled } from '@mui/material';

const LoadingContainer = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  background: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: theme.zIndex.modal + 1,
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '84px',
  },
}));
export default LoadingContainer;
