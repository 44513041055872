import React, { FC } from 'react';
import DialogModal from 'components/global/dialog-modal';
import { Pool } from '../../../../store/farm/types';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  pool: Pool;
  add: boolean;
}

const ManageLiquidityModal: FC<Props> = ({ open, setOpen, pool, add }) => {
  const src = `https://traderjoe.xyz/pool/${pool.token0}/${pool.token1}`;
  return (
    <DialogModal title={`${add ? 'Add' : 'Remove'} liquidity`} open={open} onClose={() => setOpen(false)}>
      <iframe
        src={src}
        height="1150px"
        width="100%"
        scrolling="no"
        style={{
          border: 0,
          margin: '0 auto',
          display: 'block',
          borderRadius: '10px',
          maxWidth: '600px',
          minWidth: '300px',
        }}
      />
    </DialogModal>
  );
};

export default ManageLiquidityModal;
