import { useEffect } from 'react';
import axios from 'axios';
import { TokenURIsToNFTs } from 'utils/Contracts';
import GetLogger from 'components/global/Logger';
import Chains from "../../../constants/wagmi/chains";
import { useAppDispatch } from '../../hooks';
import { setGlobalNfts } from '../reducer';

interface INFTRes {
  [key: string]: string[];
}

const GlobalNFTUpdater = (): null => {
  const log = GetLogger('Global NFT Updater');
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getNfts = async () => {
      const response = await axios.get(`https://api.cosmicuniverse.io/nfts`);
      if (response.status === 200) {
        const data = response.data as INFTRes;
        const allNfts: any = {};
        Object.entries(data).map(([token, raw]) => {
          allNfts[token] = TokenURIsToNFTs(raw, token, undefined, undefined, undefined, undefined, Chains.avalanche as any);
        });
        dispatch(setGlobalNfts(allNfts));
      }
    };
    getNfts();
    const interval = setInterval(() => {
      getNfts();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return null;
};

export default GlobalNFTUpdater;
