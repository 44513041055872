import React, { useMemo } from 'react';
import { utils } from 'ethers';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import {
  selectMintElvesTicketsSelected,
  selectMintElvesToMint,
  selectMintElves,
} from 'store/mint/hooks';
import { PaymentMethod } from 'constants/types';

interface Row {
  amount: number;
  discount: number;
  total: number;
}

const StyledTable = styled(TableContainer)(({ theme }) => ({
  background: 'transparent',
  border: `2px solid ${theme.palette.golden}`,
  borderRadius: 10,
  margin: '40px 0',
  '& .MuiTable-root': {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: 0,
        height: 36,
        maxWidth: 150,
        borderRight: `2px solid ${theme.palette.golden}`,
        borderBottom: `2px solid ${theme.palette.golden}`,
        textAlign: 'center',
        '&:last-child': {
          borderRight: 0,
        },
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          fontSize: 16,
          color: theme.palette.golden,
          [theme.breakpoints.up('sm')]: {
            fontSize: 22,
            fontWeight: 'bold',
          },
        },
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          fontSize: 12,
          [theme.breakpoints.up('sm')]: {
            fontSize: 18,
          },
        },
        '&:last-child': {
          '& .MuiTableCell-root': {
            color: theme.palette.golden,
            borderBottom: 0,
          },
        },
      },
    },
  },
}));

const MintDetails = () => {
  const count = useAppSelector(selectMintElvesToMint);
  const elves = useAppSelector(selectMintElves);
  const price = useMemo(() => {

    if (elves.price.eq(0)) {
      return 100;
    }
    return Math.round(Number(utils.formatUnits(elves.price, '6')));
  }, [elves.price]);
  const unit = 'USDC';
  const eTicketsSelected = useAppSelector(selectMintElvesTicketsSelected);
  const totalETicketsSelected = () => Object.values(eTicketsSelected).reduce((n, p) => n + p, 0);

  const priceRow = (amount: number, discount: number) => {
    return (amount * price * (100 - discount)) / 100;
  };

  const createRow = (amount: number, discount: number) => {
    const total = priceRow(amount, discount);
    return { amount, discount, total };
  };

  const getTotalPrice = (items: readonly Row[]) => {
    const p = items.map(({ total }) => total).reduce((sum, i) => sum + i, 0);
    const credits = Number(utils.formatUnits(elves.credits, '6'));
    if (credits >= p) {
      return 0;
    }
    return p - credits;
  };

  const getTotalAmount = (items: readonly Row[]) => {
    return items.map(({ amount }) => amount).reduce((sum, i) => sum + i, 0);
  };

  const getTotalDiscount = (items: readonly Row[]) => {
    const p = items
      .map(({ amount, discount }) => ({
        amount,
        discount,
      }))
      .reduce((sum, i) => sum + (i.amount * i.discount * price) / 100, 0);
    const credits = Number(utils.formatUnits(elves.credits, '6'));
    const totalPrice1 = getTotalPrice(items)
    if ((credits + p) >= totalPrice1) {
      return totalPrice1;
    }
    return (credits + p)
  };

  const rows = [
    createRow(eTicketsSelected['20%'], 20),
    createRow(eTicketsSelected['15%'], 15),
    createRow(eTicketsSelected['10%'], 10),
    createRow(eTicketsSelected['5%'], 5),
    createRow(count - totalETicketsSelected(), 0),
  ];

  const totalPrice = getTotalPrice(rows);
  const totalAmount = getTotalAmount(rows);
  const totalDiscount = getTotalDiscount(rows);

  return (
    <StyledTable>
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell>Amount</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            if (row.amount > 0)
              return (
                <TableRow key={row.discount}>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.discount === 0 ? '-' : row.discount + '%'}</TableCell>
                  <TableCell>
                    {row.total.toFixed(2)} {unit}
                  </TableCell>
                </TableRow>
              );
          })}
          <TableRow>
            <TableCell>{totalAmount}</TableCell>
            <TableCell>
              {totalDiscount.toFixed(2)} {unit}
            </TableCell>
            <TableCell>
              {totalPrice.toFixed(2)} {unit}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </StyledTable>
  );
};

export default MintDetails;
