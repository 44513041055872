import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Strings from 'utils/Strings';
import TraitRarity from 'constants/trait-rarity.json';
import FilterContainer from 'components/common/filter-container';
import FilterCard from '../../../../../components/common/filter-card';
import { cosmicRewardBonusItems } from '../../../../../constants/filters/Character';
import { CosmicRewardBonusType } from '../../../../../models/Filters';
import {useAppSelector} from "../../../../../store/hooks";
import {setAttributes} from "../../../../../store/marketplace/reducer";

const genders = ['Male'];

interface IProps {
  name?: string;
}

const CosmicRewardsBonus: React.FunctionComponent<IProps> = ({ name = Strings.characterId }) => {
  const dispatch = useDispatch();
  const { cosmicRewardBonuses } = useAppSelector(state => state.marketplace);

  const attributesWithRarity = (attr: string, rarity: number): string[] => {
    const filtered: string[] = [];
    for (const gender of genders) {
      //@ts-ignore
      Object.entries(TraitRarity[gender.toLowerCase()][attr])
        .filter(a => a[1] === rarity)
        .map(a => filtered.push(a[0]));
    }
    return Array.from(new Set(filtered));
  };

  const onSelectHandler = (key: CosmicRewardBonusType) => {
    const rarity = Number(key.substr(0, 1));

    const selection = [{ text: 'gender', checkedItems: genders }];

    for (const attr of Object.keys(TraitRarity.male)) {
      selection.push({ text: attr, checkedItems: attributesWithRarity(attr, rarity) });
    }

    dispatch(setAttributes(selection));
    //const index = cosmicRewardBonuses.findIndex(item => item === key);
    //if (index === -1) {
    //  cosmicRewardBonuses.push(key);
    //  dispatch(setCosmicRewardBonuses(cosmicRewardBonuses));
    //} else {
    //  cosmicRewardBonuses.splice(index, 1);
    //  dispatch(setCosmicRewardBonuses(cosmicRewardBonuses));
    //}
  };

  return (
    <FilterContainer
      className="mt-3"
      title={Strings.cosmicRewardBonus}
      count={cosmicRewardBonuses.length > 0 ? cosmicRewardBonuses.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
          {cosmicRewardBonusItems.map(({ type }) => (
            <FilterCard
              key={type}
              text={type}
              onClick={() => onSelectHandler(type)}
              active={cosmicRewardBonuses.includes(type)}
              purpleMode
            />
          ))}
        </div>
      }
    />
  );
};
export default CosmicRewardsBonus;
