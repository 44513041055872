import React from 'react';
import { SxProps, Theme } from '@mui/material';
import ElvesTraits from 'constants/attributes/elves/elves';
import { regionItems } from 'constants/filters/Lands';
import TraitRarity from 'constants/trait-rarity.json';
import { CharacterGender } from 'models/Filters';
import Div from 'components/global/div';
import KeyValueOutput from 'components/global/key-value-output';

interface IProps {
  rootSx?: SxProps<Theme>;
  subCategory: string;
  gender?: CharacterGender;
  title: string;
  value: any;
}

const NftAttribute: React.FunctionComponent<IProps> = ({ title, value, rootSx, gender, subCategory }) => {
  const attrRarity =
    // @ts-ignore
    gender === 'Male' ? TraitRarity.male[value] : TraitRarity.female[value];
  let rarity = attrRarity ? (attrRarity[title] ? `${attrRarity[title]}%` : undefined) : undefined;
  let t = value === 'region' ? regionItems[Number(title) - 2].text : title;
  if (subCategory === 'Elves') {
    if (gender !== undefined) {
      if (value !== 'gender') {
        rarity = `${ElvesTraits[gender][value.toLowerCase()][t]}%`;
      } else {
        t = gender;
        rarity = '50%';
      }
    }
  }

  return (
    <Div sx={rootSx} className="d-flex align-items-center">
      <KeyValueOutput
        title={t}
        subTitle={rarity}
        value={{ text: value }}
        titleProps={{
          sx: { color: theme => theme.palette.text.primary, textTransform: 'capitalize' },
          fontWeight: 'bold',
        }}
        valueTextProps={{
          sx: { color: theme => theme.palette.text.secondary, textTransform: 'capitalize' },
          variant: 'subtitle2',
        }}
      />
    </Div>
  );
};

export default NftAttribute;
