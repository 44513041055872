import { Divider, styled } from '@mui/material';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: 'transparent',
  backgroundImage: `linear-gradient(to top, transparent, ${theme.palette.divider} 50%, transparent 100%)`,
  backgroundSize: '800px 100%',
  backgroundPosition: '50% 100%',
  backgroundRepeat: 'no-repeat',
}));

export default StyledDivider;
