import React from 'react';
import { DividerProps } from '@mui/material';
import StyledDivider from './_styles';

interface IProps extends DividerProps {}

const GDivider: React.FunctionComponent<IProps> = props => {
  return <StyledDivider orientation="vertical" variant="middle" flexItem {...props} />;
};

export default GDivider;
