import { BigNumber, utils } from 'ethers';

export const CleanBN = (bn: BigNumber): number => {
  const n = utils.formatEther(bn).split('.');
  return Number(`${n[0]}.${n[1].substring(0, 3)}`);
};

export const CleanBNInteger = (bn: BigNumber): number => {
  const n = utils.formatEther(bn).split('.');
  return Number(`${n[0]}`);
};

export const CleanLocaleBN = (bn: BigNumber): string => CleanBN(bn).toLocaleString();

export const CleanLocaleBNInteger = (bn: BigNumber): string => CleanBNInteger(bn).toLocaleString();

export const FindWithdrawFee = (blockDelta: BigNumber): number => {
  let withdrawFee = 0;
  if (blockDelta.eq(0)) {
    withdrawFee = 25;
  } else if (blockDelta.gte(1) && blockDelta.lte(1770)) {
    withdrawFee = 8;
  } else if (blockDelta.gte(1771) && blockDelta.lte(43200)) {
    withdrawFee = 4;
  } else if (blockDelta.gte(43201) && blockDelta.lte(129600)) {
    withdrawFee = 2;
  } else if (blockDelta.gte(129601) && blockDelta.lte(216000)) {
    withdrawFee = 1;
  } else if (blockDelta.gte(216001) && blockDelta.lte(604800)) {
    withdrawFee = 0.5;
  } else if (blockDelta.gte(604801) && blockDelta.lte(1209600)) {
    withdrawFee = 0.25;
  } else if (blockDelta.gte(1209601)) {
    withdrawFee = 0.1;
  }
  return withdrawFee;
};
