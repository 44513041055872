import React, { FC, ReactElement, useEffect, Fragment } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ApplicationRoutes from 'routes';
import { DEV_MODE } from '../../constants/Statics';
import { useAccount } from 'wagmi';
import LoginRequired from "../../pages/error/LoginRequired";
import MaintenanceWrapper from "../../pages/error/Maintenance";

interface Props {
  children: ReactElement;
}
const RequireAuth: FC<Props> = ({ children }) => {
  const { address } = useAccount();

  if (!address) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <LoginRequired />;
  }

  return children;
};

const RouterProvider: React.FunctionComponent = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <Routes>
      {ApplicationRoutes.map(({ element: Element, ...item }) => (
        <Fragment key={item.path}>
          {item.isDev && !DEV_MODE
            ? null
            : item.isPrivate
              ? (
                <Route
                  path={item.path}
                  element={
                    <RequireAuth>
                      <Element />
                    </RequireAuth>
                  }
                />
              ) : item.maintenance ? (
                <Route
                  path={item.path}
                  element={
                    <MaintenanceWrapper until={item.maintenance}>
                      <Element />
                    </MaintenanceWrapper>
                  }
                />
                ) : (
                <Route element={<Element />} {...item} />
              )}
        </Fragment>
      ))}
      <Route path="*" element={<Navigate to="/gallery/character" />} />
    </Routes>
  );
};
export default RouterProvider;
