import { Mode } from "connectkit/build/types";
import React, { useLayoutEffect, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Utils from 'libs/Utils';
import AppConfig from 'models/env/AppConfig';
import AppLoading from 'components/global/app-loading';
import CMPSnackbar from 'components/global/snackbar';
import Navbar from 'components/layouts/navbar';
import SideBar from 'components/layouts/side-bar';
import RouterProvider from 'components/providers/Router';
import ThemeProvider from 'components/providers/Theme';
import './assets/scss/app.css';
import './assets/scss/custom-classes.css';
import ProfileAvatar from "./components/layouts/navbar/components/ProfileAvatar";
import FarmUpdater from './store/farm/updater';
import GlobalUpdater from './store/global/updaters';
import { useAppSelector } from "./store/hooks";
import MarketUpdater from './store/market/updaters';
import MintUpdater from './store/mint/updater';
import StakingUpdater from './store/staking/updaters';
import WalletUpdater from './store/wallet/updaters';
import { WagmiConfig } from 'wagmi';
import { client } from './constants/wagmi/chains';
import { ConnectKitProvider } from 'connectkit';

const Updaters = () => {
  return (
    <Fragment>
      <WalletUpdater />
      <GlobalUpdater />
      <StakingUpdater />
      <FarmUpdater />
      <MintUpdater />
      <MarketUpdater />
    </Fragment>
  );
};

const App: React.FunctionComponent = () => {
  useLayoutEffect(() => {
    const appConfig = new AppConfig().readFromLocalStorage();
    if (appConfig) {
      Utils.changeTheme(appConfig.theme);
    }
  }, []);
  const mode = useAppSelector(state => state.app.appTheme)
  return (
    <WagmiConfig client={client}>
      <ConnectKitProvider
        mode={mode.toLowerCase() as Mode}
        options={{ walletConnectName: 'WalletConnect', customAvatar: ProfileAvatar }}
      >
        <ThemeProvider>
          <Updaters />
          <BrowserRouter>
            <div className={`cosmic-nft-marketplace`}>
              <Navbar />
              <SideBar />
              <RouterProvider />
            </div>
          </BrowserRouter>
          <AppLoading />
          <CMPSnackbar />
        </ThemeProvider>
      </ConnectKitProvider>
    </WagmiConfig>
  );
};

export default App;
