import React, { FC } from 'react';
import { useContractRead} from "wagmi";
import { constants } from "ethers";
import GetLogger from 'components/global/Logger';
import { ABIs, Addresses } from "constants/Addresses";
import { useWagmi } from "hooks/wagmi";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectStakingAllStaking } from '../hooks';
import { setStakingAllStaking } from '../reducer';
import { AllUserStakingState, StakedNft } from '../types';
import Chains from 'constants/wagmi/chains';

const AllStakingDataUpdater: FC = (): null => {
  const { address } = useWagmi();
  const log = GetLogger('All Staking Updater');

  const allStaking = useAppSelector(selectStakingAllStaking);
  const dispatch = useAppDispatch();

  useContractRead({
    address: Addresses[Chains.avalanche.id].NPCs.ProfessionStaking,
    abi: ABIs.NPCs.ProfessionStaking,
    functionName: 'getAllParticipantData',
    cacheTime: 600_000,
    enabled: address !== constants.AddressZero,
    chainId: Chains.avalanche.id,
    onSuccess: (data) => {
      const allStakingState = data.addresses.reduce((o, a, i) => {
        o[a] = {
          address: a,
          nftData: data.nfts[i] as StakedNft[],
        };
        return o;
      }, {} as AllUserStakingState);
      if (Object.keys(allStakingState).length !== Object.keys(allStaking).length) {
        dispatch(setStakingAllStaking(allStakingState));
      }
    },
    onError: (err) => {
      log.error(err)
    }
  })

  return null;
};

export default AllStakingDataUpdater;
