import React, { FC, Fragment } from 'react';
import Wallet1155Updater from './Wallet1155Updater';
import WalletItem721Updater from "./WalletItem721Updater";
import WalletNFTUpdater from './WalletNFTUpdater';
import WalletTokenUpdater from './WalletTokenUpdater';

const WalletUpdater: FC = () => {
  return (
    <Fragment>
      <WalletTokenUpdater />
      <WalletNFTUpdater />
      <Wallet1155Updater />
      <WalletItem721Updater />
    </Fragment>
  );
};

export default WalletUpdater;
