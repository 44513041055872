import React, { SyntheticEvent } from "react";
import { Avatar, CardProps, Typography, TypographyProps } from "@mui/material";
import {
  CardBody,
  CardDescription,
  CardProfessions,
  IAsset,
} from "models/@types";
import CMPCardContent from "components/global/card-content";
import Div from "components/global/div";
import CMPImage from "components/global/image";
import CMPClassicCard, { classes } from "./_styles";
import { Chain, useNetwork } from "wagmi";
import { ChainList } from "../../../constants/wagmi/chains";

interface IProps extends CardProps {
  asset?: IAsset;
  title?: string;
  name?: string;
  rank?: number;
  body?: CardBody;
  description?: CardDescription;
  time?: string;
  optionIcons?: React.ReactNode[];
  titleProps?: TypographyProps<"div">;
  endIcon?: React.ReactNode;
  professions?: CardProfessions;
  action?: React.ReactNode;
  chain?: Chain;
}

const ClassicCard: React.FunctionComponent<IProps> = ({
  asset,
  body,
  description,
  name,
  title,
  titleProps,
  time,
  optionIcons,
  endIcon,
  rank,
  professions,
  action,
  chain,
  ...props
}) => {
  const matchedChain = ChainList.find((c) => c.id === chain?.id);

  return (
    <CMPClassicCard
      className="justify-content-between h-100"
      {...props}
    >
      {matchedChain && (
        <Avatar
          src={matchedChain.logo}
          sx={{ position: "absolute", top: 8, left: 8 }}
        />
      )}
      {asset && (
        <Div className={classes.imgContainer}>
          <CMPImage
            asset={asset}
            width="100%"
            height="100%"
            gender={name?.split(" ")[1]}
            style={{ objectFit: "cover" }}
          />
        </Div>
      )}
      <Div
        className="d-flex justify-content-between"
        sx={{ width: "calc(100% - 142px)" }}
      >
        <Div className="w-100 d-flex flex-column justify-content-between ms-3">
          <CMPCardContent
            body={body}
            description={description}
            title={title}
            name={name}
            titleProps={titleProps}
            rank={rank}
            professions={professions}
            action={action}
            classic
          />
        </Div>
        <Div
          sx={{ height: "100%" }}
          className="d-flex flex-column justify-content-between align-items-end"
        >
          <Div
            sx={{ width: "100%" }}
            className="d-flex align-items-center justify-content-end"
          >
            {optionIcons && (
              <Div className="d-flex align-items-center">
                {optionIcons.map((icon) => icon)}
              </Div>
            )}
            {endIcon}
          </Div>
          <Typography
            sx={{ width: "100%", minHeight: 32, textAlign: "right" }}
            variant="body2"
            color="text.secondary"
            className="d-flex align-items-center text-capitalize"
            noWrap
          >
            {time}
          </Typography>
        </Div>
      </Div>
    </CMPClassicCard>
  );
};

export default ClassicCard;
