import React, { ChangeEvent, FC, useState } from 'react';
import { BigNumber, utils } from 'ethers';
import { Alert, Grid, TextField } from '@mui/material';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import { Deposit } from '../../services/Bank';
import { useProvider, useSigner } from 'wagmi';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  balance: BigNumber;
}

const DepositModal: FC<Props> = ({ open, setOpen, balance }) => {
  const { data } = useSigner();
  const [amount, setAmount] = useState<string>('0.0');
  const handleDeposit = async () => {
    if (!data) {
      return;
    }
    await Deposit(amount, data);
  };
  return (
    <DialogModal title={`Deposit`} open={open} onClose={() => setOpen(false)}>
      <Alert severity="info" sx={{ justifyContent: 'center', marginBottom: '0.25rem' }}>
        The bank has no fees.
      </Alert>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Quantity"
            variant="standard"
            value={amount}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setAmount(event.target.value || '0.0')}
            type="number"
            inputProps={{
              step: 0.001,
              max: utils.formatEther(balance || BigNumber.from(0)),
              min: '0.0',
            }}
            helperText={`Balance: ${utils.formatEther(balance || BigNumber.from(0))}`}
          />
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CMPButton onClick={() => setAmount(utils.formatEther(balance.div(2)))}>½</CMPButton>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CMPButton onClick={() => setAmount(utils.formatEther(balance))}>Max</CMPButton>
        </Grid>

        <Grid item xs={12}>
          <CMPButton fullWidth disabled={utils.parseEther(amount).eq(0)} onClick={handleDeposit}>
            Deposit
          </CMPButton>
        </Grid>
      </Grid>
    </DialogModal>
  );
};

export default DepositModal;
