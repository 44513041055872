import { constants } from "ethers";
import React, { FC } from "react";
import { useContractReads } from "wagmi";

import GetLogger from '../../../components/global/Logger';
import { ABIs, Addresses } from "../../../constants/Addresses";
import { useWagmi } from "../../../hooks/wagmi";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { NFTState } from "../../wallet/types";
import { selectStakingRefetch, selectStakingStaked } from '../hooks';
import { setStakingRefetch, setStakingStaked } from '../reducer';
import Chains from '../../../constants/wagmi/chains';

const StakedUpdater: FC = (): null => {
  const { address } = useWagmi();
  const log = GetLogger('Staked Updater');
  const staked = useAppSelector(selectStakingStaked);
  const refetch = useAppSelector(selectStakingRefetch);
  const wizards = useAppSelector(state => state.wallet.nfts?.Wizards || {});
  const dispatch = useAppDispatch();

  const stakingContract = {
    address: Addresses[Chains.avalanche.id].NPCs.ProfessionStaking,
    abi: ABIs.NPCs.ProfessionStaking,
    chainId: Chains.avalanche.id
  }
  useContractReads({
    contracts: [
      { ...stakingContract, functionName: 'getStakedOf', args: [address] },
      { ...stakingContract, functionName: 'getActiveTrainingOf', args: [address] }
    ],
    cacheTime: refetch ? 5_000 : 60_000,
    enabled: address !== constants.AddressZero && Object.keys(wizards).length > 0,
    onSuccess: ([stakedRaw, trainingRaw]) => {
      const ids = stakedRaw.map(s => s.tokenId);
      if (ids.length === 0 || trainingRaw.length === 0) {
        return;
      }
      const newStaked = trainingRaw.reduce((o, t) => {
        const nftId = `${t._address}-${t.tokenId}`;
        if (nftId in wizards) {
          o[nftId] = wizards[nftId]
          o[nftId].setTrainingStatus(t);
        }
        return o;
      }, {} as NFTState);
      if ((Object.keys(staked).length !== Object.keys(newStaked).length) || refetch) {
        dispatch(setStakingStaked(newStaked));
        if (refetch) {
          dispatch(setStakingRefetch(false));
        }
      }
    },
    onError: (err) => {
      log.error(err);
    }
  })

  return null;
};

export default StakedUpdater;
