import React, { useState } from 'react';
import { BigNumber } from 'ethers';
import { Grid } from '@mui/material';
import useTitle from 'hooks/useTitle';
import PageContainer from 'pages/bridge/styled/PageContainer';
import { Pool } from '../../store/farm/types';
import ActionBar from './components/ActionBar';
import MagicRewardsCard from './components/MagicRewardsCard';
import MagicStatsCard from './components/MagicStatsCard';
import PoolsCard from './components/PoolsCard';

export interface MagicData {
  balance: BigNumber;
}

interface IProp {}

const FarmsPage: React.FunctionComponent<IProp> = () => {
  const [stakedFilter, setStakedFilter] = useState<boolean>(false);
  const [liquidityOpen, setLiquidityOpen] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(true);

  const [pool, setPool] = useState<Pool>({} as Pool);

  useTitle({ title: 'Farms' });

  return (
    <PageContainer sx={{ maxWidth: 'xl' }}>
      <Grid container spacing={2} sx={{ width: 0.8, justifyContent: 'center', alignItems: 'stretch', display: 'flex' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MagicStatsCard />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MagicRewardsCard />
        </Grid>
        <Grid item xs={12}>
          <ActionBar
            staked={stakedFilter}
            setStaked={setStakedFilter}
            pool={pool}
            add={add}
            addOpen={liquidityOpen}
            setAddOpen={(open: boolean, a: boolean) => {
              setLiquidityOpen(open);
              setAdd(a);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PoolsCard
            staked={stakedFilter}
            pool={pool}
            setPool={setPool}
            setAddOpen={(open: boolean, a: boolean) => {
              setLiquidityOpen(open);
              setAdd(a);
            }}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default FarmsPage;
