import React from 'react';
import { CustomIconProps, setColor } from '..';

const AuctionTimerIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="vuesax_linear_timer" data-name="vuesax/linear/timer" transform="translate(-236 -188)">
        <g id="timer" transform="translate(236 188)">
          <path
            id="Vector"
            d="M11.667,5.833A5.833,5.833,0,1,1,5.833,0,5.835,5.835,0,0,1,11.667,5.833Z"
            transform="translate(2.167 3)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0V3.333"
            transform="translate(8 5.333)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H4"
            transform="translate(6 1.333)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path id="Vector-4" data-name="Vector" d="M0,0H16V16H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};
export default AuctionTimerIcon;
