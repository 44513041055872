import React from 'react';
import { Provider } from "react-redux";
import { WagmiConfig } from "wagmi";
import App from './App';
import './index.css';
import { client } from "./constants/wagmi/chains";
import { createRoot } from 'react-dom/client';
import store from "./store";
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiConfig client={client}>
        <App />
      </WagmiConfig>
    </Provider>
  </React.StrictMode>
);

