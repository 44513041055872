import React from 'react';
import { CustomIconProps, setColor } from '..';

const BackIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg fill={iconColor} width="24" height="24" viewBox="0 0 24 24">
      <path d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.9959.9959 0 0 0-1.41 0l-6.59 6.59c-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z" />
    </svg>
  );
};
export default BackIcon;
