import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { NFT } from '../../../../models/NFT';
import { selectGlobalNFTs } from '../../../../store/global/hooks';
import { useAppSelector } from '../../../../store/hooks';
import SummaryCard from '../SummaryCard';

const LeaderboardSummaryCard: FC = () => {
  const globalNfts = useAppSelector(selectGlobalNFTs);
  const [staked, setStaked] = useState<number>(0);
  const [lvl20s, setLvl20s] = useState<number>(0);
  const [lvl40s, setLvl40s] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(globalNfts.Wizards || {}).length === 0) {
      return;
    }
    const nfts = Object.values(globalNfts.Wizards).reduce((a, l) => {
      if (l.staking?.staked) {
        a = a.concat(l);
      }
      return a;
    }, [] as NFT[]);
    if (nfts.length !== staked) {
      setStaked(nfts.length);
    }
    const wizards = globalNfts.Wizards;
    if (wizards) {
      const lvl20NFTs = Object.values(wizards).filter(n => n.professions && Object.values(n.professions).includes(20));
      if (lvl20NFTs.length !== lvl20s) {
        setLvl20s(lvl20NFTs.length);
      }
      const lvl40NFTs = Object.values(wizards).filter(
        n => n.professions && Object.values(n.professions).filter(x => x === 20).length === 2,
      );
      if (lvl40NFTs.length !== lvl40s) {
        setLvl40s(lvl40NFTs.length);
      }
    }
  }, [globalNfts, lvl20s, lvl40s]);

  const totalWiz = Object.keys(globalNfts.Wizards || {}).length;
  const rows = [
    {
      category: 'Staked',
      amount: staked,
      percent: ((staked / totalWiz) * 100).toFixed(2),
    },
    {
      category: 'Level 20s',
      amount: lvl20s,
      percent: ((lvl20s / totalWiz) * 100).toFixed(2),
    },
    {
      category: 'Level 40s',
      amount: lvl40s,
      percent: ((lvl40s / totalWiz) * 100).toFixed(2),
    },
  ];
  return (
    <SummaryCard>
      <CardHeader title="Leaderboard" sx={{ textAlign: 'center', paddingBottom: 0 }} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="right">Percent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <TableRow key={i} component="th" scope="row">
                <TableCell>{r.category}</TableCell>
                <TableCell align="center">{r.amount}</TableCell>
                <TableCell align="right">{r.percent}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </SummaryCard>
  );
};

export default LeaderboardSummaryCard;
