import React from 'react';
import { useDispatch } from 'react-redux';
import { Divider, IconButton, useTheme } from '@mui/material';
import { ViewMode } from 'models/@types';
import GridIcon from 'components/icons/main/GridIcon';
import ListIcon from 'components/icons/main/ListIcon';
import View from './_styles';
import {useAppSelector} from "../../../../store/hooks";
import {setView} from "../../../../store/marketplace/reducer";

interface IProps {
  onChange?: (val: ViewMode) => void;
}

const ViewSelect: React.FunctionComponent<IProps> = ({ onChange }) => {
  const dispatch = useDispatch();
  const { view } = useAppSelector(state => state.marketplace);
  const theme = useTheme();

  const onChangeMode = (val: ViewMode) => {
    if (view !== val) {
      dispatch(setView(val));
      if (onChange) {
        onChange(val);
      }
    }
  };

  return (
    <View>
      <IconButton onClick={() => onChangeMode('grid')}>
        <GridIcon color={theme.palette.text[view === 'grid' ? 'primary' : 'secondary']} />
      </IconButton>
      <Divider orientation="vertical" sx={{ height: 24, margin: '0 4px' }} />
      <IconButton onClick={() => onChangeMode('list')}>
        <ListIcon color={theme.palette.text[view === 'list' ? 'primary' : 'secondary']} />
      </IconButton>
    </View>
  );
};
export default ViewSelect;
