import { FC, useEffect } from 'react';
import { OldAddresses } from '../../../constants/Addresses';
import { useAppDispatch } from '../../hooks';
import { useFetchTokenDataQuery } from '../actions';
import { setTokenPrice } from '../reducer';
import { SetTokenPricePayload } from '../types';

const GlobalCosmicUpdater: FC = (): null => {
  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useFetchTokenDataQuery(OldAddresses.tokens.COSMIC, { pollingInterval: 30000 });

  useEffect(() => {
    if (error) {
      console.log('Error fetching Token Data:', error);
      return;
    }
    if (!isLoading && data) {
      const COSMIC: SetTokenPricePayload = {
        token: 'COSMIC',
        details: {
          price: Number(data.priceUsd),
          liquidity: Number(data.liquidity.usd),
        },
      };
      dispatch(setTokenPrice(COSMIC));
    }
  }, [data]);

  return null;
};

export default GlobalCosmicUpdater;
