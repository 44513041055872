import { INFTDetailsAttr, NFTAttributes, RawAttribute } from "../../../models/NFT";
import {
  BackgroundMap,
  BaseMap,
  EyesMap,
  Gender,
  GenderMap,
  HairMap,
  HatMap,
  MouthMap,
  RobeMap,
  StaffMap,
  WizardTrait,
} from './types';

export const AttributeMap = (attributes: INFTDetailsAttr[]) => {
  const gender = GenderMap[attributes.find(a => a.trait_type === 'gender')?.value];
  return attributes.map(({ trait_type, value }) => {
    const traitType = trait_type as WizardTrait;
    let trait;
    switch (traitType) {
      case 'gender': {
        trait = gender;
        break;
      }
      case 'background': {
        trait = BackgroundMap(gender)[value];
        break;
      }
      case 'hair': {
        trait = HairMap(gender)[value];
        break;
      }
      case 'hat': {
        trait = HatMap(gender)[value];
        break;
      }
      case 'eyes': {
        trait = EyesMap(gender)[value];
        break;
      }
      case 'base': {
        trait = BaseMap(gender)[value];
        break;
      }
      case 'staff': {
        trait = StaffMap(gender)[value];
        break;
      }
      case 'robe': {
        trait = RobeMap(gender)[value];
        break;
      }
      case 'mouth': {
        trait = MouthMap(gender)[value];
        break;
      }
    }
    return {
      text: {
        title: trait,
        value: { text: traitType },
      },
    };
  });
};

export const RawWizardAttributesMap = (attributes: RawAttribute[]): NFTAttributes => {
  return attributes.reduce((o, { trait_type, value }) => {
    const traitType = trait_type as WizardTrait;
    let trait;
    if (!isNaN(Number(value))) {
      return o;
    }
    switch (traitType?.toLowerCase()) {
      case 'gender':
      case 'background':
      case 'hair':
      case 'hat':
      case 'eyes':
      case 'base':
      case 'staff':
      case 'robe':
      case 'mouth': {
        trait = value;
        break;
      }
      default: {
        return o;
      }
    }
    o[traitType?.toLowerCase()] = trait;
    return o;
  }, {} as NFTAttributes);
};
