import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import BackIcon from 'components/icons/main/BackIcon';

const BackButton: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <IconButton sx={{ border: theme => `1px solid ${theme.palette.divider}` }} onClick={onBack}>
      <BackIcon />
    </IconButton>
  );
};
export default BackButton;
