import React from 'react';
import { CustomIconProps, setColor } from '..';

const CloseIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.536" height="19.536" viewBox="0 0 19.536 19.536">
      <g id="Group_48" data-name="Group 48" transform="translate(-1212.402 -370.402)">
        <path
          id="Vector"
          d="M0,16,16,0"
          transform="translate(1214.17 372.17)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M16,16,0,0"
          transform="translate(1214.17 372.17)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
      </g>
    </svg>
  );
};
export default CloseIcon;
