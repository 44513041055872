import React, { useEffect, useMemo, useState } from "react";
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import CMPImage from 'components/global/image';
import { NFT } from "../../../models/NFT";
import { selectGlobalNFTs } from '../../../store/global/hooks';
import { useAppSelector } from '../../../store/hooks';

const ElvesLatestMint = () => {
  const theme = useTheme();
  const nfts = useAppSelector(selectGlobalNFTs);
  const [latest4, setLatest4] = useState<NFT[]>([]);
  useEffect(() => {
    if (!nfts.Elves) {
      return;
    }
    const sorted = Object.values(nfts.Elves)
      .sort((a, b) => (a.tokenId - b.tokenId));
    const latest = sorted.slice(sorted.length - 4, sorted.length);
    if (!latest.map(n => n.tokenId).every((v, i) => v === latest4.map(n => n.tokenId)?.[i])) {
      setLatest4(latest);
    }

  }, [nfts, latest4]);

  return (
    <Card
      sx={{
        borderRadius: '16px',
        background: theme.palette.mintBg,
      }}
    >
      <CardContent sx={{ padding: 5 }}>
        <Typography sx={{ fontSize: 28, fontWeight: 600, marginBottom: 5 }}>Recently Minted Elves</Typography>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {latest4.map(nft => (
            <Grid item xs={12} sm={6} md={3} key={nft.tokenId}>
              <CMPImage
                asset={{
                  src: nft.asset.src,
                  alt: 'elf1',
                }}
                gender={nft.attributes.gender}
                width="100%"
              />
              <Typography>#{nft.tokenId}</Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ElvesLatestMint;
