import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Strings from 'utils/Strings';
import { characterRaces } from 'constants/filters/Character';
import { CharacterRace } from 'models/Filters';
import FilterCard from 'components/common/filter-card';
import FilterContainer from 'components/common/filter-container';
import Circle from 'components/global/circle';
import {useAppSelector} from "../../../../../store/hooks";
import {setRaces} from "../../../../../store/marketplace/reducer";

const Races: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { races } = useAppSelector(state => state.marketplace);

  const onSelectHandler = (key: CharacterRace) => {
    const index = races.findIndex(item => item === key);
    if (index === -1) {
      dispatch(setRaces(races.concat(key)));
    } else {
      dispatch(setRaces(races.slice(0, index).concat(races.slice(index+1, races.length))));
    }
  };

  return (
    <FilterContainer
      className="mt-3"
      title={Strings.races}
      count={races.length > 0 ? races.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
          {characterRaces.filter(race => !race.disabled).map(race => (
            <FilterCard
              key={race.type}
              icon={<Circle color={race.color} />}
              text={race.type}
              onClick={() => onSelectHandler(race.type)}
              active={races.includes(race.type)}
              disabled={race.disabled}
            />
          ))}
        </div>
      }
    />
  );
};
export default Races;
