import { styled } from '@mui/material';

const StyledResourceCard = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 78,
  height: 52,
  backgroundColor: theme.palette.filterCard,
  borderRadius: '18px',
  margin: '4px',
}));

export default StyledResourceCard;
