import { constants, providers, Signer, utils } from 'ethers';
import { showSnackbar } from '../components/global/snackbar';
import { Addresses } from '../constants/Addresses';
import store from '../store';
import { CreateLMagicContract, CreateMagicContract, CreateXMagicContract } from './CreateContract';
import {setAppLoading} from "../store/app/reducer";

const approval = async (signer: Signer) => {
  const magic = CreateMagicContract(signer);
  const network = await signer.provider?.getNetwork()
  if (!network) {
    return new Error('No network');
  }
  const xMagic = Addresses[network.chainId].Tokens.xMAGIC;
  const address = magic.signer.getAddress();
  const approved = await magic.allowance(address, xMagic);
  if (approved.lt(constants.MaxUint256.div(2))) {
    const txApproval = await magic.approve(xMagic, constants.MaxUint256);
    await txApproval.wait(1);
    showSnackbar({
      message: `Approved Magick`,
      severity: 'success',
    });
  }
};

export const Deposit = async (amount: string, signer: Signer): Promise<boolean> => {
  const contract = CreateXMagicContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    await approval(signer);
    const tx = await contract.deposit(utils.parseEther(amount));
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Deposited ${amount} MAGICK`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const Withdraw = async (amount: string, signer: Signer): Promise<boolean> => {
  const contract = CreateXMagicContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    await approval(signer);
    const tx = await contract.withdraw(utils.parseEther(amount));
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Withdrew ${amount} xMAGIC`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const ClaimLMagic = async (signer: Signer): Promise<boolean> => {
  const contract = CreateLMagicContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.claimPending();
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Claimed Magick`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};
