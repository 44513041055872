import { styled } from '@mui/material';

export const classes = {
  button: 'side-menu-button',
};

const CMPMenu = styled('div')(({ theme }) => ({
  width: '50%',
  maxWidth: 138,
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
  },
  [`& .${classes.button}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.sideMenu,
    width: 'calc(100% - 16px)',
    height: 48,
    margin: 8,
    padding: 16,
    borderRadius: 18,
    cursor: 'pointer',
  },
}));
export default CMPMenu;
