import React from 'react';
import { CustomIconProps, setColor } from '..';

const ArtifactsIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.81" height="16.5" viewBox="0 0 10.81 16.5">
      <g id="potion" transform="translate(-346.733 -371.75)">
        <path
          id="potion-2"
          data-name="potion"
          d="M200.109,41.252V36.143A1.143,1.143,0,0,0,198.966,35H196.68a1.143,1.143,0,0,0-1.143,1.143v5.109a5.143,5.143,0,0,0,1.714,9.714,4.975,4.975,0,0,0,.571.034,5.143,5.143,0,0,0,2.286-9.749Zm-3.783.9a.571.571,0,0,0,.354-.526v-5.48h2.286v5.48a.571.571,0,0,0,.354.526,4,4,0,0,1,2.337,2.566h-7.651a4,4,0,0,1,2.32-2.566Zm4.16,6.692h0a3.955,3.955,0,0,1-3.132,1.017,3.9,3.9,0,0,1-3.531-4h8a4,4,0,0,1-1.337,2.983Z"
          transform="translate(154.313 337)"
          fill={iconColor}
          stroke={iconColor}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
export default ArtifactsIcon;
