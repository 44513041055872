import { Box, Stack, Typography } from "@mui/material";
import { ConnectKitButton } from "connectkit";
import React from "react";

const LoginRequiredPage = () => {
  return (
    <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h3" textAlign="center">Almost there!</Typography>
      <Typography variant="h5" textAlign="center">
        This page has content that is only relevant when logged in.
      </Typography>
      <ConnectKitButton />
    </Stack>
  )
}

export default LoginRequiredPage;
