import { useEffect } from 'react';
import axios from 'axios';
import { TokenURIsToItems } from 'utils/Contracts';
import GetLogger from 'components/global/Logger';
import { useAppDispatch } from '../../hooks';
import { ItemsState } from '../../wallet/types';
import { setGlobalItems } from '../reducer';
import { I1155 } from '../types';

const GlobalItemUpdater = (): null => {
  const log = GetLogger('Global Item Updater');
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getNfts = async () => {
      const response = await axios.get(`https://api.cosmicuniverse.io/items`);
      if (response.status === 200) {
        const data = response.data as { [name: string]: I1155 };
        const allItems: ItemsState = {};

        Object.entries(data).map(([name, info]) => {
          allItems[name] = TokenURIsToItems(name, info);
        });
        dispatch(setGlobalItems(allItems));
      }
    };
    getNfts();
    const interval = setInterval(() => {
      getNfts();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return null;
};

export default GlobalItemUpdater;
