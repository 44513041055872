import React, { ReactNode, ReactNodeArray } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface IProps extends ButtonProps {
  rounded?: boolean;
  component?: React.ReactNode;
  to?: string;
  children?: any;
}

const CMPButton: React.FunctionComponent<IProps> = ({ rounded, children, ...props }) => {
  const { style } = props;
  return (
    <Button style={{ borderRadius: rounded ? 35 : 18, fontWeight: 'bold', ...style }} {...props}>
      {children}
    </Button>
  );
};
export default CMPButton;
