import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import ProfileSummaryActions from './components/profile-summary-actions';
import ProfileSummaryInformation from './components/profile-summary-information';
import ProfileSummaryInventory from './components/profile-summary-inventory';

const ProfileSummary: React.FunctionComponent = () => {
  const location = useLocation();
  return (
    <Fragment>
      {/* {location.pathname.endsWith('/profile') && <ProfileSummaryActions />} */}
      <div className="d-flex align-items-center flex-column w-100">
        <ProfileSummaryInformation />
        <ProfileSummaryInventory />
      </div>
    </Fragment>
  );
};

export default ProfileSummary;
