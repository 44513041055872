import React from 'react';
import { useDispatch } from 'react-redux';
import { SortType } from 'models/@types';
import CMPDropdown from 'components/common/dropdown';
import {useAppSelector} from "../../../../store/hooks";
import {setSort} from "../../../../store/marketplace/reducer";

interface ISortItem {
  key: SortType;
  text: string;
}

const items: ISortItem[] = [
 // { key: '+sale.price', text: 'Lowest Price' },
 // { key: '-sale.price', text: 'Highest Price' },
  { key: '+tokenId', text: 'Lowest ID' },
  { key: '-tokenId', text: 'Highest ID' },
  { key: '+rarity', text: 'Lowest Rank' },
  { key: '-rarity', text: 'Highest Rank' },
  { key: '-createdAt', text: 'Latest' },
];

interface IProps {
  minWidth?: string | number;
  maxWidth?: string | number;
  withPaddingEnd?: boolean;
  ban?: string[];
}

const Sort: React.FunctionComponent<IProps> = ({ minWidth, maxWidth, withPaddingEnd = true, ban }) => {
  const dispatch = useDispatch();
  const { sort } = useAppSelector(state => state.marketplace);

  const filterBanOptions = (list: ISortItem[], banList?: string[]) => {
    let newList: ISortItem[] = list;

    if (banList) {
      banList.forEach(element => {
        newList = newList.filter(item => !item.key.includes(element));
      });
    }
    return newList;
  };

  const onChangeSort = (sortItem: SortType) => {
    dispatch(setSort(sortItem));
  };

  return (
    <CMPDropdown<SortType>
      items={filterBanOptions(items, ban)}
      selected={filterBanOptions(items, ban).find(item => item.key === sort)}
      onSelect={item => onChangeSort(item.key)}
      minWidth={minWidth}
      maxWidth={maxWidth}
      className={withPaddingEnd ? 'me-3' : undefined}
    />
  );
};

export default Sort;
