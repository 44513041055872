import React from 'react';
import { CustomIconProps } from '../index';

const BankIcon: React.FunctionComponent<CustomIconProps> = ({ active }) => {
  const inactive = '#ffd58c'
  const activeColor = '#111111'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 338.6666565 337.3333435"
      enableBackground="new 0 0 338.6666565 337.3333435"
    >
      <g id="Background_Colour">
        <path
          fill={active ? '#E9E9E9' : activeColor}
          stroke="#685F7C"
          strokeMiterlimit="10"
          d="M317.5,329.9570923H21.0303497 c-6.3325844,0-11.466157-5.1335754-11.466157-11.466156V18.175724c0-6.3325844,5.1335726-11.466157,11.466157-11.466157H317.5 c6.3325806,0,11.466156,5.1335726,11.466156,11.466157v300.3152161 C328.966156,324.8235168,323.8325806,329.9570923,317.5,329.9570923z"
        />
      </g>
      <g id="Strokes">
        <g>
          <path fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M-474,347.3333435" />
          <path d="M-474,347.3333435" />
          <path fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M-474,347.3333435" />
        </g>
        <g>
          <path
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="10"
            strokeMiterlimit="10"
            d="M310.3324585,330.5122375H28.8906975 c-11.6827908,0-21.1535645-9.4707642-21.1535645-21.1535645V27.9169025c0-11.6827908,9.4707737-21.1535645,21.1535645-21.1535645 h281.4417725c11.6828003,0,21.1535645,9.4707737,21.1535645,21.1535645v281.4417725 C331.4860229,321.0414734,322.0152588,330.5122375,310.3324585,330.5122375z"
          />
          <path
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="10"
            strokeMiterlimit="10"
            d="M39.2093544,93.3333282"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="10"
            strokeMiterlimit="10"
            x1="39.2093544"
            y1="212.6666718"
            x2="39.2093544"
            y2="133.3333282"
          />
          <path
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="10"
            strokeMiterlimit="10"
            d="M39.2093544,212.6666718"
          />
          <path
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="10"
            strokeMiterlimit="10"
            d="M39.2093544,93.3333282V59.3891258 c0-5.6492462,2.214489-10.7812691,5.8227425-14.5753555c3.853714-4.0521851,9.2972755-6.5782089,15.330822-6.5782089H278.86026 c11.6827698,0,21.1535645,9.4707718,21.1535645,21.1535645v218.4973145c0,11.6828003-9.4707947,21.1535645-21.1535645,21.1535645 H60.3629189c-11.6827888,0-21.1535645-9.4707642-21.1535645-21.1535645v-25.5531158"
          />
          <path
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="10"
            strokeMiterlimit="10"
            d="M46.4176903,131.6666718H32.0010223 c-2.1918793,0-3.96875-1.7768707-3.96875-3.9687576v-29.0625c0-2.1918793,1.7768707-3.96875,3.96875-3.96875h14.4166679 c2.1918793,0,3.96875,1.7768707,3.96875,3.96875v29.0625C50.3864403,129.889801,48.6095695,131.6666718,46.4176903,131.6666718z"
          />
          <path
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="10"
            strokeMiterlimit="10"
            d="M46.4176903,251H32.0010223 c-2.1918793,0-3.96875-1.7768707-3.96875-3.96875v-29.0625c0-2.1918793,1.7768707-3.96875,3.96875-3.96875h14.4166679 c2.1918793,0,3.96875,1.7768707,3.96875,3.96875v29.0625C50.3864403,249.2231293,48.6095695,251,46.4176903,251z"
          />
          <circle
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="10"
            strokeMiterlimit="10"
            cx="169.2651672"
            cy="168.3333282"
            r="61.6522255"
          />
          <circle
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="26"
            strokeMiterlimit="10"
            cx="169.2651672"
            cy="168.3333282"
            r="13.0875072"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="169.2651672"
            y1="110.9657364"
            x2="169.2651672"
            y2="125.1290817"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="111.8261795"
            y1="168.3333282"
            x2="125.9895325"
            y2="168.3333282"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="169.2651672"
            y1="225.6689758"
            x2="169.2651672"
            y2="211.5056305"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="226.6335602"
            y1="168.3333282"
            x2="212.4702148"
            y2="168.3333282"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="211.4702148"
            y1="206.5482178"
            x2="201.4725494"
            y2="196.5505524"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="126.9895325"
            y1="206.5482178"
            x2="136.9351196"
            y2="196.6026306"
          />
          <path
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            d="M202.5833282,139.3333282"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="126.9895325"
            y1="130.2947693"
            x2="136.9611511"
            y2="140.2664032"
          />
          <line
            fill="none"
            stroke={active ? activeColor : inactive}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="211.7132111"
            y1="130.2947693"
            x2="201.6244354"
            y2="140.3835602"
          />
        </g>
      </g>
    </svg>
  );
};

export default BankIcon;
