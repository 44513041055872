import { LandsRegion } from 'models/Filters';

export interface IRegionFilter {
  color: string;
  text: LandsRegion;
}

export const regionItems: IRegionFilter[] = [
  { color: '#8BCD33', text: 'Elysian Fields' },
  { color: '#EA5568', text: 'Enchanted Forest' },
  { color: '#FFCE38', text: 'Mystic Alpines' },
  { color: '#BA7DFF', text: 'Forest of Whimsy' },
];
