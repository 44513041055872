import { Box, Stack, Typography } from "@mui/material";
import { ConnectKitButton } from "connectkit";
import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import GetLogger from "../../components/global/Logger";

interface Props {
  until: number
  children: ReactNode
}

const MaintenanceWrapper: FC<Props> = ({ until, children }) => {
  const log = GetLogger("Maintenance Wrapper")
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [inMaintenance, setInMaintenance] = useState<boolean>(Date.now() < until)
  const getTimeRemaining = () => {
    const total = Math.floor(until - Date.now());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor(total / 1000 / 60 / 60 / 24);
    if (total >= 0) {
      setTimeRemaining(
        (days > 9 ? days : '0' + days) + ':' +
        (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      )
      setInMaintenance(true);
    } else {
      setInMaintenance(false);
    }
  }

  log.debug(timeRemaining, inMaintenance, Date.now(), until, Date.now() - until);
  useEffect(() => {
    if (!inMaintenance) {
      return;
    }
    const i = setInterval(getTimeRemaining, 1000)
    return () => {
      clearInterval(i);
    }
  }, [timeRemaining, inMaintenance])
  return inMaintenance ? (
    <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h3" textAlign="center">Pardon Our Dust!</Typography>
      <Typography variant="h5" textAlign="center">
        This page is under maintenance, and will be back soon.
      </Typography>
      <Typography variant="h5" textAlign="center">
        { timeRemaining }
      </Typography>
    </Stack>
  ) : <Fragment>{children}</Fragment>;
}

export default MaintenanceWrapper;
