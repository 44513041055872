import React, { useState } from 'react';
import { BigNumber, utils } from "ethers";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader, Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import { useContractRead } from "wagmi";
import { usePopover } from "../../components/common/Popover";
import CMPButton from "../../components/global/button";
import { ABIs, Addresses } from "../../constants/Addresses";
import Chains from "../../constants/wagmi/chains";
import { useAppSelector } from '../../store/hooks';
import { newBalance } from "../../store/wallet/reducer";
import { CleanLocaleBN } from '../farms/functions';
import DepositModal from './DepositModal';
import WithdrawModal from './WithdrawModal';

const StyledCard = styled(Card)({
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '320px',
  height: `100%`,
});

const XMagicCard = () => {
  const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false);
  const [depositOpen, setDepositOpen] = useState<boolean>(false);

  const walletMagic = useAppSelector(state => state.wallet.tokens.MAGIC);
  const walletXMagic = useAppSelector(state => state.wallet.tokens.xMAGIC);
  const magic = walletMagic || newBalance();
  const xMagic = walletXMagic || newBalance();
  const [lockedMagic, setLockedMagic] = useState<BigNumber>(BigNumber.from(0))

  const {
    popover: TDPopover,
    handleOpen: handleTDPOpen,
    handleClose: handleTDPClose
  } = usePopover({
    title: 'Total Deposited',
    text: `Total MAGICK: ${CleanLocaleBN(magic.total)} | Deposited: ${xMagic.total.gt(0) ? xMagic.total.mul(10_000).div(magic.total).toNumber() / 100 : 0}%`}
  )
  useContractRead({
    address: Addresses[Chains.avalanche.id].Tokens.MAGIC,
    abi: ABIs.Tokens.MAGIC,
    functionName: "balanceOf",
    args: [Addresses[Chains.avalanche.id].Tokens.xMAGIC || "0x"],
    chainId: Chains.avalanche.id,
    cacheTime: 600_000,
    onSuccess: (data) => {
      setLockedMagic(data);
    }
  })
  const getMultiplier = () => {
    try {
      return lockedMagic.gt(0) ? lockedMagic.mul(100000).div(xMagic.total) : BigNumber.from(0);
    } catch (err) {
      return BigNumber.from(0);
    }
  };
  return (
    <StyledCard>
      <CardHeader title="xMAGIC" sx={{ textAlign: 'center', paddingBottom: 0 }} />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>APR</TableCell>
              <TableCell align="right">Variable</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Multiplier</TableCell>
              <TableCell align="right">{getMultiplier().gt(0) ? getMultiplier().toNumber() / 100000 : 1}x</TableCell>
            </TableRow>
            <TableRow onMouseEnter={handleTDPOpen} onMouseLeave={handleTDPClose}>
              <TableCell>Total Deposited</TableCell>
              <TableCell align="right">{CleanLocaleBN(lockedMagic)} MAGICK</TableCell>
            </TableRow>
            {TDPopover}
            <TableRow>
              <TableCell>Balance</TableCell>
              <TableCell align="right">{CleanLocaleBN(xMagic.balance)} xMAGIC</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions sx={{ marginTop: 'auto' }}>
        <CMPButton fullWidth onClick={() => setDepositOpen(true)} disabled={magic.balance.eq(0)}>
          Deposit
        </CMPButton>
        <CMPButton fullWidth onClick={() => setWithdrawOpen(true)} disabled={xMagic.balance.eq(0)}>
          Withdraw
        </CMPButton>
      </CardActions>
      <WithdrawModal open={withdrawOpen} setOpen={setWithdrawOpen} balance={xMagic.balance} />
      <DepositModal open={depositOpen} setOpen={setDepositOpen} balance={magic.balance} />
    </StyledCard>
  );
};

export default XMagicCard;
