import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { WalletState, SetTokenBalancePayload, TokenBalance } from './types';

export const newBalance = (): TokenBalance => ({
  timestamp: 0,
  balance: BigNumber.from(0),
  unlocked: BigNumber.from(0),
  locked: BigNumber.from(0),
  total: BigNumber.from(0),
});

const initialState: WalletState = {
  refetch: false,
  nfts: {},
  tokens: {},
  items1155: {},
  items721: {}
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletNfts: (state, action: PayloadAction<{ [index: string]: any }>) => {
      state.nfts = { ...state.nfts, ...action.payload };
    },
    setWalletItems1155: (state, action: PayloadAction<{ [index: string]: any }>) => {
      state.items1155 = { ...state.items1155, ...action.payload };
    },
    setWalletItems721: (state, action: PayloadAction<{ [index: string]: any }>) => {
      state.items721 = { ...state.items721, ...action.payload };
    },
    setWalletTokenBalanceOf: (state, action: PayloadAction<SetTokenBalancePayload>) => {
      state.tokens[action.payload.token] = {
        ...newBalance(),
        ...state.tokens[action.payload.token],
        ...action.payload.balance
      }
    },
  },
});

export const { setWalletNfts, setWalletItems1155, setWalletItems721, setWalletTokenBalanceOf } = walletSlice.actions;

export default walletSlice.reducer;
