import { providers, Signer } from 'ethers';
import store from 'store';
import { CreateHRC1155Contract, CreateHRC721Contract } from 'services/CreateContract';
import { showSnackbar } from 'components/global/snackbar';
import { NFT } from '../models/NFT';
import {setAppLoading} from "../store/app/reducer";

export type NFTTransferType = 'single' | 'bulk';

export const NFTTransferService = async (
  nft: NFT,
  to: string,
  type: NFTTransferType = 'single',
  signer: Signer,
): Promise<boolean> => {
  const contract = CreateHRC721Contract(nft.contract, signer);
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.transferFrom(nft.owner, to, nft.tokenId);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.reason ?? err?.error?.message ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const ItemTransferService = async (nft: NFT, to: string, amount: number, signer: Signer): Promise<boolean> => {
  const contract = CreateHRC1155Contract(nft.contract, signer);
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.safeTransferFrom(nft.owner, to, nft.tokenId, amount, []);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.reason ?? err?.error?.message ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};
