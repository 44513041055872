import { styled } from '@mui/material';
import Utils from 'libs/Utils';

const View = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 18,
  height: 52,
  background: theme.palette.filterBg,
  border: Utils.getAppTheme() === 'light' ? `1px solid ${theme.palette.divider}` : undefined,
  padding: '0 8px',
  [theme.breakpoints.up('md')]: {
    borderRadius: 24,
    height: 68,
  },
}));
export default View;
