import { ComponentType } from 'react';
import { Blockchain, CharacterGender, CharacterRace, CosmicRewardBonusType } from "models/Filters";
import { CustomIconProps } from 'components/icons';
import FemaleIcon from 'components/icons/main/FemaleIcon';
import MaleIcon from 'components/icons/main/MaleIcon';

// Character Races
export interface ICharacterRace {
  color: string;
  type: CharacterRace;
  disabled?: boolean;
}
export const characterRaces: ICharacterRace[] = [
  { color: '#9D73CD', type: 'Wizards' },
  { color: '#F4B63A', type: 'Elves' },
  { color: '#FF6B6B', type: 'Apes', disabled: true },
  { color: '#1DD1A1', type: 'Trolls', disabled: true },
  { color: '#0BB4E7', type: 'Felines', disabled: true },
  { color: '#D5A35C', type: 'Yetis', disabled: true },
  { color: '#F485E5', type: 'Dwarves', disabled: true },
];

export interface IBlockchain {
  color: string;
  type: Blockchain;
  disabled?: boolean;
}
export const Blockchains: IBlockchain[] = [
  { color: '#9D73CD', type: 'Avalanche' },
  { color: '#F4B63A', type: 'BobaAvax' },
]
// Character Genders
export interface IGenderItem {
  type: CharacterGender;
  icon: ComponentType<CustomIconProps>;
}
export const genderItems: IGenderItem[] = [
  { icon: MaleIcon, type: 'Male' },
  { icon: FemaleIcon, type: 'Female' },
];

export interface ICosmicRewardBonus {
  type: CosmicRewardBonusType;
}

export const cosmicRewardBonusItems: ICosmicRewardBonus[] = [
  { type: '1%' },
  { type: '2%' },
  { type: '3%' },
  { type: '4%' },
  { type: '5%' },
];
