import React from 'react';
import clsx from 'clsx';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { NFTCategory } from 'models/@types';
import CMPDropdown, { IDropDownItem } from 'components/common/dropdown';
import CMPButton from 'components/global/button';
import { categories } from './_data';
import CategoriesDiv from './_styles';

interface IProps {
  activeItem: NFTCategory;
  onChangeCategory?: (cat: NFTCategory) => void;
  className?: string;
  fullWidth?: boolean;
  withoutIcon?: boolean;
  disabledCategories?: NFTCategory[];
}

const Categories: React.FunctionComponent<IProps> = ({
  onChangeCategory,
  activeItem,
  className,
  fullWidth,
  withoutIcon = false,
  disabledCategories,
}) => {
  const currentCategory = categories.find(item => item.key === activeItem);
  const CurrentCategoryIcon = currentCategory?.icon;
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const onChange = (item: IDropDownItem<NFTCategory>) => {
    if (activeItem !== item.key) {
      if (onChangeCategory) {
        onChangeCategory(item.key);
      }
    }
  };

  if (matches) {
    return (
      <CategoriesDiv className={clsx({ 'w-100': fullWidth })}>
        {categories
          .filter(c => (disabledCategories ? !disabledCategories.includes(c.key) : true))
          .map(({ icon: Icon, ...item }) => (
            <CMPButton
              key={item.key}
              onClick={() => onChange({ key: item.key, text: item.text })}
              // @ts-ignore
              startIcon={withoutIcon ? null : <Icon />}
              variant={activeItem === item.key ? 'contained' : 'text'}
              sx={{
                minWidth: item.width,
                height: 52,
                color: theme => (activeItem !== item.key ? theme.palette.text.primary : undefined),
                padding: activeItem !== item.key ? '0 16px' : undefined,
              }}
            >
              {item.text}
            </CMPButton>
          ))}
      </CategoriesDiv>
    );
  }

  return (
    <CMPDropdown<NFTCategory>
      className={className}
      items={categories}
      selectedComponent={
        <div className="d-flex align-items-center">
          {/* @ts-ignore */}
          {CurrentCategoryIcon && <CurrentCategoryIcon />}
          <Typography fontWeight="bold" className="ms-2">
            {currentCategory?.text}
          </Typography>
        </div>
      }
      onSelect={onChange}
    />
  );
};
export default Categories;
