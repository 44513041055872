import { SaleType } from "store/market/types";
import * as Types from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Attributes,
  NFTCategory,
  SortType,
  ViewMode,
} from "../../models/@types";
import {
  Blockchain,
  CharacterGender,
  CharacterRace,
  CosmicRewardBonusType,
  ItemRarity,
  ItemType,
  LandsRegion,
  ResourceType,
  StructureType,
} from "../../models/Filters";
import { NFTProfession, prettyNFTProfessions } from "models/NFT";
import { IQuery } from "../../models/request/Query";

export const initialState: Types.IMarketPlaceState = {
  forSale: false,
  saleTypes: [SaleType.AUCTION, SaleType.FIXED],
  races: [],
  view: "grid",
  category: "character",
  genders: [],
  attributes: [],
  resourceTypes: [],
  regions: [],
  rarities: [],
  cosmicRewardBonuses: [],
  types: [],
  structureTypes: [],
  page: 1,
  queries: [],
  sort: "+tokenId",
  characterId: "",
  blockchains: [],
  professions: []
};

export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    clearFilters: (state, action: PayloadAction<boolean>) => {
      state.forSale = true;
      state.saleTypes = [SaleType.AUCTION, SaleType.FIXED];
      state.races = [];
      state.genders = [];
      state.attributes = [];
      state.resourceTypes = [];
      state.regions = [];
      state.rarities = [];
      state.cosmicRewardBonuses = [];
      state.types = [];
      state.structureTypes = [];
      state.queries = [];
      state.sort = "+tokenId";
      state.characterId = "";
      state.blockchains = [];
      state.professions = [];
    },
    setRaces: (state, action: PayloadAction<CharacterRace[]>) => {
      state.races = action.payload;
    },
    setSaleTypes: (state, action: PayloadAction<SaleType[]>) => {
      state.saleTypes = action.payload;
    },
    setResourceTypes: (state, action: PayloadAction<Attributes[]>) => {
      state.resourceTypes = action.payload;
    },
    setAttributes: (state, action: PayloadAction<Attributes[]>) => {
      state.attributes = action.payload;
    },
    setView: (state, action: PayloadAction<ViewMode>) => {
      state.view = action.payload;
    },
    setStructureTypes: (state, action: PayloadAction<StructureType[]>) => {
      state.structureTypes = action.payload;
    },
    setQueries: (state, action: PayloadAction<IQuery[]>) => {
      state.queries = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSort: (state, action: PayloadAction<SortType>) => {
      state.sort = action.payload;
    },
    setCharacterId: (state, action: PayloadAction<string>) => {
      state.characterId = action.payload;
    },
    setCategory: (state, action: PayloadAction<NFTCategory>) => {
      state.category = action.payload;
    },
    setGenders: (state, action: PayloadAction<CharacterGender[]>) => {
      state.genders = action.payload;
    },
    setRegions: (state, action: PayloadAction<LandsRegion[]>) => {
      state.regions = action.payload;
    },
    setRarities: (state, action: PayloadAction<ItemRarity[]>) => {
      state.rarities = action.payload;
    },
    setCosmicRewardBonuses: (
      state,
      action: PayloadAction<CosmicRewardBonusType[]>
    ) => {
      state.cosmicRewardBonuses = action.payload;
    },
    setTypes: (state, action: PayloadAction<ItemType[]>) => {
      state.types = action.payload;
    },
    setBlockchains: (state, action: PayloadAction<Blockchain[]>) => {
      state.blockchains = action.payload;
    },
    setProfessions: (state, action: PayloadAction<NFTProfession[]>) => {
      state.professions = action.payload;
    },
  },
});

export const {
  setPage,
  clearFilters,
  setCategory,
  setCharacterId,
  setRarities,
  setAttributes,
  setQueries,
  setRaces,
  setGenders,
  setSort,
  setView,
  setRegions,
  setCosmicRewardBonuses,
  setTypes,
  setStructureTypes,
  setSaleTypes,
  setResourceTypes,
  setBlockchains,
  setProfessions,
} = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
