import { OutlinedInput, styled } from '@mui/material';

const StylesCMPInput = styled(OutlinedInput)(({ theme }) => ({
  border: '1px solid',
  transition: 'border-color 100ms linear',
  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
}));
export default StylesCMPInput;
