import { Card, CardHeader, CardMedia, Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import React, { FC } from "react";
import ElvesCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Elves.png';
import WizardsCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Wizards.png';
import LandCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Land.png';
import TicketsCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Tickets.png';
import FramedCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Framed.png';
import ChestsCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Chests.png';
import BadgesCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Badges.png';
import ComponentsCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Components.png';
import PotionsCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Potions.png';
import RawResourcesCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Raw_Resources.png';
import SkinsCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Skins.png';
import RefinedResourcesCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Refined_Resources.png';
import ToolsCollection from '../../../../assets/images/nfts/collections/Opensea_Featured_Tools.png';
import CloseIcon from "../../../icons/main/CloseIcon";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}
const OpenSeaCollectionsModal: FC<Props> = ({ open, setOpen }) => {
  const collections = [
    { name: "Wizards", img: WizardsCollection, slug: "cu-cosmic-wizards" },
    { name: "Elves", img: ElvesCollection, slug: "cu-cosmic-elves" },
    { name: "Cosmic Island Land", img: LandCollection, slug: "cu-cosmic-island-land" },
    { name: "Framed Wizards", img: FramedCollection, slug: "cu-framed-wizards" },
    { name: "Elves Tickets", img: TicketsCollection, slug: "cu-cosmic-elves-discount-ticket" },
    { name: "Bundles", img: ChestsCollection, slug: "cu-cosmic-bundles" },
    { name: "Badges", img: BadgesCollection, slug: "cu-cosmic-badges" },
    { name: "Tools", img: ToolsCollection, slug: "cu-cosmic-tools" },
    { name: "Components", img: ComponentsCollection, slug: "cu-cosmic-components" },
    { name: "Raw Resources", img: RawResourcesCollection, slug: "cu-cosmic-raw-resources" },
    { name: "Refined Resources", img: RefinedResourcesCollection, slug: "cu-cosmic-refined-resources" },
    { name: "Potions", img: PotionsCollection, slug: "cu-cosmic-potions" },
    { name: "Skins", img: SkinsCollection, slug: "cu-cosmic-skins" },
  ]
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { borderRadius: '24px' } }}
      fullWidth
      maxWidth="md"
    >
      <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ textAlign: 'center' }}>OpenSea Collections</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ justifyContent: 'space-evenly'}}>
          {collections.map((c, i) => (
            <Grid item key={i}>
              <a href={`https://opensea.io/collection/${c.slug}`} target="_blank" style={{ textDecoration: 'none' }}>
                <Card sx={{ borderRadius: '24px' }}>
                  <CardMedia component="img" height="194" alt={`${c.name} collection`} image={c.img} />
                  <CardHeader title={c.name} sx={{ textAlign: 'center' }} />
                </Card>
              </a>

            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default OpenSeaCollectionsModal;
