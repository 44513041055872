import React from 'react';
import { CustomIconProps, setColor } from '..';

const InventoryIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="vuesax_bold_box" data-name="vuesax/bold/box" transform="translate(-108 -188)">
        <path
          id="Vector"
          d="M11.29,3.878,6.157,6.852a.687.687,0,0,1-.68,0L.343,3.878a.676.676,0,0,1-.18-1.027A2.392,2.392,0,0,1,.81,2.325l3.613-2a3.178,3.178,0,0,1,2.8,0l3.613,2a2.524,2.524,0,0,1,.647.527A.688.688,0,0,1,11.29,3.878Z"
          transform="translate(110.183 189.335)"
          fill={iconColor}
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M6.133,3.47V8.017a.674.674,0,0,1-.967.62C3.793,7.964,1.48,6.7,1.48,6.7A3.267,3.267,0,0,1,0,4.13V.69A.674.674,0,0,1,1.007.09L5.8,2.87A.717.717,0,0,1,6.133,3.47Z"
          transform="translate(109.487 193.956)"
          fill={iconColor}
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,3.47V8.017a.674.674,0,0,0,.967.62C2.34,7.964,4.653,6.7,4.653,6.7A3.267,3.267,0,0,0,6.133,4.13V.69A.674.674,0,0,0,5.127.09L.333,2.87A.717.717,0,0,0,0,3.47Z"
          transform="translate(116.38 193.956)"
          fill={iconColor}
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H16V16H0Z"
          transform="translate(124 204) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};
export default InventoryIcon;
