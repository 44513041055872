import { AppState } from '../index';

export const selectWalletTokenBalances = (state: AppState) => state.wallet.tokens;
export const selectMagicWalletBalance = (state: AppState) => state.wallet.tokens.MAGIC;
export const selectlMagicWalletBalance = (state: AppState) => state.wallet.tokens.lMAGIC;
export const selectCosmicWalletBalance = (state: AppState) => state.wallet.tokens.COSMIC;
export const selectWalletUSDCBalance = (state: AppState) => state.wallet.tokens.USDC;
export const selectxMagicWalletBalance = (state: AppState) => state.wallet.tokens.xMAGIC;
export const selectRefetchWalletBalances = (state: AppState) => state.wallet.refetch;

export const selectWalletNFTs = (state: AppState) => state.wallet.nfts;
export const selectWalletNftsWizards = (state: AppState) => state.wallet.nfts?.Wizards || {};
export const selectWalletNftsElvesTickets = (state: AppState) => state.wallet.nfts.ELVES_TICKET;
export const selectWalletItems1155 = (state: AppState) => state.wallet.items1155;
export const selectWalletItems721 = (state: AppState) => state.wallet.items721;
