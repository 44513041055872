import React, { ComponentPropsWithoutRef } from 'react';
import { styled } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface IProps extends ComponentPropsWithoutRef<'div'> {
  sx?: SxProps<Theme>;
}

const StyledDiv = styled('div')();

const Div: React.FunctionComponent<IProps> = ({ sx, children, ...props }) => {
  return (
    <StyledDiv sx={sx} {...props}>
      {children}
    </StyledDiv>
  );
};

export default Div;
