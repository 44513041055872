import { CSSProperties } from 'react';
import { styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const classes = {
  image: 'image',
  information: 'information',
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 24,
  textTransform: 'capitalize',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.filterCard,
    color: theme.palette.text.secondary,
    fontSize: '1rem',
  } as CSSProperties,
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1rem',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  whiteSpace: 'nowrap',
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.filterBg,
  whiteSpace: 'nowrap',
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
