import { ResourceType } from 'models/Filters';

type ResourcesAttr = {
  Wood: ('Hemp' | 'Pine' | 'Oak')[];
  Stone: ('Granite' | 'Marble' | 'Lapis Lazuli')[];
  Ore: ('Gold' | 'Mercury' | 'Iron' | 'Platinum')[];
  Crystals: (
    | 'Amethyst'
    | 'Sapphire'
    | 'Ruby'
    | 'Emerald'
    | 'Tourmaline'
    | 'Citrine'
    | 'Fluorite'
    | 'Labradorite'
    | 'Tanzanian'
  )[];
};

export type ICharacterAttributes = {
  [key in ResourceType]: ResourcesAttr[key];
};
export const resourcesTypes: ICharacterAttributes = {
  Wood: ['Hemp', 'Pine', 'Oak'],
  Stone: ['Granite', 'Marble', 'Lapis Lazuli'],
  Ore: ['Gold', 'Mercury', 'Iron', 'Platinum'],
  Crystals: [
    'Amethyst',
    'Sapphire',
    'Ruby',
    'Emerald',
    'Tourmaline',
    'Citrine',
    'Fluorite',
    'Labradorite',
    'Tanzanian',
  ],
};
