import React, { FC, useMemo } from 'react';
import { useAccount } from 'wagmi';
import { useLocation } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import { filterGlobalNfts, userInventories } from 'pages/profile/functions';
import ResourceCard from 'components/common/resource-card';
import GetLogger from '../../../../../../components/global/Logger';
import { DEV_TEST_ADDRESS } from '../../../../../../constants/Statics';
import { selectGlobalNFTs } from '../../../../../../store/global/hooks';
import { useAppSelector } from '../../../../../../store/hooks';
import { selectStakingStaked } from '../../../../../../store/staking/hooks';
import { selectWalletItems1155, selectWalletNFTs } from '../../../../../../store/wallet/hooks';

const ProfileSummaryInventory: FC = () => {
  const log = GetLogger('Profile Summary Inventory');
  const staked = useAppSelector(selectStakingStaked);
  const globalNfts = useAppSelector(selectGlobalNFTs);
  const nfts = useAppSelector(selectWalletNFTs);
  const items = useAppSelector(selectWalletItems1155);
  const { address } = useAccount();
  const wallet = DEV_TEST_ADDRESS || address;
  const { pathname } = useLocation();

  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const inventories = useMemo(() => {
    const s = pathname.split('/');
    if (pathname.endsWith('/profile') || (s.length === 3 && s[2] === wallet)) {
      let n = { ...nfts };
      if (n.Wizards) {
        n.Wizards = { ...nfts.Wizards, ...staked }
      }
      return userInventories(n, items);
    }
    return userInventories(filterGlobalNfts(globalNfts, s[2]));
  }, [pathname, wallet, nfts, staked, items]);

  return (
    <div className={`d-flex align-items-center ${matches ? 'flex-row' : 'flex-column'}`}>
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        {inventories?.map(({ icon: Icon, ...inventory }, index) => (
          // @ts-ignore
          <ResourceCard key={index} icon={<Icon />} count={inventory.amount} />
        ))}
      </div>
      {/* {matches ? <GDivider sx={{ mx: 2 }} /> : <Divider sx={{ my: 2 }} flexItem />} */}
      {/* <div className="d-flex align-items-center justify-content-center flex-wrap">
        {tokens ? (
          <>
            {tokens.map((token, index) => (
              <ResourceCard key={index} icon={token.icon} count={token.count} />
            ))}
          </>
        ) : null}
      </div> */}
    </div>
  );
};

export default ProfileSummaryInventory;
