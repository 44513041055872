import { styled } from '@mui/material';

export const classes = {
  activeLink: 'active-link',
};

const CMPNavbar = styled('nav')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 68,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  padding: '0 8px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: theme.palette.filterBg,
  zIndex: 1000,
  [theme.breakpoints.up('sm')]: {
    width: 'calc(100% - 84px)',
    padding: '0 16px',
  },
  [`& .${classes.activeLink}`]: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 4,
    background: theme.palette.primary.main,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
}));

export default CMPNavbar;
