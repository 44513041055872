import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, Typography } from '@mui/material';
import ArrowDownIcon from 'components/icons/main/ArrowDownIcon';
import Container, { Button, classes } from './_styles';

interface IProps {
  className?: string;
  title: string;
  body: React.ReactNode;
  count?: number;
}

const FilterContainer: React.FunctionComponent<IProps> = ({ title, body, count, className }) => {
  const [open, setOpen] = useState(false);

  const onChangeOpen = () => {
    setOpen(prevState => !prevState);
  };

  return (
    <Container className={className}>
      <Button color="inherit" onClick={onChangeOpen}>
        <div className="d-flex align-items-center">
          <Typography fontWeight="bold">{title}</Typography>
          {count && <Typography className={classes.count}>{count}</Typography>}
        </div>
        <div className={clsx(classes.icon, { [classes.open]: open, [classes.close]: !open })}>
          <ArrowDownIcon />
        </div>
      </Button>
      <Collapse in={open} unmountOnExit>
        <div className="px-3">{body}</div>
      </Collapse>
    </Container>
  );
};
export default FilterContainer;
