import { utils, constants, providers, Signer } from 'ethers';
import store from 'store';
import { setAppLoading } from "store/app/reducer";
import { CreateHRC20Contract, CreateLPTokenContract, CreateFarmContract } from 'services/CreateContract';
import { showSnackbar } from 'components/global/snackbar';

export const lpApproval = async (token: string, signer: Signer) => {
  const lp = CreateLPTokenContract(token, signer);
  const contract = CreateFarmContract(signer);

  const address = lp.signer.getAddress();
  const approved = await lp.allowance(address, contract.address);
  if (approved.lt(constants.MaxUint256.div(2))) {
    const txApproval = await lp.approve(contract.address, constants.MaxUint256);
    await txApproval.wait(1);
    showSnackbar({
      message: `Approved LP Token`,
      severity: 'success',
    });
  }
};

export const StakeLP = async (pid: number, token: string, amount: string, signer: Signer): Promise<boolean> => {
  const contract = CreateFarmContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    await lpApproval(token, signer);
    const tx = await contract.deposit(pid, utils.parseEther(amount));
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Staked ${amount} LP tokens`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const UnstakeLP = async (pid: number, token: string, amount: string, signer: Signer): Promise<boolean> => {
  const contract = CreateFarmContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract['withdraw(uint256,uint256)'](pid, utils.parseEther(amount));
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Unstaked ${amount} LP tokens`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const ClaimLP = async (pid: number[], signer: Signer): Promise<boolean> => {
  const contract = CreateFarmContract(signer);
  console.log(pid);
  try {
    store.dispatch(setAppLoading(true));
    if (pid.length === 1) {
      const tx = await contract.claimReward(pid[0]);
      await tx.wait(1);
    } else {
      const tx = await contract.claimRewards(pid);
      await tx.wait(1);
    }
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Claimed rewards`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const ClaimLocked = async (signer: Signer): Promise<boolean> => {
  const contract = CreateHRC20Contract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.unlock();
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Unlocked MAGICK`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};
