import React, { FC, useEffect, useState } from 'react';
import { useAccount, useContractReads, useSigner } from "wagmi";
import { BigNumber, utils } from 'ethers';
import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import store from 'store';
import { CreateMagicContract } from 'services/CreateContract';
import { ABIs, Addresses } from "../../../constants/Addresses";
import Chains from "../../../constants/wagmi/chains";
import { selectGlobalPrices } from "../../../store/global/hooks";
import { useAppSelector } from "../../../store/hooks";

const CleanLocaleBN = (bn: BigNumber): string => Number(utils.formatEther(bn).split('.')[0]).toLocaleString();

interface StatLineProps {
  text: string;
  value?: BigNumber;
  textValue?: string;
}

const StatLine: FC<StatLineProps> = ({ text, value, textValue }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant="body1" sx={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
        {text}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
        {value ? CleanLocaleBN(value) : textValue}
      </Typography>
    </Box>
  );
};

const MagicStatsCard: FC = () => {
  const [circSupply, setCircSupply] = useState<BigNumber>(BigNumber.from(0));
  const [totalSupply, setTotalSupply] = useState<BigNumber>(BigNumber.from(0));
  const [totalBurned, setTotalBurned] = useState<BigNumber>(BigNumber.from(0));
  const globalPrices = useAppSelector(selectGlobalPrices);
  const magicContract = {
    address: Addresses[Chains.avalanche.id].Tokens.MAGIC,
    abi: ABIs.Tokens.MAGIC,
    chainId: Chains.avalanche.id
  };
  useContractReads({
    contracts: [
      { ...magicContract, functionName: 'totalSupply' },
      { ...magicContract, functionName: 'totalBurned' }
    ],
    cacheTime: 600_000,
    onSuccess: ([ts, tb]) => {
      if (!circSupply.eq(ts.sub(tb))) {
        setCircSupply(ts.sub(tb));
      }
      if (!ts.eq(totalSupply)) {
        setTotalSupply(ts);
      }
      if (!tb.eq(totalBurned)) {
        setTotalBurned(tb);
      }
    }
  })
  return (
    <Card sx={{ borderRadius: '24px', width: 1 }}>
      <CardHeader title="MAGICK Stats" sx={{ textAlign: 'center' }} />
      <CardContent sx={{ paddingBottom: 0, paddingTop: 0 }}>
        <StatLine text="In Circulation" value={circSupply} />
        <StatLine text="Total Supply" value={totalSupply} />
        <StatLine text="Total Burned" value={totalBurned} />
      </CardContent>
      <Divider variant="middle" />
      <CardContent sx={{ paddingBottom: 0, paddingTop: 0 }}>
        <StatLine text="Price" textValue={'$' + globalPrices.MAGIC.price} />
        <StatLine
          text="Circulating Market Cap"
          textValue={'$' + (Number(utils.formatEther(circSupply).split('.')[0]) * globalPrices.MAGIC.price).toLocaleString()}
        />
      </CardContent>
      <Divider variant="middle" />
      <CardContent sx={{ paddingBottom: 0, paddingTop: 0 }}>
        <StatLine text="Base Emission" textValue="1.0" />
        <StatLine text="Emission Multiplier" textValue="x1" />
      </CardContent>
    </Card>
  );
};

export default MagicStatsCard;
