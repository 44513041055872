import { styled } from '@mui/material';

export const NftSectionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '48px',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 1050,
}));
