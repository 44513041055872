import React from 'react';
import { CustomIconProps, setColor } from '..';

const FilterIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="vuesax_bold_filter" data-name="vuesax/bold/filter" transform="translate(-556 -508)">
        <g id="filter" transform="translate(556 508)">
          <path
            id="Vector"
            d="M8.83,1.333V2.8a2.489,2.489,0,0,1-.667,1.533L5.3,6.867A2.17,2.17,0,0,0,4.63,8.4v2.867a1.485,1.485,0,0,1-.6,1.133L3.1,13A1.359,1.359,0,0,1,1.03,11.867V8.333A2.577,2.577,0,0,0,.5,6.933L.184,6.607A.663.663,0,0,1,.1,5.793L3.517.313A.666.666,0,0,1,4.084,0H7.5A1.337,1.337,0,0,1,8.83,1.333Z"
            transform="translate(4.903 1.4)"
            fill={iconColor}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M4.633,1.02,2.267,4.813A.668.668,0,0,1,1.22,4.92L.6,4.267A2.21,2.21,0,0,1,0,2.933V1.4A1.353,1.353,0,0,1,1.333,0H4.067A.669.669,0,0,1,4.633,1.02Z"
            transform="translate(2.267 1.4)"
            fill={iconColor}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H16V16H0Z"
            transform="translate(16 16) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
export default FilterIcon;
