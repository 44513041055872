import React, { FC } from 'react';
import { useAccount, useSigner } from 'wagmi';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import { Grid, IconButton, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ClaimLP } from 'services/Farms';
import CMPButton from 'components/global/button';
import { OldAddresses } from '../../../../constants/Addresses';
import { Pool } from '../../../../store/farm/types';
import { CleanLocaleBN } from '../../functions';

interface PoolSummaryProps {
  pool: Pool;
  openAdd: (open: boolean) => void;
  openRemove: (open: boolean) => void;
  openLiquidity: (open: boolean, add: boolean) => void;
}

const PoolDetails: FC<PoolSummaryProps> = ({ pool, openAdd, openRemove, openLiquidity }) => {
  const { address } = useAccount();
  const { data } = useSigner();
  const handleClaim = async () => {
    if (!data) {
      return;
    }
    await ClaimLP([pool.pid], data);
  };
  return (
    <Grid
      container
      sx={{
        width: 1,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
      }}
      spacing={2}
    >
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <a href={`https://snowtrace.io/address/${pool.token}`} target="_blank" rel="noreferrer">
          <CMPButton>Contract</CMPButton>
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Typography color="primary" sx={{ marginBottom: '0.25rem' }}>
          Manage Liquidity
        </Typography>
        <IconButton
          component={Link}
          color="primary"
          sx={{ border: 1, marginRight: '1rem', visibility: pool.apr === 0 ? 'hidden' : 'visible' }}
          disabled={!address}
          href={`https://traderjoexyz.com/avalanche/pool/v1/${pool.token0}/${
            pool.token1 === OldAddresses.tokens.WAVAX ? 'AVAX' : pool.token1
          }`}
          target="_blank"
        >
          <AddOutlined />
        </IconButton>
        <IconButton
          component={Link}
          color="primary"
          sx={{ border: 1 }}
          disabled={pool.remainBalance.eq(0)}
          href={`https://traderjoexyz.com/avalanche/pool/v1/${pool.token0}/${
            pool.token1 === OldAddresses.tokens.WAVAX ? 'AVAX' : pool.token1
          }`}
          target="_blank"
        >
          <RemoveOutlined />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Typography>{CleanLocaleBN(pool.earned)}</Typography>
        <CMPButton disabled={pool.earned.eq(0)} onClick={handleClaim}>
          Harvest
        </CMPButton>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Typography color="primary">Staked {pool?.cleanName}</Typography>
        <Typography variant="h6">{CleanLocaleBN(pool.balance)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Typography color="primary" sx={{ marginBottom: '0.25rem' }}>
          Manage Staked
        </Typography>
        <IconButton
          color="primary"
          sx={{ border: 1, marginRight: '1rem', visibility: pool.apr === 0 ? 'hidden' : 'visible' }}
          disabled={pool.remainBalance.eq(0)}
          onClick={() => openAdd(true)}
        >
          <AddOutlined />
        </IconButton>
        <IconButton color="primary" sx={{ border: 1 }} disabled={pool.balance.eq(0)} onClick={() => openRemove(true)}>
          <RemoveOutlined />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PoolDetails;
