import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Strings from 'utils/Strings';
import { typeItems } from 'constants/filters/Items';
import { ItemType } from 'models/Filters';
import FilterCard from 'components/common/filter-card';
import FilterContainer from 'components/common/filter-container';
import {useAppSelector} from "../../../../../store/hooks";
import {setTypes} from "../../../../../store/marketplace/reducer";

const ItemTypes: React.FunctionComponent = () => {
  const theme = useTheme();
  const { types } = useAppSelector(state => state.marketplace);
  const dispatch = useDispatch();

  const onSelectHandler = (key: ItemType) => {
    const index = types.findIndex(item => item === key);
    if (index === -1) {
      types.push(key);
      dispatch(setTypes(types));
    } else {
      types.splice(index, 1);
      dispatch(setTypes(types));
    }
  };

  return (
    <FilterContainer
      className="mt-3"
      title={Strings.types}
      count={types.length > 0 ? types.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
          {typeItems.map(({ icon: Icon, type }) => (
            <FilterCard
              key={type}
              // @ts-ignore
              icon={<Icon color={theme.palette.purple} />}
              text={type}
              onClick={() => onSelectHandler(type)}
              active={types.includes(type)}
              purpleMode
            />
          ))}
        </div>
      }
    />
  );
};
export default ItemTypes;
