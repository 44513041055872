import React from 'react';

const AboutIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="about" transform="translate(-556 -316)">
        <g id="bill" transform="translate(556 316)">
          <path id="Vector" d="M0,0H36V36H0Z" fill="none" opacity="0" />
          <path id="Vector-2" data-name="Vector" d="M0,0H36V36H0Z" fill="none" opacity="0" />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0V17.955a4.682,4.682,0,0,0,1.875,3.75l7.815,5.85a4.7,4.7,0,0,0,5.625,0l7.815-5.85A4.682,4.682,0,0,0,25,17.955V0Z"
            transform="translate(5.505 3.75)"
            fill="#ffd58c"
            opacity="0.4"
          />
          <g id="Group" transform="translate(1.875 2.625)">
            <path
              id="Vector-4"
              data-name="Vector"
              d="M31.125,2.25h-30A1.133,1.133,0,0,1,0,1.125,1.133,1.133,0,0,1,1.125,0h30A1.133,1.133,0,0,1,32.25,1.125,1.133,1.133,0,0,1,31.125,2.25Z"
              fill="#ffd58c"
            />
          </g>
          <g id="Group-2" data-name="Group" transform="translate(10.875 10.875)">
            <path
              id="Vector-5"
              data-name="Vector"
              d="M13.125,2.25h-12A1.133,1.133,0,0,1,0,1.125,1.133,1.133,0,0,1,1.125,0h12A1.133,1.133,0,0,1,14.25,1.125,1.133,1.133,0,0,1,13.125,2.25Z"
              fill="#ffd58c"
            />
          </g>
          <g id="Group-3" data-name="Group" transform="translate(10.875 18.375)">
            <path
              id="Vector-6"
              data-name="Vector"
              d="M13.125,2.25h-12A1.133,1.133,0,0,1,0,1.125,1.133,1.133,0,0,1,1.125,0h12A1.133,1.133,0,0,1,14.25,1.125,1.133,1.133,0,0,1,13.125,2.25Z"
              fill="#ffd58c"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default AboutIcon;
