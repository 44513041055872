import { Card, styled } from '@mui/material';

const SummaryCard = styled(Card)({
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '280px',
});

export default SummaryCard;
