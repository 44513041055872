import React from 'react';
import { CustomIconProps, setColor } from '..';

const MoreIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fill={iconColor}
        opacity="0.5"
        d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      />
    </svg>
  );
};

export default MoreIcon;
