import { styled } from '@mui/material';

export const classes = {
  image: 'image',
  information: 'information',
};

const InformationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 0',
  alignItems: 'center',
  maxWidth: 1050,
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.image}`]: {
    objectFit: 'contain',
    borderRadius: 20,
    width: '100%',
    maxWidth: 444,
    maxHeight: 444,
  },
  [`& .${classes.information}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));
export default InformationContainer;
