import { FC, useEffect, useState } from 'react';
import { useAccount, useContractRead, useContractReads, useNetwork } from "wagmi";
import { BigNumber } from 'ethers';
import { DEV_TEST_ADDRESS, FILLER_ADDRESS } from 'constants/Statics';
import GetLogger from '../../components/global/Logger';
import { ABIs, Addresses } from "../../constants/Addresses";
import { CORE_CHAIN } from '../../constants/Statics';
import { useWagmi } from "../../hooks/wagmi";
import { GetFarmContract, GetLpTokenContract } from '../../services/CreateContract';
import { FindWithdrawFeeDetailed, GetContractName, GetTokenDiv } from '../../utils/Contracts';
import { selectGlobalCosmicPrice, selectGlobalMagicPrice, selectGlobalPrices } from '../global/hooks';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectWalletTokenBalances } from '../wallet/hooks';
import { selectFarmMultiplier, selectFarmRewardPerSecond, selectFarmPools } from './hooks';
import { setFarmMultiplier, setFarmPools, setFarmRewardPerSecond } from './reducer';
import Chains, { networkProviders } from '../../constants/wagmi/chains';

const FarmUpdater: FC = (): null => {
  const { address } = useWagmi();
  const provider = networkProviders[Chains.avalanche.id];
  const wallet = DEV_TEST_ADDRESS || address || FILLER_ADDRESS;
  const log = GetLogger('Farm Updater');
  const dispatch = useAppDispatch();
  const prices = useAppSelector(selectGlobalPrices);
  const walletBalances = useAppSelector(selectWalletTokenBalances);
  const pools = useAppSelector(selectFarmPools);
  const magicPrice = useAppSelector(selectGlobalMagicPrice);
  const cosmicPrice = useAppSelector(selectGlobalCosmicPrice);
  const farmMultiplier = useAppSelector(selectFarmMultiplier);
  const farmRewardPerSecond = useAppSelector(selectFarmRewardPerSecond);
  const [poolIds, setPoolIds] = useState<number[]>(pools.map(p => p.pid));

  const now = BigNumber.from(Number((Date.now() / 1000).toFixed(0)));
  const farmContract = {
    address: Addresses[Chains.avalanche.id].NPCs.Farm,
    abi: ABIs.NPCs.Farm,
    chainId: Chains.avalanche.id,
  }
  useContractReads({
    contracts: [
      { ...farmContract, functionName: 'poolLength' },
      { ...farmContract, functionName: 'getMultiplier', args: [now.sub(1), now] },
      { ...farmContract, functionName: 'REWARD_PER_SECOND' }
    ],
    cacheTime: 600_000,
    enabled: poolIds.length === 0,
    onSuccess: ([poolLength, multiplier, rewardPerSecond]) => {
      const pIds = Array.from(Array(poolLength.toNumber()).keys());
      if (pIds !== poolIds) {
        setPoolIds(pIds);
      }
      if (!multiplier.eq(farmMultiplier)) {
        dispatch(setFarmMultiplier(multiplier));
      }
      if (!farmRewardPerSecond.eq(rewardPerSecond)) {
        dispatch(setFarmRewardPerSecond(rewardPerSecond));
      }
    },
    onError: (err) => {
      log.error("Error getting Initial Investor info::", err)
    }
  })

  useEffect(() => {
    if (!wallet) {
      return;
    }
    const fakePrice = {
      price: BigNumber.from(0),
    };

    const getPoolData = async () => {
      const investor = GetFarmContract();
      const poolData = await Promise.all(
        poolIds.map(async i => {
          const [pInfo] = await provider.all([investor.poolInfo(i)]);
          const allocPoint = pInfo.allocPoint;
          const lpContract = GetLpTokenContract(pInfo.lpToken);
          const [
            userInfo,
            emissionRate,
            totalSupply,
            contBalance,
            timeDelta,
            remainBalance,
            pendingRewards,
            depFee,
            token0,
            token1,
            reserves,
          ] = await provider.all([
            investor.userInfo(i, address),
            investor.getNewRewardPerSecond(i),
            lpContract.totalSupply(),
            lpContract.balanceOf(investor.address),
            investor.userDelta(i),
            lpContract.balanceOf(address),
            investor.pendingReward(i, address),
            investor.USER_DEP_FEE(),
            lpContract.token0(),
            lpContract.token1(),
            lpContract.getReserves(),
          ]);
          const name = GetContractName(lpContract.address, CORE_CHAIN)?.replace('MAGIC', 'MAGICK');
          const token0Name = GetContractName(token0, CORE_CHAIN);
          const token1Name = GetContractName(token1, CORE_CHAIN);
          const token0ReservesValue =
            (reserves._reserve0 / GetTokenDiv(token0Name)) * (prices[token0Name] || fakePrice).price;
          const token1ReservesValue =
            (reserves._reserve1 / GetTokenDiv(token1Name)) * (prices[token1Name] || fakePrice).price;
          const totalReservesValue = token0ReservesValue + token1ReservesValue;
          const currentPrice = totalReservesValue / (totalSupply / 1e18);
          const liquidity = (prices[name].liquidity / totalSupply) * contBalance;
          const balance = userInfo.amount;
          const lockedRatio = walletBalances.MAGIC?.total?.gt(0)
            ? walletBalances.MAGIC.locked.div(walletBalances.MAGIC.total).mul(100)
            : BigNumber.from(0);
          const lastTime = address
            ? userInfo.lastWithdrawTime > userInfo.firstDepositTime
              ? userInfo.lastWithdrawTime
              : userInfo.firstDepositTime
            : 0;
          const [withdrawFee, nextWithdrawFee, nextSecondsRemaining] = FindWithdrawFeeDetailed(timeDelta);

          const lockedRewards = BigNumber.from(0);
          console.log(prices,liquidity, (
            (
              (
                emissionRate / 1e18
              ) * 86400 * 365 * prices.MAGIC.price
            ) / liquidity
          ) * 100)
          return {
            name,
            cleanName: name.replace('_', ' / '),
            earned: pendingRewards,
            apr: (((emissionRate / 1e18) * 86400 * 365 * prices.MAGIC.price) / liquidity) * 100,
            liquidity,
            emissionRate,
            lockPercentage: BigNumber.from(0),
            level: allocPoint,
            totalBalance: contBalance,
            totalBalanceUsd: (contBalance / 1e18) * currentPrice,
            balance,
            balanceUsd: (balance / 1e18) * currentPrice,
            remainBalance,
            remainBalanceUsd: (remainBalance / 1e18) * currentPrice,
            token0,
            token1,
            locked: lockedRewards,
            lockedUsd: 0,
            unlocked: pendingRewards.sub(lockedRewards),
            unlockedUsd: (pendingRewards.sub(lockedRewards) / 1e18) * prices.MAGIC.price,
            lockedRatio,
            unlockedRatio: lockedRatio.gt(0)
              ? walletBalances.EVO.total.sub(walletBalances.MAGIC.locked).div(walletBalances.MAGIC.total).mul(100)
              : BigNumber.from(100),
            token: pInfo.lpToken,
            lastTime,
            depFee,
            withdrawFee,
            nextWithdrawFee,
            nextSecondsRemaining,
            value: currentPrice,
            pid: i,
          };
        }),
      );

      dispatch(setFarmPools(poolData));
    };

    getPoolData().catch(e => log.error('Error getting pool data', e));
    const interval = setInterval(() => {
      getPoolData().catch(e => log.error('Error getting pool data', e));
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [magicPrice, cosmicPrice, wallet, provider, poolIds]);

  return null;
};

export default FarmUpdater;
