import React from 'react';
import { CustomIconProps, setColor } from '..';

const LightThemeIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="light" transform="translate(-172 -188)">
        <g id="sun" transform="translate(172 188)">
          <path
            id="Vector"
            d="M11.667,5.833A5.833,5.833,0,1,1,5.833,0,5.833,5.833,0,0,1,11.667,5.833Z"
            transform="translate(4.167 4.167)"
            fill={iconColor}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M9.167,18.3a.808.808,0,0,1-.833-.8v-.067a.833.833,0,0,1,1.667,0A.865.865,0,0,1,9.167,18.3Zm5.95-2.35a.854.854,0,0,1-.592-.242l-.108-.108a.831.831,0,0,1,1.175-1.175l.108.108a.83.83,0,0,1,0,1.175A.82.82,0,0,1,15.117,15.95Zm-11.9,0a.854.854,0,0,1-.592-.242.83.83,0,0,1,0-1.175l.108-.108A.831.831,0,0,1,3.908,15.6l-.108.108A.834.834,0,0,1,3.217,15.95ZM17.5,10h-.067a.833.833,0,0,1,0-1.667.865.865,0,0,1,.867.833A.808.808,0,0,1,17.5,10ZM.9,10H.833a.833.833,0,0,1,0-1.667.865.865,0,0,1,.867.833A.808.808,0,0,1,.9,10ZM15.008,4.158a.854.854,0,0,1-.592-.242.83.83,0,0,1,0-1.175l.108-.108A.831.831,0,0,1,15.7,3.808l-.108.108A.82.82,0,0,1,15.008,4.158Zm-11.683,0a.854.854,0,0,1-.592-.242L2.625,3.8A.831.831,0,0,1,3.8,2.625l.108.108a.83.83,0,0,1,0,1.175A.809.809,0,0,1,3.325,4.158ZM9.167,1.7A.808.808,0,0,1,8.333.9V.833A.833.833,0,0,1,10,.833.865.865,0,0,1,9.167,1.7Z"
            transform="translate(0.833 0.833)"
            fill={iconColor}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H20V20H0Z"
            transform="translate(20 20) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
export default LightThemeIcon;
