import { GetProfessionId, INFTProfessions, NFTProfession } from "models/NFT";

export const options = (professions: INFTProfessions): number[] => {
  const allOptions = Array.from(Array(12).keys());
  if (!professions) {
    return [];
  }

  const levels = Object.values(professions).filter(n => n > 0);

  switch (levels.length) {
    case 0: {
      return allOptions;
    }
    case 1: {
      const profData = Object.entries(professions).find(p => p[1] === levels[0]);
      if (!profData) {
        return [];
      }
      const id = GetProfessionId(profData[0] as NFTProfession);
      if (levels[0] !== 20) {
        return id !== null ? [id] : [];
      }
      return allOptions.filter(n => n !== id);
    }
    case 2: {
      const unfinished = levels.filter(n => n !== 20);
      if (unfinished.length == 0) {
        return [];
      }
      const remaining = Object.entries(professions).find(p => p[1] === unfinished[0]);
      if (!remaining) {
        return [];
      }
      const rId = GetProfessionId(remaining[0] as NFTProfession);
      return rId !== null ? [rId] : [];
    }
    default: {
      return [];
    }
  }
};
