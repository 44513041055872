import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import useTitle from 'hooks/useTitle';
import PageContainer from 'pages/bridge/styled/PageContainer';
import LMagicCard from './LMagicCard';
import XMagicCard from './XMagicCard';

interface IProps {}

const BankPage: React.FunctionComponent<IProps> = () => {
  useTitle({ title: 'Bank' });
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <PageContainer>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'stretch',
          display: 'flex',
          marginTop: 0,
          marginBottom: 3,
          width: matches ? 0.8 : 1,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            Cosmic Universe Bank
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <XMagicCard />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LMagicCard />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default BankPage;
