import React, { FC } from "react";
import { useAccount, useSigner } from 'wagmi';
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Grid,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material';
import ButtonBorder from 'assets/images/button-border.svg';
import Divider from 'assets/images/divider.svg';
import ElfMale from 'assets/images/nfts/elf-4852-shadow.png';
import ElfFemale from 'assets/images/nfts/elf-85-shadow.png';
import { useAppDispatch } from 'store/hooks';
import { setMintElvesDetails } from 'store/mint/reducer';
import { Mint } from 'services/Mint';
import Div from 'components/global/div';
import CMPImage from 'components/global/image';

const StyledMintBtnContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: '14px 32px 21px 32px',
}));

const StyledMintBtn = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: 0,
  backgroundColor: '#000',
  color: theme.palette.golden,
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: 1,
  '&:disabled': {
    backgroundColor: '#00000020',
  },
  '&:hover': {
    backgroundColor: '#15120d',
  },
}));

interface Props {
  tokenIds: number[]
  ready: boolean
  refetch: () => void;
}
const ElvesMintPending: FC<Props> = ({ tokenIds, ready, refetch }) => {
  const { data } = useSigner();
  const theme = useTheme();
  const hiddenSm = useMediaQuery(theme.breakpoints.down('sm'));

  const mint = async () => {
    if (!data) {
      return;
    }
    const success = await Mint(tokenIds.length, data);
    if (success) {
      refetch();
    }
  };
  console.log(tokenIds)
  return (
    <Card
      sx={{
        borderRadius: '16px',
        background: theme.palette.mintBg,
        height: '100%',
      }}
    >
      <CardContent sx={{ padding: 4.5, height: '100%' }}>
        <Grid container spacing={8} sx={{ alignItems: 'center', height: '100%' }}>
          <Grid item xs={12} md={3} hidden={hiddenSm}>
            <CMPImage
              asset={{
                src: ElfMale,
                alt: 'male',
              }}
              width="100%"
              height="100%"
              style={{ maxWidth: 260 }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', minHeight: 900 }}>
            <Stack direction="column" spacing={5} sx={{ maxWidth: 400, margin: 'auto' }}>
              <Typography sx={{ fontSize: !hiddenSm ? 28 : 24, fontWeight: 'medium', textAlign: 'left' }}>
                <b>Kaze,</b> the Air Elemental is coalescing elements, combining molecules, channelling The Prism&apos;s
                power...
              </Typography>
              <CMPImage
                asset={{
                  src: Divider,
                  alt: 'divider',
                }}
                width="100%"
              />
              <Div>
                <Typography sx={{ fontSize: !hiddenSm ? 18 : 16, textAlign: 'left', marginBottom: 3 }}>
                  Your{' '}
                  <b>
                    El{tokenIds.length > 1 ? 'ves' : "f's"} ID{tokenIds.length > 1 ? "'s" : ''}
                  </b>{' '}
                  {tokenIds.length > 1 ? 'are' : 'is'}:&nbsp;
                  <b style={{ color: theme.palette.golden }}>
                    {tokenIds.length >= 5
                      ? `#${tokenIds[0]} - #${tokenIds[tokenIds.length - 1]}`
                      : tokenIds.map((item, idx) => `#${item}${idx != tokenIds.length - 1 ? ', ' : ''}`)}
                  </b>
                  <br />
                  <br />
                  Congratulations! You have successfully made your purchase. To merge your Elves into their final form
                  please click on the MINT button below. Due to the true random generation of our Elves it will take a
                  short time for the image to generate, but they will be in your inventory as soon as they are minted.
                </Typography>
                <Box sx={{ position: 'relative', margin: 'auto', width: 240 }}>
                  <CMPImage
                    asset={{
                      src: ButtonBorder,
                      alt: 'button boder',
                    }}
                    width="100%"
                  />
                  <StyledMintBtnContainer>
                    <StyledMintBtn disabled={!ready} onClick={mint}>
                      MINT
                    </StyledMintBtn>
                  </StyledMintBtnContainer>
                </Box>
              </Div>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} hidden={hiddenSm}>
            <CMPImage
              asset={{
                src: ElfFemale,
                alt: 'female',
              }}
              width="100%"
              height="100%"
              style={{ maxWidth: 260 }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ElvesMintPending;
