const reserved = [
  'wizzard blizzard',
  'wizzardblizzard',
  'wizardblizard',
  'wizardblizzard',
  'dirtycajunrice',
  'cool vibrations',
  'rainbowniphair',
  'wickednights',
  'anal',
  'bitch',
  'cock',
  'cunt',
  'dick',
  'dildo',
  'faggot',
  'fuck',
  'nigger',
  'nigga',
  'pussy',
  'shit',
  'whore',
];
export const NameFilter = (name: string) => {
  if (!name) {
    return false;
  }
  const lower = name.toLowerCase();
  for (const word of reserved) {
    if (lower.includes(word)) {
      return false;
    }
  }
  if (lower.startsWith('cu')) {
    return false;
  }
  const regex = new RegExp(/^[a-z\d ]+$/i);
  return regex.test(name);
};
