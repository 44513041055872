import { PaletteMode } from "@mui/material";
import { BigNumber } from 'ethers';
import numeral from 'numeral';
import store from 'store';
import { setColor } from 'theme/variables';
import Strings from 'utils/Strings';
import TraitRarity from 'constants/trait-rarity.json';
import WizardRarity from 'constants/wizard-rarity.json';
import { AJVError } from 'models/@types';
import AppConfig, { IAppConfig } from 'models/env/AppConfig';
import { CharacterGender } from 'models/Filters';
import { showSnackbar } from 'components/global/snackbar';
import { GetRarestElfAttribute } from '../constants/attributes/elves/functions';
import { GenderMap } from '../constants/attributes/wizards/types';
import { INFDetailRarity } from "../models/NFT";
import {setAppTheme} from "../store/app/reducer";

const setAppConfig = ({ theme }: IAppConfig) => {
  new AppConfig({ theme }).writeToLocalStorage();
};

const Utils = {
  getAppTheme: (): PaletteMode => {
    return store.getState().app.appTheme;
  },
  getWizardRarity: (id: number): INFDetailRarity | null => {
    const wizardRarity = WizardRarity.find(wizard => wizard.id === id);
    return wizardRarity ? { rank: wizardRarity.rank, categoryRank: wizardRarity.categoryRank } : null;
  },
  getRarestAttribute: (subCategory: string, attributes: Record<string, string> | null): number => {
    if (!attributes || !['Elves', 'Wizards'].includes(subCategory)) {
      return 0;
    } else {
      if (subCategory === 'Wizards') {
        const gender = GenderMap[Number(attributes.gender || attributes.Gender)] || attributes.gender;
        const traitMap = TraitRarity[gender.toLowerCase() as 'male' | 'female'];
        const rarityArr: number[] = [];
        Object.entries(attributes).map(([k, v]) => {
          // @ts-ignore
          if (k != 'gender') rarityArr.push(traitMap[k][v]);
        });
        const bestRarity = rarityArr.reduce((p, c) => (p <= c ? p : c));
        return bestRarity > 5 ? 0 : 6 - Math.ceil(bestRarity);
      } else {
        const bestRarity = GetRarestElfAttribute(attributes).rarity;
        return bestRarity > 5 ? 0 : 6 - Math.ceil(bestRarity);
      }
    }
  },
  getAttributeRarity: (gender: CharacterGender, key: string, value: string): string | undefined => {
    // @ts-ignore
    const attrRarity = gender === 'Male' ? TraitRarity.male[key] : TraitRarity.female[key];

    return attrRarity ? (attrRarity[value] ? `${attrRarity[value]}%` : undefined) : undefined;
  },
  changeTheme: (theme: PaletteMode): void => {
    if (store.getState().app.appTheme === theme) {
      return;
    }
    store.dispatch(setAppTheme(theme));
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', setColor('background'));
    setAppConfig({ theme });
  },
  emailValidation: (val: string): boolean => {
    if (val) {
      const email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!!val && email.test(val)) {
        return true;
      }
    }
    return false;
  },
  parseAJV: (error: AJVError): string => {
    const field = error.field.substring(1, error.field.length);
    return `${error.errorCode} Error. Field ${field} ${error.message}`;
  },

  minimizeAddress: (address: string): string => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4, address.length)}`;
  },
  copyToClipboard: (code: string): void => {
    navigator.clipboard.writeText(code).then(() => {
      showSnackbar({
        message: Strings.copied,
        severity: 'success',
      });
    });
  },
  addHRC20Decimal: (price: number): BigNumber => {
    return BigNumber.from(price.toString() + '.0');
  },
  removeRC20Decimal: (price: number): number => {
    return Number(price.toFixed(0));
  },
  addComma: (value: number, noPrecision = false): string => {
    if (noPrecision) {
      return numeral(value).format('0,0');
    }
    return numeral(value).format('0,0.00');
  },
};
export default Utils;
