import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { PaymentMethod } from '../../constants/types';
import { MintState } from './types';

const initialState: MintState = {
  ticketsLeft: 0,
  minting: [],
  ready: false,
  elves: {
    ticketsSelected: {
      '5%': 0,
      '10%': 0,
      '15%': 0,
      '20%': 0,
    },
    toMint: 1,
    credits: BigNumber.from(0),
    remaining: 0,
    ready: false,
    pending: [],
    price: BigNumber.from(0),
    refetch: false
  },
};

export const mintSlice = createSlice({
  name: 'mint',
  initialState,
  reducers: {
    setMintTicketsLeft: (state, action: PayloadAction<number>) => {
      state.ticketsLeft = action.payload;
    },
    setMintMinting: (state, action: PayloadAction<number[]>) => {
      state.minting = action.payload;
    },
    setMintReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setMintElvesSelected: (state, action: PayloadAction<{ discount: string; count: number }>) => {
      state.elves.ticketsSelected[action.payload.discount] = action.payload.count;
    },
    setMintElvesToMint: (state, action: PayloadAction<number>) => {
      state.elves.toMint = action.payload;
    },
    setMintElvesDetails: (state, action: PayloadAction<any>) => {
      state.elves = { ...state.elves, ...action.payload };
    },
    setMintElvesRefetch: (state, action: PayloadAction<boolean>) => {
      state.elves.refetch = action.payload;
    },
  },
});

export const {
  setMintTicketsLeft,
  setMintMinting,
  setMintReady,
  setMintElvesSelected,
  setMintElvesToMint,
  setMintElvesDetails,
  setMintElvesRefetch,
} = mintSlice.actions;

export default mintSlice.reducer;
