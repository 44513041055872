import { createTheme, PaletteMode, Theme } from "@mui/material";
import "@fontsource/poppins/400.css";

import { setColor } from './variables';

const Poppins = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
};

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    divider: string;
    inputBg: string;
    gray: string;
    filterBg: string;
    filterCard: string;
    iconBg: string;
    sideMenu: string;
    golden: string;
    mintBg: string;
  }
  interface PaletteOptions {
    divider?: string;
    inputBg?: string;
    gray: string;
    filterBg: string;
    filterCard: string;
    iconBg: string;
    sideMenu: string;
    golden: string;
    mintBg: string;
  }
}

const getTheme = (mode: PaletteMode): Theme => {
  return createTheme({
    direction: 'ltr',
    typography: {
      fontFamily: 'Poppins',
    },
    palette: {
      mode,
      divider: setColor('divider'),
      inputBg: setColor('inputBg'),
      gray: setColor('gray'),
      filterBg: setColor('filterBg'),
      filterCard: setColor('filterCard'),
      iconBg: setColor('iconBg'),
      sideMenu: setColor('sideMenu'),
      golden: setColor('golden'),
      mintBg: setColor('mintBg'),
      background: {
        default: setColor('background'),
      },
      primary: {
        main: '#c4aa63',
        light: '#c4aa63',
        dark: '#c4aa63',
      },
      error: {
        main: '#FF6B6B',
        contrastText: 'white',
      },
      text: {
        secondary: '#c4aa63',
        primary: setColor('textPrimary'),
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          'fontFamily': 'Poppins',
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
          },
          containedPrimary: {
            color: 'white',
            ':disabled': {
              color: 'rgba(0, 0, 0, 0.3)',
              backgroundColor: 'rgba(196, 170, 99, 0.3)',
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variant: 'body2',
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: `2px solid ${setColor('golden')}`,
            boxShadow: 'none',
            backgroundColor: setColor('filterCard'),
          },
        },
      },
    },
  });
};
export default getTheme;
