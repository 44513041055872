import React, { FC, ReactNode } from "react";
import { CssBaseline, ThemeProvider as MuiThemeProvider, useTheme } from "@mui/material";
import getTheme from 'theme';
import {useAppSelector} from "../../store/hooks";

interface Props {
  children: ReactNode;
}
const ThemeProvider: FC<Props> = ({ children }) => {

  const mode = useAppSelector(state => state.app.appTheme);

  const theme = React.useMemo(() => { return getTheme(mode) }, [mode]);
  return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
  );
};
export default ThemeProvider;
