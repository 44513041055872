import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Stack, Tab, Tabs, Typography } from '@mui/material';
import useTitle from 'hooks/useTitle';
import PageContainer from 'pages/bridge/styled/PageContainer';
import SelectProfessionDialog from './components/SelectProfessionDialog';
import StakingTable from './components/StakingTable';
import SummaryCards from './components/SummaryCards';
import {clearFilters} from "../../store/marketplace/reducer";

interface IProp {}

const StakingPage: React.FunctionComponent<IProp> = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const dispatch = useDispatch();

  useTitle({ title: 'Staking' });

  useEffect(() => {
    dispatch(clearFilters(false));
    return () => {
      dispatch(clearFilters(true));
    };
  }, []);

  return (
    <PageContainer>
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Character Staking
        </Typography>
        <SummaryCards />
        {showAlert && <Alert severity="error">{alertMessage}</Alert>}
        <StakingTable
          setShowAlert={(a, m) => {
            setShowAlert(a);
            setAlertMessage(m);
          }}
        />
      </Stack>
      <SelectProfessionDialog />
    </PageContainer>
  );
};

export default StakingPage;
