import React from 'react';
import { Drawer, DrawerProps } from '@mui/material';

interface IProps extends DrawerProps {
  open?: boolean;
  onOpenChange?: (state: boolean) => void;
}

const CMPDrawer: React.FunctionComponent<IProps> = ({ children, onOpenChange, open, ...props }) => {
  const toggleDrawer = (newState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (onOpenChange) {
      onOpenChange(newState);
    }
  };
  return (
    <Drawer
      anchor="left"
      onClose={() => toggleDrawer(false)}
      open={open}
      PaperProps={{
        sx: { background: theme => theme.palette.background.default },
        className: 'small-scrollbar',
      }}
      {...props}
    >
      {children}
    </Drawer>
  );
};
export default CMPDrawer;
