import React from 'react';
import Div from '../div';
import { styles } from './_styles';

interface IProps {
  color: string;
  size?: number;
}

const Square: React.FunctionComponent<IProps> = ({ color, size = 14 }) => {
  return <Div sx={{ ...styles, width: size, height: size, backgroundColor: color }} />;
};

export default Square;
