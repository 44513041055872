import React, { FC } from 'react';
import CMPImage from 'components/global/image';
interface Props {
  wh?: number;
}
const FarmingIcon: FC<Props> = ({ wh }) => {
  return (
    <CMPImage
      asset={{
        src: `${process.env.PUBLIC_URL}/images/professions/Farming.png`,
        alt: 'Farming',
      }}
      width={wh || 64}
      height={wh || 64}
    />
  );
};

export default FarmingIcon;
