import { useEffect } from 'react';

interface UseQueryProps {
  title: string;
}

const useTitle = ({ title }: UseQueryProps): void => {
  useEffect(() => {
    document.title = `${title} | Cosmic Universe Gallery`;
    return () => {
      document.title = 'Cosmic Universe Gallery';
    };
  }, [title]);
};

export default useTitle;
