import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { OldAddresses } from '../../constants/Addresses';
import { NFT } from '../../models/NFT';
import { NFTState } from '../wallet/types';
import {
  StakingState,
  SetNFTsPayload,
  SetTokenBalancePayload,
  TokenBalance,
  TokenState,
  AllUserStakingState,
} from './types';

const newBalance = (): TokenBalance => ({
  timestamp: 0,
  balance: BigNumber.from(0),
  unlocked: BigNumber.from(0),
  locked: BigNumber.from(0),
  total: BigNumber.from(0),
});

const initialState: StakingState = {
  refetch: false,
  allStaking: {},
  staked: {},
  training: {},
  selectProfessionNft: undefined,
};

export const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    setStakingAllStaking: (state, action: PayloadAction<AllUserStakingState>) => {
      state.allStaking = action.payload;
    },
    setStakingStaked: (state, action: PayloadAction<NFTState>) => {
      state.staked = action.payload;
    },
    setStakingRefetch: (state, action: PayloadAction<boolean>) => {
      state.refetch = action.payload;
    },
    setStakingSelectProfessionNft: (state, action: PayloadAction<NFT | undefined>) => {
      state.selectProfessionNft = action.payload;
    },
  },
});

export const { setStakingAllStaking, setStakingStaked, setStakingRefetch, setStakingSelectProfessionNft } =
  stakingSlice.actions;

export default stakingSlice.reducer;
