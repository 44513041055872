import React from 'react';
import { CustomIconProps, setColor } from './index';

const TransferIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg
      version="1.1"
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 800 800"
      enableBackground="new 0 0 800 800"
      xmlSpace="preserve"
    >
      <path fill="none" stroke={iconColor} strokeWidth="38" strokeMiterlimit="10" d="M167.8974304,314.2275696" />
      <path
        fill="none"
        stroke={iconColor}
        strokeWidth="38"
        strokeMiterlimit="10"
        d="M789.1998291,14.9386921L402.7207642,788.3300171 c-1.1570435,2.3154297-4.2481689,1.9927979-4.9946899-0.5213013L292.0579529,431.949707 c-0.1923828-0.6479187-0.5762634-1.2050476-1.0895996-1.5814514L12.3536444,226.0796204 c-1.9902678-1.4593353-1.4594126-4.8451996,0.858428-5.475174l772.94104-210.08078 C788.4716797,9.893507,790.3562622,12.6245203,789.1998291,14.9386921z"
      />
      <path
        fill="none"
        stroke={iconColor}
        strokeWidth="38"
        strokeMiterlimit="10"
        d="M291.7609253,430.9493713L792.2316895,8.8715563 L291.7609253,430.9493713z"
      />
      <line
        fill="none"
        stroke={iconColor}
        strokeWidth="38"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="219.5878448"
        y1="483.5593872"
        x2="14.5878506"
        y2="652.5593872"
      />
      <line
        fill="none"
        stroke={iconColor}
        strokeWidth="38"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="156.3570862"
        y1="415.3993835"
        x2="14.5878506"
        y2="532.272522"
      />
      <line
        fill="none"
        stroke={iconColor}
        strokeWidth="38"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="253.6429138"
        y1="588.3604126"
        x2="114.2269058"
        y2="703.2935791"
      />
    </svg>
  );
};

export default TransferIcon;
