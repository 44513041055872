import React from 'react';
import { CustomIconProps, setColor } from '..';

const TickIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10.414" viewBox="0 0 14.828 10.414">
      <g id="tick" transform="translate(-200.586 -455.586)">
        <path
          id="tick-2"
          data-name="tick"
          d="M0,4,4,8l8-8"
          transform="translate(202 457)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
export default TickIcon;
