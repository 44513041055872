import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import Card from "components/common/card";
import Circle from "components/global/circle";
import MagickIcon from "components/icons/magick-icon";
import LocationIcon from "components/icons/main/LocationIcon";
import Square from "../../../../components/global/square";
import { INFTProfessions } from "../../../../models/NFT";
import { NFTState } from "../../../../store/wallet/types";
import { classes } from "../../_styles";
import ActionButton from "./ActionButton";

interface INFTGridViewProps {
  items: NFTState;
  centerAlign?: boolean;
  onRefresh?: () => void;
}

const NFTGridView: React.FunctionComponent<INFTGridViewProps> = ({
  items,
  centerAlign,
  onRefresh,
}) => {
  const BuildProfessionsArray = (professions: INFTProfessions) => {
    const pList = Object.entries(professions)
      .filter(([, l]) => l > 0)
      .map(([p, l]) => ({
        name: p,
        level: l,
      }));
    if (pList.length < 2) {
      for (let i = pList.length; i < 2; i++) {
        pList.push({
          name: "unknown",
          level: 0,
        });
      }
    }
    return pList;
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: "center", alignItems: "stretch" }}
    >
      {Object.values(items).map((nft, index) => (
        <Grid
          key={`${nft.contract}-${nft.tokenId}`}
          item
          xs={12}
          sm={6}
          lg={4}
          xl={3}
          className={
            centerAlign ? "" : index % 2 === 0 ? classes.odd : classes.even
          }
          sx={{ minHeight: 1 }}
        >
          <Card
            // @ts-ignore
            component={Link}
            to={`/gallery/${nft.category}/${nft.contract}/${nft.tokenId}`}
            asset={nft.asset}
            title={nft.name}
            name={nft.customName}
            chain={nft.chain}
            rank={
              nft.category === "character"
                ? nft.subCategory === "Wizards"
                  ? nft.rarity?.rank
                  : 0
                : undefined
            }
            largePhoto={nft.type === "ERC1155"}
            body={
              nft.category === "character"
                ? nft.race
                  ? [
                      {
                        icon: <Circle color={nft.race.color} />,
                        title: nft.race.type,
                      },
                      nft.rarityBonus > 0
                        ? {
                            icon: <MagickIcon />,
                            title:
                              nft.rarityBonus > 0
                                ? `+${nft.rarityBonus}%`
                                : "-",
                          }
                        : (undefined as any),
                    ]
                  : undefined
                : nft.coordinate && nft.x
                ? [
                    {
                      icon: <LocationIcon color={nft.region?.color ?? ""} />,
                      title: nft.coordinate || "",
                    },
                    {
                      icon: <Square color={nft.region?.color ?? ""} />,
                      title: nft.region?.text || "",
                    },
                  ]
                : nft.type === "ERC1155"
                ? [
                    {
                      icon: <Circle color="red" />,
                      title: `Owned: ${nft.balance || 0}`,
                    },
                  ]
                : undefined
            }
            description={
              nft.sale
                ? {
                    icon: <MagickIcon />,
                    value: nft.priceStr,
                    price: nft.usdtPrice,
                  }
                : undefined
            }
            professions={
              nft.professions
                ? BuildProfessionsArray(nft.professions)
                : undefined
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default NFTGridView;
