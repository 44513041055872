import * as Types from './types';
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LpTokenPricesState} from "./types";

const initialState: Types.ConstantsStoreState = {
  magicPrice: 0,
  lpTokenPrices: {
    COSMIC_MAGIC: {
      liquidity: 0,
      price: 0,
    },
    MAGIC_USDC: {
      liquidity: 0,
      price: 0,
    },
    COSMIC_USDC: {
      liquidity: 0,
      price: 0,
    },
  },
};

export const constantsSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {
    setMagicPrice: (state, action: PayloadAction<number>) => {
      state.magicPrice = action.payload;
    },
    setLPTokenPrices: (state, action: PayloadAction<LpTokenPricesState>) => {
      state.lpTokenPrices = action.payload;
    }
  }
});

export const { setMagicPrice, setLPTokenPrices } = constantsSlice.actions;

export default constantsSlice.reducer;

