import React from 'react';
import { SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import Div from '../div';

interface IProp {
  icon: React.ReactNode;
  value: string;
  hasPrice?: boolean;
  containerSx?: SxProps<Theme>;
  containerClassName?: string;
  valueProps?: TypographyProps;
  priceProps?: TypographyProps;
  price?: string;
}

const Description: React.FunctionComponent<IProp> = ({
  value,
  icon,
  hasPrice = false,
  containerSx,
  containerClassName,
  valueProps,
  priceProps,
  price = '0',
}) => {
  return (
    <Div className={`d-flex align-items-center ${containerClassName}`} sx={containerSx}>
      {icon}
      <Typography {...valueProps}>{value}</Typography>
      {hasPrice ? (
        <Typography color="text.secondary" {...priceProps}>
          ${price}
        </Typography>
      ) : null}
    </Div>
  );
};

export default Description;
