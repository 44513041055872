import React, { useEffect, useState } from "react";
import { useAccount } from 'wagmi';
import { useParams } from 'react-router-dom';
import { INFTPageParams } from 'routes/params';
import useTitle from 'hooks/useTitle';
import { DEV_TEST_ADDRESS } from 'constants/Statics';
import BackButton from 'components/common/back-button';
import FetchWrapper from 'components/layouts/fetch-wrapper';
import { GetTokenNameFromAddress } from '../../constants/Addresses';
import { NFT } from "../../models/NFT";
import { selectGlobalNFTs } from '../../store/global/hooks';
import { useAppSelector } from '../../store/hooks';
import { selectMarketNfts } from '../../store/market/hooks';
import { selectStakingStaked } from '../../store/staking/hooks';
import { selectWalletItems1155, selectWalletItems721, selectWalletNFTs } from "../../store/wallet/hooks";
import Information from './components/informations';
import NftAttributes from './components/nft-body/attributes';
import NftProfessions from './components/nft-body/professions';
import SaleHistory from './components/sale-history';

interface IProp {}

const NftPage: React.FunctionComponent<IProp> = () => {
  const { tokenId, contract } = useParams<INFTPageParams>();
  const { address } = useAccount();
  const walletNfts = useAppSelector(selectWalletNFTs);
  const walletItems = useAppSelector(selectWalletItems1155);
  const walletItems721 = useAppSelector(selectWalletItems721);
  const nfts = useAppSelector(selectGlobalNFTs);
  const staked = useAppSelector(selectStakingStaked);
  const forSale = useAppSelector(selectMarketNfts);

  const [nft, setNft] = useState<NFT>()
  useEffect(() => {
    const v = {
      ...nfts[GetTokenNameFromAddress(contract)],
      ...walletNfts[GetTokenNameFromAddress(contract)],
      ...walletItems[GetTokenNameFromAddress(contract)],
      ...walletItems721[GetTokenNameFromAddress(contract)],
      ...staked,
    }[`${contract}-${tokenId}`]
    if (v && v?.tokenId !== nft?.tokenId) {
      setNft(v);
    }
  }, [walletNfts, walletItems, walletItems721, nfts, staked])


  useTitle({ title: nft?.name || '' });


  return (
    <FetchWrapper loading={!nft}>
      <BackButton />
      <Information data={nft} />
      {nft?.professions && <NftProfessions professions={nft.professions as any} />}
      {/* {(nft()?.owner === wallet || process.env.NODE_ENV === 'development') && nft()?.subCategory === 'wizard' && (
        <NftStaking nft={nft()} />
      )} */}
      <NftAttributes subCategory={nft?.subCategory || ''} attributes={nft?.attributes as any} />
      {/* @ts-ignore */}
    </FetchWrapper>
  );
};

export default NftPage;
