import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Strings from "utils/Strings";
import { prettyNFTProfessions, NFTProfession } from "models/NFT";
import FilterCard from "components/common/filter-card";
import FilterContainer from "components/common/filter-container";
import Circle from "components/global/circle";
import { useAppSelector } from "../../../../../store/hooks";
import { setProfessions } from "../../../../../store/marketplace/reducer";

const Professions: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { professions } = useAppSelector((state) => state.marketplace);

  const onSelectHandler = (key: string) => {
    const newProfession = [] as NFTProfession[];
    newProfession.push(key as NFTProfession);
    dispatch(setProfessions(newProfession));
  };

  return (
    <FilterContainer
      className="mt-3"
      title={Strings.professions}
      count={professions.length > 0 ? professions.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
          {Object.entries(prettyNFTProfessions).map(([key, value]) => {
            return (
              <FilterCard
                key={key}
                // icon={<Circle color={race.color} />}
                text={value}
                onClick={() => onSelectHandler(key)}
                active={key === professions[0]}
                // disabled={race.disabled}
              />
            );
          })}
        </div>
      }
    />
  );
};
export default Professions;
