import React, { useState } from 'react';
import { BigNumber } from 'ethers';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { usePopover } from "../../components/common/Popover";
import CMPButton from '../../components/global/button';
import { ClaimLMagic } from '../../services/Bank';
import { useAppSelector } from '../../store/hooks';
import { newBalance } from "../../store/wallet/reducer";
import { CleanLocaleBN } from '../farms/functions';
import { useSigner } from 'wagmi';

const StyledCard = styled(Card)({
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '320px',
  height: `100%`,
});

const LMagicCard = () => {
  const { data } = useSigner();
  const lMagic = useAppSelector(state => state.wallet.tokens.lMAGIC || newBalance());

  const {
    popover: TLPopover,
    handleOpen: handleTLPOpen,
    handleClose: handleTLPClose
  } = usePopover({
    title: 'Total Unclaimed',
    text: `Total Unclaimed MAGICK: ${CleanLocaleBN(lMagic.total)}`}
  )
  const nowSeconds = Math.round(Date.now() / 1000);

  const GENESIS_TIMESTAMP = 1673438400;
  const ONE_YEAR = 86400 * 365;
  const claimed = lMagic.locked.sub(lMagic.balance);
  const pendingPerSecond = lMagic.locked.div(ONE_YEAR);
  const handleClaim = async () => {
    if (!data) {
      return;
    }
    await ClaimLMagic(data);
  };

  return (
    <StyledCard>
      <CardHeader title="lMagic" sx={{ textAlign: 'center', paddingBottom: 0 }} />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow onMouseEnter={handleTLPOpen} onMouseLeave={handleTLPClose}>
              <TableCell>Days Elapsed</TableCell>
              <TableCell align="right">365 / 365</TableCell>
            </TableRow>
            {TLPopover}
            <TableRow>
              <TableCell>Balance</TableCell>
              <TableCell align="right">{CleanLocaleBN(lMagic.balance)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rate</TableCell>
              <TableCell align="right">Fully Unlocked</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Claimed</TableCell>
              <TableCell align="right">{CleanLocaleBN(claimed)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Claimable</TableCell>
              <TableCell align="right">{CleanLocaleBN(lMagic.balance)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <CMPButton fullWidth onClick={handleClaim} disabled={lMagic.balance.eq(0)}>
          Claim
        </CMPButton>
      </CardActions>
    </StyledCard>
  );
};

export default LMagicCard;
