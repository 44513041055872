import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import DialogModal from 'components/global/dialog-modal';
import CMPButton from '../../global/button';
import { ISideBarItem } from './data';

interface Props {
  destination?: Omit<ISideBarItem, 'icon'>;
  setDestination: (destination: Omit<ISideBarItem, 'icon'> | undefined) => void;
}

const LeavingPopup: FC<Props> = ({ destination, setDestination }) => {
  if (!destination) {
    return null;
  }
  return (
    <DialogModal title="Leaving so soon?" open={!!destination} onClose={() => setDestination(undefined)}>
      <DialogContent>
        <Typography variant="h6">
          You are about to leave the Cosmic Marketplace. If this was intentional, click continue. Otherwise, phew...
          that was close!
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <CMPButton sx={{ backgroundColor: 'red' }} onClick={() => setDestination(undefined)}>
          Go Back
        </CMPButton>
        <Button component={'a'} href={destination.path} target="_blank" sx={{ borderRadius: '24px' }}>
          Continue
        </Button>
      </DialogActions>
    </DialogModal>
  );
};

export default LeavingPopup;
