import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Strings from 'utils/Strings';
import { regionItems } from 'constants/filters/Lands';
import { LandsRegion } from 'models/Filters';
import FilterCard from 'components/common/filter-card';
import FilterContainer from 'components/common/filter-container';
import Square from 'components/global/square';
import {useAppSelector} from "../../../../../store/hooks";
import {setRegions} from "../../../../../store/marketplace/reducer";

const Regions: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { regions } = useAppSelector(state => state.marketplace);

  const onSelectHandler = (key: LandsRegion) => {
    const index = regions.findIndex(item => item === key);
    if (index === -1) {
      regions.push(key);
      dispatch(setRegions(regions));
    } else {
      regions.splice(index, 1);
      dispatch(setRegions(regions));
    }
  };

  return (
    <FilterContainer
      className="mt-3"
      title={Strings.regions}
      count={regions.length > 0 ? regions.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
          {regionItems.map(region => (
            <FilterCard
              w100
              key={region.text}
              icon={<Square color={region.color} />}
              text={region.text}
              onClick={() => onSelectHandler(region.text)}
              active={regions.includes(region.text)}
            />
          ))}
        </div>
      }
    />
  );
};
export default Regions;
