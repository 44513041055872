import React from 'react';
import { CustomIconProps, setColor } from '..';

const ListIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="list" transform="translate(-236 -252)">
        <g id="grid-6" transform="translate(236 252)">
          <path
            id="Vector"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
            d="M5.833,16.667h5c4.167,0,5.833-1.667,5.833-5.833v-5C16.667,1.667,15,0,10.833,0h-5C1.667,0,0,1.667,0,5.833v5C0,15,1.667,16.667,5.833,16.667Z"
            transform="translate(1.667 1.667)"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
            d="M0,0V16.667"
            transform="translate(8.333 1.667)"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            className="cls-2"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
            d="M0,0H10"
            transform="translate(8.333 7.083)"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
            d="M0,0H10"
            transform="translate(8.333 12.917)"
          />
          <path id="Vector-5" data-name="Vector" fill="none" opacity="0" d="M0,0H20V20H0Z" />
        </g>
      </g>
    </svg>
  );
};
export default ListIcon;
