import React, { FC, useEffect, useMemo, useState } from "react";
import { DataGrid, GridSelectionModel } from '@mui/x-data-grid';
import { INFTProfessions, NFT, NFTProfession, prettyNFTProfessions } from "models/NFT";
import Chains from "../../../../constants/wagmi/chains";
import { useWagmi } from "../../../../hooks/wagmi";
import { useAppSelector } from '../../../../store/hooks';
import { selectStakingStaked } from '../../../../store/staking/hooks';
import { selectWalletNFTs, selectWalletNftsWizards } from '../../../../store/wallet/hooks';
import { NFTState } from "../../../../store/wallet/types";
import { options } from '../helpers';
import StakingTableColumns from "./columns";
import StakingTableToolbar from './StakingTableToolbar';

interface Props {
  setShowAlert: (showAlert: boolean, message: string) => void;
}

const StakingTable: FC<Props> = ({ setShowAlert }) => {
  const [showCheck, setShowCheck] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(15);
  const [selected, setSelected] = useState<GridSelectionModel>([]);
  const staked = useAppSelector(state => state.staking.staked);
  const walletNfts = useAppSelector(state => state.wallet.nfts);
  const { chain } = useWagmi();
  const [rows, setRows] = useState<any[]>([]);
  useEffect(() => {
    const wiz = Object.entries(walletNfts?.Wizards || {}).reduce((o, [id, nft]) => {
      if (nft.chain?.id === Chains.avalanche.id) {
        o[id] = nft;
      }
      return o;
    }, {} as NFTState);
    for (const id of Object.keys(staked || {})) {
      wiz[id] = staked[id];
    }
    if (Object.values(wiz).length > 0) {
      const r = Object.values(wiz)
        .map(nft => {
          const wizard: { [index: string]: any } = {
            id: nft.tokenId,
            stakingUnlocked: nft.staking?.unlocked,
            stakingStaked: nft.staking?.staked,
            isTraining: !!nft.training,
            trainingLevel: nft.training?.level,
            trainingSkill: nft.training?.skillId,
            trainingStartedAt: nft.training?.startedAt,
            trainingCompleteAt: nft.training?.completeAt,
            levels: 0,
            get NFTProfessions(): INFTProfessions {
              const p: { [index: string]: number } = {};
              for (const profession of Object.keys(prettyNFTProfessions)) {
                p[profession as string] = this[profession];
              }
              return p as unknown as INFTProfessions;
            },
            rawData: nft,
          };
          for (const profession of Object.keys(prettyNFTProfessions)) {
            wizard[profession] = 0;
            if (nft.professions) {
              wizard.levels += nft.professions[profession as NFTProfession];
              wizard[profession] = nft.professions[profession as NFTProfession];
            }
          }
          return wizard;
        })
        .filter((v, i, s) => i === s.findIndex(t => t.id === v.id));
      switch (action) {
        case 'unlock': {
          setRows(r.filter(w => !w.stakingUnlocked));
          return;
        }
        case 'stake': {
          setRows(r.filter(w => !w.stakingStaked && w.stakingUnlocked && w.levels < 40));
          return;
        }
        case 'unstake': {
          setRows(r.filter(w => w.stakingStaked && !w.isTraining));
          return;
        }
        case 'start': {
          setRows(r.filter(w => !w.isTraining && options(w.NFTProfessions).length === 1 && w.stakingStaked));
          return;
        }
        case 'finish': {
          setRows(r.filter(
            w => w.isTraining && Date.parse(String(w.trainingCompleteAt || '99999999999999999')) <= Date.now(),
          ));
          return;
        }
        case 'cancel': {
          setRows(r.filter(
            w => w.isTraining && Date.parse(String(w.trainingCompleteAt || '99999999999999999')) > Date.now(),
          ));
          return;
        }
        default: {
          setRows(r || []);
        }
      }
    }
  }, [walletNfts, staked, action]);

  return (
    <DataGrid
      sx={{ width: 1, backgroundColor: theme => theme.palette.filterBg, border: theme => `2px solid ${theme.palette.golden}` }}
      columns={StakingTableColumns(chain as any)}
      rows={rows}
      density="compact"
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
      onSelectionModelChange={nsm => setSelected(nsm)}
      selectionModel={selected}
      checkboxSelection={showCheck}
      disableSelectionOnClick
      autoHeight
      components={{
        Toolbar: StakingTableToolbar,
      }}
      componentsProps={{
        toolbar: {
          showCheck: showCheck,
          setShowCheck: setShowCheck,
          rows: rows,
          action: action,
          setAction: setAction,
          selected: selected,
          setSelected: setSelected,
          setShowAlert: setShowAlert,
        },
      }}
    />
  );
};

export default StakingTable;
