import React from 'react';
import { CustomIconProps, setColor } from '..';

const MaleIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
      <g id="male" transform="translate(-131.353 -61.352)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M143.3,61.6a.615.615,0,0,0,0,1.231H145.5l-3.147,3.147a6.632,6.632,0,1,0,.866.872l3.154-3.154V65.91a.615.615,0,0,0,1.231,0V62.217h0a.615.615,0,0,0-.615-.615Zm-5.128,4.1a5.333,5.333,0,1,1-5.333,5.333A5.324,5.324,0,0,1,138.167,65.7Z"
          transform="translate(0 0)"
          fill={iconColor}
          stroke={iconColor}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
export default MaleIcon;
