import { BigNumber, constants } from "ethers";
import React, { FC, useState } from "react";
import { Address, Chain, useContractReads } from "wagmi";
import { TokenURIsToNFTs } from 'utils/Contracts';
import GetLogger from '../../../components/global/Logger';
import { ABIs, Addresses } from "../../../constants/Addresses";
import { useWagmi } from "../../../hooks/wagmi";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectWalletNFTs } from '../hooks';
import { setWalletItems721, setWalletNfts } from "../reducer";

const WalletItem721Updater: FC = (): null => {
  const { address, chain } = useWagmi();
  const wallet = address;
  const log = GetLogger('Wallet NFT Updater');
  const nfts = useAppSelector(selectWalletNFTs);
  const dispatch = useAppDispatch();
  const [tokensOf, setTokensOf] = useState<{ [address: string]: BigNumber[] }>({})

  useContractReads({
    contracts: Object.values(Addresses[chain.id].Items721).map(_address => ({
      address: _address,
      abi: ABIs.NFTs.Elves,
      functionName: 'tokensOfOwner',
      args: [wallet]
    })),
    cacheTime: 60_000,
    enabled: wallet !== constants.AddressZero,
    onSuccess: (data) => {
      const _tokensOf: { [address: string]: BigNumber[] } = {};
      Object.values(Addresses[chain.id].Items721).map((_address, i) => {
        if (data[i].length) {
          _tokensOf[_address] = data[i] as BigNumber[];
        }
      })
      setTokensOf(_tokensOf);
    }
  })

  useContractReads({
    contracts: Object.values(Addresses[chain.id].Items721).filter((a) => a in tokensOf).map(_address => ({
      address: _address,
      abi: ABIs.NFTs.Elves,
      functionName: 'batchTokenURI',
      args: [tokensOf[_address]]
    })),
    cacheTime: 600_000,
    enabled: wallet !== constants.AddressZero && Object.keys(tokensOf).length > 0,
    onSuccess: (data) => {
      const allNewNfts: any = {};
      Object.entries(Addresses[chain.id].Items721).filter(([n, a]) => a in tokensOf).map(([name, _address], i) => {
        const newNfts = TokenURIsToNFTs(data[i] as string[], name, wallet, 'ERC721', undefined, _address as Address, chain as Chain);
        if (!(Object.keys(nfts[name] || {}).length === Object.keys(newNfts).length)) {
          allNewNfts[name] = newNfts;
        }
      });
      if (Object.keys(allNewNfts).length > 0) {
        dispatch(setWalletItems721(allNewNfts));
      }
    }
  })

  return null;
};

export default WalletItem721Updater;
