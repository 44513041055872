import { AppState } from '../index';

export const selectFarm = (state: AppState) => state.farm;
export const selectFarmPools = (state: AppState) => state.farm.pools;
export const selectFarmPool = (state: AppState, poolId: number) => state.farm.pools[poolId];
export const selectFarmPoolByAddress = (state: AppState, address: string) =>
  state.farm.pools.find(p => p.token === address);
export const selectFarmEmissionRate = (state: AppState) => state.farm.rewardPerSecond.mul(state.farm.multiplier);
export const selectFarmRewardPerSecond = (state: AppState) => state.farm.rewardPerSecond;
export const selectFarmMultiplier = (state: AppState) => state.farm.multiplier;
