import React from 'react';
import { Grid, Typography } from '@mui/material';
import Strings from 'utils/Strings';
import { NftSectionContainer } from 'pages/nft/_styles';
import { NFTProfessions } from '../../../../../models/NFT';
import NftProfession from './profession';

interface IProps {
  professions: NFTProfessions;
}

const NftProfessions: React.FunctionComponent<IProps> = ({ professions }) => {
  if (!professions) {
    return null;
  }
  return (
    <NftSectionContainer>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>
        {Strings.professions}
      </Typography>
      <Grid container className="d-flex flex-wrap" spacing={2} sx={{ width: '100%' }}>
        {Object.entries(professions).map(([profession, level]) => (
          <Grid
            item
            key={`${profession}`}
            xs={4}
            sm={3}
            lg={2}
            className="d-flex justify-content-center align-items-center"
          >
            <NftProfession profession={profession} level={level} />
          </Grid>
        ))}
      </Grid>
    </NftSectionContainer>
  );
};

export default NftProfessions;
