import React from 'react';
import { IconButton } from '@mui/material';
import Utils from 'libs/Utils';
import DarkThemeIcon from 'components/icons/header/DarkThemeIcon';
import LightThemeIcon from 'components/icons/header/LightThemeIcon';
import {useAppSelector} from "../../../../store/hooks";

const ChangeTheme: React.FunctionComponent = () => {
  const appTheme = useAppSelector(state => state.app.appTheme);

  const onChangeTheme = () => {
    Utils.changeTheme(appTheme === 'light' ? 'dark' : 'light');
  };

  return (
    <IconButton sx={{ border: theme => `1px solid ${theme.palette.divider}` }} onClick={onChangeTheme}>
      {appTheme === 'light' ? <DarkThemeIcon /> : <LightThemeIcon />}
    </IconButton>
  );
};
export default ChangeTheme;
