import React, { CSSProperties, SyntheticEvent } from 'react';
import { IAsset } from 'models/@types';
import { useAppSelector } from '../../../store/hooks';
import { selectMarketAutoPlay } from '../../../store/market/hooks';

interface IProps {
  asset: IAsset;
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  gender?: string;
}

const CMPImage: React.FunctionComponent<IProps> = ({ asset, width, height, style, className, onClick, gender }) => {
  const onElvesError = (event: SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    console.log('ERR');
    if (img.src.includes('/elves/')) {
      img.src = `https://images.cosmicuniverse.io/elves/${gender?.toString()}`;
    }
  };

  return (
    <img
      src={asset.src}
      alt={asset.alt}
      width={width}
      height={height}
      className={className}
      style={style}
      onClick={onClick}
      onError={onElvesError}
    />
  );
};
export default CMPImage;
