const ColorPalette = {
  primary: '#ffd58c',
  primaryBackground: 'rgba(11, 180, 231, 0.1)',
  iconLight: '#140034',
  iconDark: '#E7E5EA',
  white: '#FFFFFF',
  textWhite: '#E7E5EA',
  textDark: '#140034',
  activeBg: '#ffd58c',
  active: '#000000',
  purple: '#611CAF',
  iconBg: `rgba(231, 229, 234, 0.5)`,
  error: '#FF6B6B',
};
export default ColorPalette;
