import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { Pool, FarmState } from './types';

const initialState: FarmState = {
  pools: [],
  rewardPerSecond: BigNumber.from(0),
  multiplier: BigNumber.from(0),
};

export const farmSlice = createSlice({
  name: 'stake',
  initialState,
  reducers: {
    setFarmPools: (state, action: PayloadAction<Pool[]>) => {
      state.pools = action.payload;
    },
    setFarmRewardPerSecond: (state, action: PayloadAction<BigNumber>) => {
      if (!state.rewardPerSecond.eq(action.payload)) {
        state.rewardPerSecond = action.payload;
      }
    },
    setFarmMultiplier: (state, action: PayloadAction<BigNumber>) => {
      if (!state.multiplier.eq(action.payload)) {
        state.multiplier = action.payload;
      }
    },
  },
});

export const { setFarmPools, setFarmRewardPerSecond, setFarmMultiplier } = farmSlice.actions;

export default farmSlice.reducer;
