import React from 'react';
import { Box, Chip, SxProps, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import TraitRarity from 'constants/trait-rarity.json';
import { AttributeType } from 'models/@types';
import { CharacterGender } from 'models/Filters';
import Div from 'components/global/div';
import AlchemyIcon from 'components/icons/professions/AlchemyIcon';
import ArchitectureIcon from 'components/icons/professions/ArchitectureIcon';
import CarpentryIcon from 'components/icons/professions/CarpentryIcon';
import CookingIcon from 'components/icons/professions/CookingIcon';
import CrystalExtractionIcon from 'components/icons/professions/CrystalExtractionIcon';
import FarmingIcon from 'components/icons/professions/FarmingIcon';
import FishingIcon from 'components/icons/professions/FishingIcon';
import GemCuttingIcon from 'components/icons/professions/GemCuttingIcon';
import HerbalismIcon from 'components/icons/professions/HerbalismIcon';
import MiningIcon from 'components/icons/professions/MiningIcon';
import TailoringIcon from 'components/icons/professions/TailoringIcon';
import UnknownIcon from 'components/icons/professions/Unknown';
import WoodcuttingIcon from 'components/icons/professions/WoodcuttingIcon';
import { stringToCapitalise } from '../../../../../../utils/Strings';

export const GetProfessionIcon = (p?: string, wh?: number) => {
  switch (p) {
    case 'alchemy':
      return <AlchemyIcon wh={wh} />;
    case 'architecture':
      return <ArchitectureIcon wh={wh} />;
    case 'carpentry':
      return <CarpentryIcon wh={wh} />;
    case 'cooking':
      return <CookingIcon wh={wh} />;
    case 'crystalExtraction':
      return <CrystalExtractionIcon wh={wh} />;
    case 'farming':
      return <FarmingIcon wh={wh} />;
    case 'fishing':
      return <FishingIcon wh={wh} />;
    case 'gemCutting':
      return <GemCuttingIcon wh={wh} />;
    case 'herbalism':
      return <HerbalismIcon wh={wh} />;
    case 'mining':
      return <MiningIcon wh={wh} />;
    case 'tailoring':
      return <TailoringIcon wh={wh} />;
    case 'woodcutting':
      return <WoodcuttingIcon wh={wh} />;
    default:
      return <UnknownIcon wh={wh} />;
  }
};

interface IProps {
  rootSx?: SxProps<Theme>;
  profession?: string;
  level?: number;
}

const NftProfession: React.FunctionComponent<IProps> = ({ rootSx, profession, level }) => {
  const theme = useTheme()
  return (
    <Tooltip title={stringToCapitalise(profession || '')}>
      <Box sx={{ ...rootSx, ...(level === 0 ? { opacity: '0.3' } : {}) }} className="d-flex justify-content-center align-items-center">
        <div>{GetProfessionIcon(profession)}</div>
        <Chip
          label={level}
          sx={{
            marginLeft: 1,
            paddingLeft: 1,
            paddingRight: 1,
            justifySelf: 'center',
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            fontWeight: 800,
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default NftProfession;
