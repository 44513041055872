import { FC, useEffect } from 'react';
import GetLogger from '../../../components/global/Logger';
import { OldAddresses } from '../../../constants/Addresses';
import { useAppDispatch } from '../../hooks';
import { useFetchTokenDataQuery } from '../actions';
import { setTokenPrice } from '../reducer';
import { SetTokenPricePayload } from '../types';

const GlobalAvaxUpdater: FC = (): null => {
  const log = GetLogger('AVAX Updater');
  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useFetchTokenDataQuery(OldAddresses.tokens.WAVAX, { pollingInterval: 30000 });

  useEffect(() => {
    if (error) {
      log.error('Error fetching Token Data:', error);
      return;
    }
    if (!isLoading && data) {
      const AVAX: SetTokenPricePayload = {
        token: 'WAVAX',
        details: {
          price: Number(data.priceUsd),
          liquidity: Number(data.liquidity.usd),
        },
      };
      //log.debug('WAVAX Token Price', AVAX);
      dispatch(setTokenPrice(AVAX));
    }
  }, [data]);

  return null;
};

export default GlobalAvaxUpdater;
