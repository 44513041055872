import React, { useEffect, useState } from 'react';
import { useAccount, useSigner } from 'wagmi';
import { Typography } from '@mui/material';
import { NameNFTService } from 'services/RenameNFT';
import { NFT } from 'models/NFT';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import { NameFilter } from '../../../../../utils/NameFilter';
import InputRow from '../InputRow';

interface IProp {
  open: boolean;
  setOpen: (open: boolean) => void;
  nft: NFT;
}

const EditNameModal: React.FunctionComponent<IProp> = ({ open, setOpen, nft }) => {
  const { data } = useSigner();
  const [name, setName] = useState<string>(nft.name);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (name === '') {
      setIsValid(false);
      return;
    }
    setIsValid(NameFilter(name));
  }, [name, isValid]);

  const handleInput = (input: string) => {
    let n = input;
    if (!n) {
      n = '';
    }
    setName(n);
  };

  const handleClose = () => {
    setName('');
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!data) {
      return;
    }
    const sanitized = name.trim().replace(/\s+/g, ' ');
    setName(sanitized);
    const success = await NameNFTService(nft, sanitized, data);
    if (success) {
      handleClose();
    }
  };

  return (
    <DialogModal title="Edit Name" open={open} onClose={handleClose}>
      <Typography sx={{ textAlign: 'center' }}>Cost: 125 MAGICK</Typography>
      <InputRow title="Name" inputValue={name} onInputChange={handleInput} error={name !== '' && !isValid} />
      <CMPButton
        fullWidth
        sx={{ minHeight: 56, marginTop: '2rem' }}
        onClick={handleSubmit}
        disabled={!isValid || nft.staking?.staked === true}
      >
        {nft.staking?.staked ? 'Staked' : 'Set'}
      </CMPButton>
    </DialogModal>
  );
};

export default EditNameModal;
