import React from 'react';
import { CircularProgress } from '@mui/material';
import LoadingContainer from './_styles';
import {useAppSelector} from "../../../store/hooks";

const AppLoading: React.FunctionComponent = () => {
  const loading = useAppSelector(state => state.app.appLoading);

  if (!loading) {
    return null;
  }

  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};
export default AppLoading;
