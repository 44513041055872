import store from 'store';

interface IColorPalletMode {
  light: string;
  dark: string;
}
export interface IColorPalette {
  inputBg: IColorPalletMode;
  divider: IColorPalletMode;
  background: IColorPalletMode;
  textPrimary: IColorPalletMode;
  gray: IColorPalletMode;
  filterBg: IColorPalletMode;
  filterCard: IColorPalletMode;
  iconBg: IColorPalletMode;
  sideMenu: IColorPalletMode;
  golden: IColorPalletMode;
  mintBg: IColorPalletMode;
}

export const ColorPalette: IColorPalette = {
  background: {
    dark: '#202124',
    light: '#FFFFFF',
  },
  divider: {
    dark: 'rgba(255, 255, 255, 0.12)',
    light: 'rgba(0, 0, 0, 0.12)',
  },
  textPrimary: {
    dark: '#E7E5EA',
    light: '#140034',
  },
  inputBg: {
    dark: '#000000',
    light: '#F9F9FA',
  },
  gray: {
    dark: '#707070',
    light: '#cfcfcf',
  },
  filterBg: {
    dark: '#000000',
    light: '#FFFFFF',
  },
  sideMenu: {
    dark: '#000000',
    light: '#F9F9FA',
  },
  filterCard: {
    dark: '#000000',
    light: '#F9F9FA',
  },

  iconBg: {
    dark: 'rgba(231, 229, 234, 0.2)',
    light: 'rgba(231, 229, 234, 0.5)',
  },
  golden: {
    dark: '#ffd58c',
    light: '#ffd58c',
  },
  mintBg: {
    dark: 'rgba(0, 0, 0, 0.8)',
    light: 'rgba(255, 255, 255, 0.8)',
  },
} as const;

export const setColor = (key: keyof IColorPalette): string => {
  const mode = store.getState().app.appTheme;
  return ColorPalette[key][mode];
};
