import React from 'react';
import { SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import Div from '../div';

interface IProp extends TypographyProps {
  containerSx?: SxProps<Theme>;
  containerClassName?: string;
}

const ContractContainer: React.FunctionComponent<IProp> = ({ children, containerSx, containerClassName, ...props }) => {
  return (
    <Div
      className={containerClassName}
      sx={{
        backgroundColor: theme => theme.palette.filterCard,
        borderRadius: 15,
        padding: 1,
        ...containerSx,
      }}
    >
      <Typography color="text.secondary" {...props}>
        {children}
      </Typography>
    </Div>
  );
};

export default ContractContainer;
