import { Button, styled } from '@mui/material';

const Filter = styled(Button)(({ theme }) => ({
  borderRadius: 16,
  backgroundColor: theme.palette.sideMenu,
  width: 'calc(50% - 16px)',
  maxWidth: 132,
  height: 48,
  margin: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0 8px',
  border: 1,
  borderStyle: 'solid',
  [theme.breakpoints.up('lg')]: {
    padding: '0 12px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0 16px',
  },
}));
export default Filter;
