
import { NFTState } from '../store/wallet/types';

export const filterNFTStateForOwned = (n: NFTState, account?: string) => {
  const s: NFTState = {};
  Object.entries(n).map(([id, nft]) => {
    if (nft.owner === account) {
      s[id] = nft;
    }
  });
  return s;
};
