import React from 'react';
import { CustomIconProps, setColor } from '..';

const EditIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28">
      <g id="vuesax_linear_edit" data-name="vuesax/linear/edit" transform="translate(-620 -252)">
        <g id="edit" transform="translate(620 252)">
          <path
            id="Vector"
            d="M10.8,0H8.4C2.4,0,0,2.4,0,8.4v7.2c0,6,2.4,8.4,8.4,8.4h7.2c6,0,8.4-2.4,8.4-8.4V13.2"
            transform="translate(2 2)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z"
            transform="translate(11.049 1.749)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0A7.144,7.144,0,0,0,4.94,4.94"
            transform="translate(18.039 5.021)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
          />
          <path id="Vector-4" data-name="Vector" d="M0,0H28V28H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};
export default EditIcon;
