import React from 'react';
import { CustomIconProps, setColor } from '..';

const CopyIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="vuesax_linear_document-copy" data-name="vuesax/linear/document-copy" transform="translate(-684 -188)">
        <path
          id="Vector"
          d="M10,4.267v2C10,8.933,8.933,10,6.267,10H3.733C1.067,10,0,8.933,0,6.267V3.733C0,1.067,1.067,0,3.733,0h2"
          transform="translate(685.333 192.667)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M4.267,4.267H2.133C.533,4.267,0,3.733,0,2.133V0Z"
          transform="translate(691.067 192.667)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H2.667"
          transform="translate(691.733 189.333)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,2A2,2,0,0,1,2,0H3.747"
          transform="translate(688.667 189.333)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M1.873,0V4.127A1.875,1.875,0,0,1,0,6"
          transform="translate(696.793 193.333)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M4,4H2C.5,4,0,3.5,0,2V0Z"
          transform="translate(694.667 189.333)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-7"
          data-name="Vector"
          d="M0,0H16V16H0Z"
          transform="translate(684 188)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};
export default CopyIcon;
