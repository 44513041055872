export type WizardTrait = 'gender' | 'background' | 'hair' | 'hat' | 'eyes' | 'base' | 'staff' | 'robe' | 'mouth';

export type Gender = 'Male' | 'Female';

export const GenderMap: Gender[] = ['Male', 'Female'];

export type MaleBackground =
  | 'Starry Night'
  | 'Shining Light'
  | 'Double Aurora'
  | 'Starbursts'
  | 'Plasma Ball'
  | 'Sparkling Green'
  | 'Galaxy';

export const MaleBackgroundMap: MaleBackground[] = [
  'Starry Night',
  'Shining Light',
  'Double Aurora',
  'Starbursts',
  'Plasma Ball',
  'Sparkling Green',
  'Galaxy',
];

export type FemaleBackground =
  | 'Bluish Purple'
  | 'Bluish Sparkle'
  | 'Bluish Black Space'
  | 'Dark Green'
  | 'Aqua'
  | 'Dark Blue Rays'
  | 'Magenta Rays'
  | 'Magenta Sparkle'
  | 'Blurred Galaxy'
  | 'Purple Light'
  | 'Diffused Purple'
  | 'Green Glow'
  | 'Glowing Bluish Purple'
  | 'Purple Glow'
  | 'Galactic Rainbow';

export const FemaleBackgroundMap: FemaleBackground[] = [
  'Bluish Purple',
  'Bluish Sparkle',
  'Bluish Black Space',
  'Dark Green',
  'Aqua',
  'Dark Blue Rays',
  'Magenta Rays',
  'Magenta Sparkle',
  'Blurred Galaxy',
  'Purple Light',
  'Diffused Purple',
  'Green Glow',
  'Glowing Bluish Purple',
  'Purple Glow',
  'Galactic Rainbow',
];

export const BackgroundMap = (gender: Gender) => (gender === 'Male' ? MaleBackgroundMap : FemaleBackgroundMap);

export type MaleBase = 'Tan' | 'Brown' | 'Dark Brown' | 'Blue';

export const MaleBaseMap: MaleBase[] = ['Tan', 'Brown', 'Dark Brown', 'Blue'];

export type FemaleBase = 'Tan' | 'Blue';

export const FemaleBaseMap: FemaleBase[] = ['Tan', 'Blue'];

export const BaseMap = (gender: Gender) => (gender === 'Male' ? MaleBaseMap : FemaleBaseMap);

export type MaleRobe =
  | 'Blue and Pink'
  | 'Black and Gold'
  | 'V Style'
  | 'Y Style'
  | 'Fancy Y Style'
  | 'Mushroom Style'
  | 'Fancy Collar'
  | 'Fringed'
  | 'Regal Style';

export const MaleRobeMap: MaleRobe[] = [
  'Blue and Pink',
  'Black and Gold',
  'V Style',
  'Y Style',
  'Fancy Y Style',
  'Mushroom Style',
  'Fancy Collar',
  'Fringed',
  'Regal Style',
];

export type FemaleRobe =
  | 'Purple and Gold'
  | 'Gold Trim'
  | 'Greenish Gold'
  | 'Fancy Eggplant'
  | 'Fancy Reddish Brown'
  | 'Regal Two-Tone'
  | 'Regal'
  | 'Jeweled'
  | 'Festive Regal'
  | 'Collared and Jeweled'
  | 'Light Blue Ambassador'
  | 'Light Pink Ambassador';

export const FemaleRobeMap: FemaleRobe[] = [
  'Purple and Gold',
  'Gold Trim',
  'Greenish Gold',
  'Fancy Eggplant',
  'Fancy Reddish Brown',
  'Regal Two-Tone',
  'Regal',
  'Jeweled',
  'Festive Regal',
  'Collared and Jeweled',
  'Light Blue Ambassador',
  'Light Pink Ambassador',
];

export const RobeMap = (gender: Gender) => (gender === 'Male' ? MaleRobeMap : FemaleRobeMap);

export type MaleEyes =
  | 'Inquisitive Eyes'
  | 'Wise Eyes'
  | 'Green Old Eyes'
  | 'Bagged Eyes'
  | 'Squinting Eyes'
  | 'Weathered Eyes'
  | 'Green Eyes'
  | 'Angry Eyes'
  | 'Concerned Eyes'
  | 'Spectacles'
  | 'Saucy Eyepatch'
  | 'Rainbow Furrows'
  | 'Aviator Shades'
  | 'Five Elements Eyepatch'
  | 'Lennon Shades'
  | 'Star Shades'
  | 'Arcane Glasses'
  | 'Blue Eyebrows'
  | 'Rainbow Star Shades'
  | 'Arcane Rainbow Glasses'
  | 'Aviator Rainbow Shades'
  | 'Lennon Rainbow Shades';

export const MaleEyesMap: MaleEyes[] = [
  'Inquisitive Eyes',
  'Wise Eyes',
  'Green Old Eyes',
  'Bagged Eyes',
  'Squinting Eyes',
  'Weathered Eyes',
  'Green Eyes',
  'Angry Eyes',
  'Concerned Eyes',
  'Spectacles',
  'Saucy Eyepatch',
  'Rainbow Furrows',
  'Aviator Shades',
  'Five Elements Eyepatch',
  'Lennon Shades',
  'Star Shades',
  'Arcane Glasses',
  'Blue Eyebrows',
  'Rainbow Star Shades',
  'Arcane Rainbow Glasses',
  'Aviator Rainbow Shades',
  'Lennon Rainbow Shades',
];

export type FemaleEyes =
  | 'Simple Purple'
  | 'Amazed'
  | 'Blue'
  | 'Dark Blue'
  | 'Purple'
  | 'Angry'
  | 'Reddish Whites'
  | 'Squinting Blue'
  | 'Inquisitive Blue';

export const FemaleEyesMap: FemaleEyes[] = [
  'Simple Purple',
  'Amazed',
  'Blue',
  'Dark Blue',
  'Purple',
  'Angry',
  'Reddish Whites',
  'Squinting Blue',
  'Inquisitive Blue',
];

export const EyesMap = (gender: Gender) => (gender === 'Male' ? MaleEyesMap : FemaleEyesMap);

export type MaleHair =
  | 'Simple'
  | 'Classic'
  | 'Blue Tie'
  | 'Quadruple Braid'
  | 'Wrapped'
  | 'Wavy'
  | 'Wild'
  | 'Triple Braid'
  | 'Long'
  | 'Quadruple Rainbow'
  | 'Wrapped Rainbow'
  | 'Classic Rainbow'
  | 'Wavy Rainbow'
  | 'Reddish Yellow'
  | 'Rainbow Tie'
  | 'Wild Rainbow'
  | 'Triple Green'
  | 'Long Rainbow';

export const MaleHairMap: MaleHair[] = [
  'Simple',
  'Classic',
  'Blue Tie',
  'Quadruple Braid',
  'Wrapped',
  'Wavy',
  'Wild',
  'Triple Braid',
  'Long',
  'Quadruple Rainbow',
  'Wrapped Rainbow',
  'Classic Rainbow',
  'Wavy Rainbow',
  'Reddish Yellow',
  'Rainbow Tie',
  'Wild Rainbow',
  'Triple Green',
  'Long Rainbow',
];

export type FemaleHair =
  | 'Blonde'
  | 'Aqua'
  | 'Medium Brown'
  | 'Purple'
  | 'Braided Golden'
  | 'Medium Purple'
  | 'Short Red'
  | 'Long Bluish'
  | 'Short'
  | 'White'
  | 'Short Rainbow'
  | 'Long White'
  | 'Rainbow'
  | 'Braided Rainbow';

export const FemaleHairMap: FemaleHair[] = [
  'Blonde',
  'Aqua',
  'Medium Brown',
  'Purple',
  'Braided Golden',
  'Medium Purple',
  'Short Red',
  'Long Bluish',
  'Short',
  'White',
  'Short Rainbow',
  'Long White',
  'Rainbow',
  'Braided Rainbow',
];

export const HairMap = (gender: Gender) => (gender === 'Male' ? MaleHairMap : FemaleHairMap);

export type MaleHat =
  | 'Classic'
  | 'Oak Leaf'
  | 'none'
  | 'Formal'
  | 'Regiment'
  | 'Black and Gold'
  | 'Funky'
  | 'Eastern'
  | 'Headband'
  | 'Crow';

export const MaleHatMap: MaleHat[] = [
  'Classic',
  'Oak Leaf',
  'none',
  'Formal',
  'Regiment',
  'Black and Gold',
  'Funky',
  'Eastern',
  'Headband',
  'Crow',
];

export type FemaleHat =
  | 'none'
  | 'Purple Helmet'
  | 'Pink and Ribbon'
  | 'Orange and Ribbon'
  | 'Red Cap'
  | 'Orange Cap'
  | 'Feather and Bow'
  | 'Purplish and Feather'
  | 'Psychedelic Buttons'
  | 'Feathers and Buckle'
  | 'Headband';

export const FemaleHatMap: FemaleHat[] = [
  'none',
  'Purple Helmet',
  'Pink and Ribbon',
  'Orange and Ribbon',
  'Red Cap',
  'Orange Cap',
  'Feather and Bow',
  'Purplish and Feather',
  'Psychedelic Buttons',
  'Feathers and Buckle',
  'Headband',
];

export const HatMap = (gender: Gender) => (gender === 'Male' ? MaleHatMap : FemaleHatMap);

export type MaleMouth =
  | 'Classic'
  | 'Natural'
  | 'Smiling'
  | 'Thick'
  | 'Sage'
  | 'Jolly'
  | 'Wavy'
  | 'Fu Manchu'
  | 'V Style'
  | 'Natural Rainbow'
  | 'Thick Rainbow'
  | 'Smiling Rainbow'
  | 'Wavy Rainbow'
  | 'Rainbow Fu Mancu'
  | 'Rainbow Sage'
  | 'Jolly Rainbow'
  | 'Rainbow V Style'
  | 'Curling Rainbow';

export const MaleMouthMap: MaleMouth[] = [
  'Classic',
  'Natural',
  'Smiling',
  'Thick',
  'Sage',
  'Jolly',
  'Wavy',
  'Fu Manchu',
  'V Style',
  'Natural Rainbow',
  'Thick Rainbow',
  'Smiling Rainbow',
  'Wavy Rainbow',
  'Rainbow Fu Mancu',
  'Rainbow Sage',
  'Jolly Rainbow',
  'Rainbow V Style',
  'Curling Rainbow',
];

export type FemaleMouth =
  | 'Happy'
  | 'Dark Red'
  | 'Light Red'
  | 'Closed'
  | 'Red'
  | 'Reddish'
  | 'Smiling'
  | 'Smiling Pink'
  | 'Open'
  | 'Pink'
  | 'Smiling Rainbow'
  | 'Pursed Rainbow'
  | 'Rainbow';

export const FemaleMouthMap: FemaleMouth[] = [
  'Happy',
  'Dark Red',
  'Light Red',
  'Closed',
  'Red',
  'Reddish',
  'Smiling',
  'Smiling Pink',
  'Open',
  'Pink',
  'Smiling Rainbow',
  'Pursed Rainbow',
  'Rainbow',
];

export const MouthMap = (gender: Gender) => (gender === 'Male' ? MaleMouthMap : FemaleMouthMap);

export type MaleStaff =
  | 'Wooden'
  | 'Travel Staff'
  | 'Amethyst'
  | 'Steel'
  | 'Bulky'
  | 'Golden'
  | 'Mystery Portal'
  | 'Fancy Wood'
  | 'Arcane Gyres'
  | 'Sword'
  | 'Crystal Portal';

export const MaleStaffMap: MaleStaff[] = [
  'Wooden',
  'Travel Staff',
  'Amethyst',
  'Steel',
  'Bulky',
  'Golden',
  'Mystery Portal',
  'Fancy Wood',
  'Arcane Gyres',
  'Sword',
  'Crystal Portal',
];

export type FemaleStaff =
  | 'Amethyst'
  | 'Crystal Ball'
  | 'Reddish Brown'
  | 'Star Crescent'
  | 'Travel-Sized'
  | 'Feather'
  | 'Octopus Shell';

export const FemaleStaffMap: FemaleStaff[] = [
  'Amethyst',
  'Crystal Ball',
  'Reddish Brown',
  'Star Crescent',
  'Travel-Sized',
  'Feather',
  'Octopus Shell',
];

export const StaffMap = (gender: Gender) => (gender === 'Male' ? MaleStaffMap : FemaleStaffMap);

export interface WizardAttributes {
  gender: Gender;
  background: MaleBackground | FemaleBackground;
  hair: MaleHair | FemaleHair;
  hat: MaleHat | FemaleHat;
  eyes: MaleEyes | FemaleEyes;
  base: MaleBase | FemaleBase;
  staff: MaleStaff | FemaleStaff;
  robe: MaleRobe | FemaleRobe;
  mouth: MaleMouth | FemaleMouth;
}
