import React from 'react';
import { CustomIconProps, setColor } from '..';

const DarkThemeIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18">
      <g id="dark" transform="translate(-108 -188)">
        <g id="moon" transform="translate(108 188)">
          <path
            id="Vector"
            d="M14.418,10.447c-.12-.2-.457-.517-1.3-.368a6.345,6.345,0,0,1-1.41.1A6.307,6.307,0,0,1,7.278,8.062,6.051,6.051,0,0,1,6.2,1.567C6.528.81,6.3.412,6.131.247S5.553-.165,4.758.165A7.738,7.738,0,0,0,.018,7.822,7.857,7.857,0,0,0,5.3,14.565a7.5,7.5,0,0,0,2.167.412c.12.008.24.015.36.015a7.876,7.876,0,0,0,6.352-3.2C14.681,11.092,14.546,10.65,14.418,10.447Z"
            transform="translate(1.729 1.5)"
            fill={iconColor}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H18V18H0Z"
            transform="translate(18 18) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
export default DarkThemeIcon;
