import React, { FC, Fragment } from 'react';
import AllStakingDataUpdater from './AllStakingDataUpdater';
import StakedUpdater from './StakedUpdater';

const StakingUpdater: FC = () => {
  return (
    <Fragment>
      <AllStakingDataUpdater />
      <StakedUpdater />
    </Fragment>
  );
};

export default StakingUpdater;
