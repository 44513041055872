import { AppState } from '../index';

export const selectMintTicketsLeft = (state: AppState) => state.mint.ticketsLeft;
export const selectMintMinting = (state: AppState) => state.mint.minting;
export const selectMintReady = (state: AppState) => state.mint.ready;

export const selectMintElves = (state: AppState) => state.mint.elves;
export const selectMintElvesTicketsSelected = (state: AppState) => state.mint.elves.ticketsSelected || {};
export const selectMintElvesToMint = (state: AppState) => state.mint.elves.toMint;
export const selectMintElvesReady = (state: AppState) => state.mint.elves.ready;
export const selectMintElvesPending = (state: AppState) => state.mint.elves.pending;
