import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import ColorPalette from 'utils/ColorPalette';
import usePagination from 'utils/Pagination';
import Div from 'components/global/div';
import FetchWrapper from 'components/layouts/fetch-wrapper';
import { NFTState } from '../../../../store/wallet/types';
import Sort from '../sort';
import ViewSelect from '../view-select';
import NFTGridView from './GridView';
import NFTListView from './ListView';
import {useAppSelector} from "../../../../store/hooks";
import {setPage} from "../../../../store/marketplace/reducer";

interface INFTListProps {
  user?: string;
  showFilters?: boolean;
  centerGrid?: boolean;
  nfts: NFTState;
}

const NFTList: React.FunctionComponent<INFTListProps> = ({ nfts, showFilters = true, centerGrid }) => {
  const dispatch = useDispatch();
  const { category, view, queries, page, sort, forSale, saleTypes } = useAppSelector(state => state.marketplace);
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const [perPage, setPerPage] = useState(10);
  const paginatedNFTs = usePagination(nfts, perPage);

  const changePage = (pageNum: number) => {
    dispatch(setPage(pageNum));
    paginatedNFTs.jump(pageNum);
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    changePage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePaginationSelect = (e: SelectChangeEvent<number>) => {
    changePage(1);
    setPerPage(Number(e.target.value));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    changePage(1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [perPage]);

  useEffect(() => {
    changePage(1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [category]);

  useEffect(() => {
    changePage(page);
  }, [nfts]);

  return (
    <Div
      className="w-100 d-flex flex-column"
      sx={{
        minHeight: 'calc(100vh - 300px)',
        '@media screen and (min-width: 600px)': { minHeight: 'calc(100vh - 170px)' },
      }}
    >
      {matches && showFilters && (
        <div className="d-flex align-items-center justify-content-between my-3">
          <Sort ban={category === 'land' ? ['rarity'] : []} />
          {category === 'land' ? <ViewSelect /> : <ViewSelect />}
        </div>
      )}
        <>
          <FetchWrapper empty={Object.keys(paginatedNFTs).length === 0}>
            {view === 'list' ? (
              <NFTListView items={paginatedNFTs.currentData()} />
            ) : (
              <NFTGridView items={paginatedNFTs.currentData()} centerAlign={centerGrid} />
            )}
          </FetchWrapper>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', alignItems: 'center', mt: 3 }}>
            <Typography sx={{ textAlign: 'center' }}>Page: {page}</Typography>
            {Object.keys(nfts).length > perPage && (
              <Pagination
                shape="rounded"
                color="primary"
                siblingCount={matches ? 1 : 0}
                size={matches ? 'large' : 'medium'}
                count={Math.ceil(Object.keys(nfts).length / perPage) || 1}
                onChange={handlePagination}
                page={page}
                defaultPage={page}
                sx={{
                  '& .MuiPaginationItem-root': {
                    fontWeight: 'bold',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    color: ColorPalette.white,
                    fontWeight: 'bold',
                  },
                }}
              />
            )}
            <Select
              labelId="pagination-label"
              value={perPage}
              label="Per Page"
              size="small"
              variant="standard"
              onChange={handlePaginationSelect}
              sx={{
                '.MuiSelect-icon': { color: theme => theme.palette.primary.main },
                minWidth: '60px',
                fontSize: '12px',
              }}
            >
              {[10, 15, 20, 25, 50, 100].map(p => (
                <MenuItem value={p} key={p}>
                  {p}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </>
    </Div>
  );
};
export default NFTList;
