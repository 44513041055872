import React, { ComponentType, CSSProperties } from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material';
import CMPButton from 'components/global/button';
import Div from 'components/global/div';
import { CustomIconProps } from 'components/icons';

export interface ITabItem<T> {
  key: T;
  icon?: ComponentType<CustomIconProps>;
}

export interface ITabViewProps<T> {
  items: ITabItem<T>[];
  activeTab?: T;
  onTabChange: (tab: T) => void;
  w100?: boolean;
  className?: string;
  buttonClassName?: string;
  buttonStyle?: CSSProperties;
  style?: CSSProperties;
}

function TabView<T>({
  items,
  activeTab,
  onTabChange,
  className,
  w100 = true,
  buttonClassName,
  buttonStyle,
  style,
}: ITabViewProps<T>): React.ReactElement {
  const { palette } = useTheme();
  return (
    <div className={clsx('d-flex align-items-center h-100', className, { 'w-100': w100 })} style={style}>
      {items.map(({ icon: TabIcon, key }) => {
        const color = activeTab === key ? palette.text.primary : palette.text.secondary;

        return (
          <CMPButton
            disableRipple
            key={String(key)}
            variant="text"
            onClick={() => onTabChange(key)}
            // @ts-ignore
            startIcon={TabIcon ? <TabIcon color={color} width={13} height={13} /> : undefined}
            style={buttonStyle}
            className={buttonClassName}
            sx={{
              width: '100%',
              height: '100%',
              padding: '0 16px',
              fontWeight: 600,
              color,
            }}
          >
            {key}
            {activeTab === key && (
              <Div
                sx={{
                  position: 'absolute',
                  bottom: '-1px',
                  width: '100%',
                  height: 4,
                  background: theme => theme.palette.primary.main,
                  borderTopRightRadius: 4,
                  borderTopLeftRadius: 4,
                }}
              />
            )}
          </CMPButton>
        );
      })}
    </div>
  );
}
export default TabView;
