import React from 'react';
import { CustomIconProps, setColor } from '..';

const ArtifactsIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
      <g id="Regular" transform="translate(-347 -376)">
        <rect
          id="Rectangle_3721"
          data-name="Rectangle 3721"
          width="10"
          height="2"
          rx="1"
          transform="translate(347 384)"
          fill={iconColor}
        />
        <rect
          id="Rectangle_3722"
          data-name="Rectangle 3722"
          width="10"
          height="2"
          rx="1"
          transform="translate(347 380)"
          fill={iconColor}
        />
        <rect
          id="Rectangle_3723"
          data-name="Rectangle 3723"
          width="10"
          height="2"
          rx="1"
          transform="translate(347 376)"
          fill={iconColor}
          opacity="0.25"
        />
      </g>
    </svg>
  );
};
export default ArtifactsIcon;
