import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Ticket10 from 'assets/images/ticket10.png';
import Ticket15 from 'assets/images/ticket15.png';
import Ticket20 from 'assets/images/ticket20.png';
import Ticket5 from 'assets/images/ticket5.png';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Div from 'components/global/div';
import CMPImage from 'components/global/image';
import { selectMintElvesTicketsSelected, selectMintElvesToMint } from '../../../store/mint/hooks';
import { setMintElvesSelected, setMintElvesToMint } from '../../../store/mint/reducer';
import ArrowButton from './ArrowBtn';

export const elvesImages: { [index: string]: any } = {
  '5%': Ticket5,
  '10%': Ticket10,
  '15%': Ticket15,
  '20%': Ticket20,
};

interface Props {
  discount: string;
  max: number;
  totalSelected: number;
}

const CosmicTicketStack: FC<Props> = ({ discount, max, totalSelected }) => {
  const tickets = useAppSelector(state =>
    Object.values(state.wallet.items721.ElvesTicket || {}).filter(e => e.attributes.discount === discount),
  );
  const allSelected = useAppSelector(selectMintElvesTicketsSelected);
  const toMint = useAppSelector(selectMintElvesToMint);
  const selected = allSelected[discount];
  const dispatch = useAppDispatch();

  const increment = () => {
    const count = selected + 1;
    dispatch(setMintElvesSelected({ discount, count }));
    if (totalSelected + 1 > toMint) {
      dispatch(setMintElvesToMint(totalSelected + 1));
    }
  };

  const decrement = () => {
    const count = selected - 1;
    dispatch(setMintElvesSelected({ discount, count }));
  };

  return (
    <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Div style={{ width: 'calc(100% - 20px)', maxWidth: 80 }}>
        <Typography sx={{ fontSize: 16, fontWeight: 'light' }}>{discount}</Typography>
        <CMPImage
          asset={{
            src: elvesImages[discount],
            alt: discount,
          }}
          width="100%"
          style={{ margin: '2px 0' }}
        />
        <Typography sx={{ fontSize: 16, fontWeight: 'light' }}>
          {selected}/{tickets.length}
        </Typography>
      </Div>
      <Stack
        direction="column"
        sx={{ alignItems: 'center', justifyContent: 'center', marginLeft: 1, width: 16, height: 32 }}
      >
        <ArrowButton
          direction="up"
          disabled={selected === tickets.length || totalSelected === max}
          onClick={increment}
        />
        <ArrowButton direction="down" disabled={selected === 0} onClick={decrement} />
      </Stack>
    </Stack>
  );
};

export default CosmicTicketStack;
