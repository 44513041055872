import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OldAddresses } from '../../constants/Addresses';
import { Sale } from '../../models/Sale';
import { SetNFTsPayload } from '../wallet/types';
import { AutoPlay, MarketState } from './types';

const initialState: MarketState = {
  autoPlay: 'hover',
  sales: [],
  nfts: Object.keys(OldAddresses.nfts).reduce((o, a) => {
    o[a] = {};
    return o;
  }, {} as { [index: string]: any }),
};

export const marketSlice = createSlice({
  name: 'mint',
  initialState,
  reducers: {
    setMarketAutoPlay: (state, action: PayloadAction<AutoPlay>) => {
      state.autoPlay = action.payload;
    },
    setMarketSales: (state, action: PayloadAction<Sale[]>) => {
      state.sales = action.payload;
    },
    setMarketNftsOf: (state, action: PayloadAction<SetNFTsPayload>) => {
      state.nfts[action.payload.token] = action.payload.nfts;
    },
    setMarketNfts: (state, action: PayloadAction<{ [index: string]: any }>) => {
      state.nfts = { ...state.nfts, ...action.payload };
    },
  },
});

export const { setMarketNfts, setMarketSales } = marketSlice.actions;

export default marketSlice.reducer;
