import React from 'react';
import { CustomIconProps, setColor } from '..';

const FemaleIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.505" height="16.508" viewBox="0 0 16.505 16.508">
      <g id="female" transform="translate(-131.124 -61.172)">
        <path
          id="Path_41"
          data-name="Path 41"
          d="M146.729,61.43a.783.783,0,0,0-.423.192l-1.614,1.614-1.614-1.614a.614.614,0,1,0-.871.865l1.614,1.62-1.71,1.71a6.628,6.628,0,1,0,.865.871l1.716-1.716,1.614,1.614a.615.615,0,1,0,.871-.865l-1.614-1.614,1.614-1.62a.615.615,0,0,0-.448-1.057Zm-8.8,4.112a5.329,5.329,0,1,1-5.328,5.329A5.319,5.319,0,0,1,137.931,65.542Z"
          fill={iconColor}
          stroke={iconColor}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
export default FemaleIcon;
