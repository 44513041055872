import React from 'react';
import { CustomIconProps } from '../index';

const MintIcon: React.FunctionComponent<CustomIconProps> = ({ active }) => {
  const black = '#111111'
  const gold = '#ffd58c'
  const white = '#E9E9E9'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 1907.42692 1905.98097">
      <g id="uuid-67257698-065d-4e49-a7dd-c731860e9f5a" data-name="Colour 1">
        <path
          id="uuid-5fb93f5f-9c33-44b7-90e4-b9f040268396"
          data-name="Anvil Join"
          d="M737.93567,884.95044v401.04393c0,2.20818-1.53223,4.00836-3.40942,3.94829-68.30037-2.34017-105.08374-38.77578-111.16187-45.31652-.59871-.64796-1.3699-1.01993-2.19182-1.03194h-.04057c-1.82647-.03604-3.28766-1.78817-3.28766-3.93643v-308.79076c0-24.36248,15.04822-27.06275,16.88485-27.30271,.1116-.02403,.21305-.02403,.32472-.02403,48.45258-.64811,74.08419-5.73661,87.65091-10.96896,.23343-.0961,.47695-.18019,.72047-.28802,.13192-.04805,.25368-.10812,.3856-.15617,.64943-.26399,1.27855-.52798,1.8671-.80399,.13192-.04805,.26377-.10812,.3856-.16803,5.63165-2.47217,9.3962-4.57238,11.8721-6.20467Z"
          fill={active ? black : white}
        />
        <path
          id="uuid-357cc358-f71d-492b-8d25-4a97ebc1f32c"
          data-name="Hammer Handle"
          d="M1193.97408,478.96999L241.77518,75.77791c-16.69508-7.06783-25.98785-25.03436-20.74342-40.12351l6.35684-18.28432c5.24442-15.08921,23.02691-21.57985,39.73036-14.51207l952.7258,403.4263-25.87068,72.68568Z"
          fill={active ? black : white}
        />
      </g>
      <g id="uuid-283219fb-7d68-48f0-b199-b01c1c85ac52" data-name="Colour 2">
        <path
          id="uuid-d793a837-5275-4cc9-9895-5c628edcd35f"
          data-name="Anvil Top"
          d="M1907.42692,866.19824v29.36294c0,6.44838-3.85596,11.93388-9.15274,13.17878H748.06586v-47.2589c0-21.8544,18.4779-20.48306,18.4779-20.48306H1854.30669c16.85438,0,35.9412,7.91702,46.01724,12.74116,4.29222,2.06192,7.103,6.9833,7.103,12.45907Z"
          fill={active ? white : gold}
        />
        <path
          id="uuid-59e962e0-0323-4b1f-90f6-5e4384b03926"
          data-name="Anvil Bottom"
          d="M748.06586,922.48288H1871.87131l-.17242,11.7685c-.05079,.49604-.0711,.99208-.0711,1.49785v50.96446c0,6.41923-3.8458,11.93388-9.17305,13.18852l-196.3674,45.66381s-198.62004,43.70893-329.10191,178.92048c-125.87528,130.41644-83.0341,243.0636-79.85804,250.85418,.13192,.34039,.25368,.6613,.35513,.99208,31.43581,99.3807,213.37401,177.76302,241.0859,189.20086,2.31345,.9726,4.30238,2.76216,5.67209,5.16447l51.35477,89.83951c1.26839,2.2273,1.94817,4.84355,1.94817,7.51822v124.48369c0,7.4211-5.08373,13.44147-11.36474,13.44147h-198.20409c-2.23232,0-4.41386-.7976-6.27086-2.24677-275.94117-215.80146-523.99809-24.29565-553.18126-.175-1.90767,1.58535-4.17046,2.42178-6.50435,2.42178h-201.57283c-6.28108,0-11.36481-6.02037-11.36481-13.44147v-128.50044c0-1.86744,.32472-3.71542,.96399-5.41748l32.11565-86.08527c.3755-1.01155,.85239-1.94521,1.42062-2.81084h.01016c470.87785-191.57396,145.37804-364.97972,145.37804-364.97972-.58855-1.63403-.90311-3.42347-.90311-5.24235v-377.02052Z"
          fill={active ? white : gold}
        />
        <path
          id="uuid-959636ca-698b-422e-84f9-b4b4e405c168"
          data-name="Anvil Left"
          d="M594.51939,1241.83762C297.63446,1230.4874,66.84798,1014.33569,3.13414,948.4125c-6.34196-6.57476-2.41502-18.92685,6.01724-18.92685H594.83395c5.0431,0,9.13242,4.83382,9.13242,10.79589v290.75057c0,6.10799-4.28212,11.0001-9.44698,10.80551Z"
          fill={active ? white : gold}
        />
        <path
          id="uuid-8e862321-6636-44ba-b5a3-054ce32c66a8"
          data-name="Hammer Head"
          d="M1469.5771,406.85917l-100.19583,288.35043c-3.61333,10.40518-15.88377,14.88842-27.40141,10.01208l-170.17138-72.05835c-11.51764-4.87639-17.92461-17.2639-14.31128-27.67745l41.82981-120.36206,.48509-1.39682,25.33546-72.9199,32.54537-93.66327c2.50091-7.18489,6.81702-13.4916,12.60504-18.41818l96.19767-81.75253c27.79451-23.62074,76.52486-2.98606,82.88179,35.09657l21.98964,131.79608c1.31315,7.9377,.71094,15.81684-1.78997,22.99342Z"
          fill={active ? white : gold}
        />
      </g>
    </svg>
  );
};
export default MintIcon;
