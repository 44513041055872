import React from 'react';
import { Card, CardContent, Stack, Typography, Grid, useMediaQuery, useTheme, Theme, styled } from '@mui/material';
import Div from 'components/global/div';
import CMPImage from 'components/global/image';
import Crown from '../../../assets/images/crown.png';
import Crown2 from '../../../assets/images/crown2.png';
import Divider from '../../../assets/images/divider.svg';
import ElfItemBorder from '../../../assets/images/item-border.svg';
import Necklace from '../../../assets/images/necklace.png';
import Elf from '../../../assets/images/nfts/elf-4272-crop.png';
import Staff1 from '../../../assets/images/staff1.png';
import Staff2 from '../../../assets/images/staff2.png';
import Staff3 from '../../../assets/images/staff3.png';
import ElfGif from '../../../assets/spellcasting.gif';

const StyledItemContainer = styled(Div)(({ theme }) => ({
  position: 'relative',
  background: '#270232',
  borderRadius: '50%',
  width: '100%',
  maxWidth: 200,
  marginBottom: 16,
  marginRight: 0,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
    marginRight: 16,
  },
  '&:last-child': {
    margin: 0,
  },
}));

const ElvesInfo = () => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
  const theme = useTheme();

  return (
    <Card
      sx={{
        borderRadius: '16px',
        background: theme.palette.mintBg,
      }}
    >
      <CardContent sx={{ padding: 5 }}>
        <Grid container spacing={5} sx={{ alignItems: 'flex-start', marginBottom: 2 }}>
          <Grid item xs={12} md={5} sx={{ textAlign: 'left' }}>
            <Typography sx={{ fontSize: 28 }}>
              <b>ELVES</b> are the second root race collection released for the Cosmic Universe MMORPG
            </Typography>
            <CMPImage
              asset={{
                src: Divider,
                alt: 'devider',
              }}
              width="100%"
              style={{ margin: '40px 0' }}
            />
            <Typography sx={{ fontSize: 18 }}>
              Each NFT is a still-framed capture of actual in-game models completely designed, rigged, and animated
              within Unreal Engine 5.
              <br />
              <br />
              Elves are playable characters that will be playable in both the mini-game Dawn of Crypton as well as the
              full-scale MMORPG. Cosmic Universe.
            </Typography>
            <Grid container spacing={5} sx={{ justifyContent: 'space-between', marginTop: 0 }}>
              <Grid item xs={4} sx={{ textAlign: 'center' }}>
                <CMPImage
                  asset={{
                    src: Staff1,
                    alt: 'staff1',
                  }}
                  height={143}
                />
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'center' }}>
                <CMPImage
                  asset={{
                    src: Staff2,
                    alt: 'staff2',
                  }}
                  height={143}
                />
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'center' }}>
                <CMPImage
                  asset={{
                    src: Staff3,
                    alt: 'staff3',
                  }}
                  height={143}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                <CMPImage
                  asset={{
                    src: ElfGif,
                    alt: 'Elf',
                  }}
                  width={matches ? '100%' : '60%'}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack
                  direction={matches ? 'column' : 'row'}
                  sx={{ justifyContent: 'space-between', alignItems: 'flex-end', height: '100%' }}
                >
                  <StyledItemContainer>
                    <CMPImage
                      asset={{
                        src: ElfItemBorder,
                        alt: 'ElfItemBorder',
                      }}
                      width="100%"
                      height="100%"
                    />
                    <CMPImage
                      asset={{
                        src: Crown,
                        alt: 'crown',
                      }}
                      width="60%"
                      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    />
                  </StyledItemContainer>
                  <StyledItemContainer>
                    <CMPImage
                      asset={{
                        src: ElfItemBorder,
                        alt: 'ElfItemBorder',
                      }}
                      width="100%"
                      height="100%"
                    />
                    <CMPImage
                      asset={{
                        src: Crown2,
                        alt: 'crown2',
                      }}
                      width="60%"
                      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    />
                  </StyledItemContainer>
                  <StyledItemContainer>
                    <CMPImage
                      asset={{
                        src: ElfItemBorder,
                        alt: 'ElfItemBorder',
                      }}
                      width="100%"
                      height="100%"
                    />
                    <CMPImage
                      asset={{
                        src: Necklace,
                        alt: 'necklace',
                      }}
                      width="60%"
                      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    />
                  </StyledItemContainer>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ElvesInfo;
