import React from 'react';
import CMPImage from 'components/global/image';
import { CustomIconProps } from '../index';

const StakingIcon: React.FunctionComponent<CustomIconProps> = ({ active, width, height }) => {
  const activeColor = '#ffd58c'
  const inactiveColor = '#E9E9E9'
  return (
    <svg
      version="1.1"
      id="Bag"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width || 36}
      height={height || 36}
      viewBox="0 0 595.2755737 595.2755737"
      xmlSpace="preserve"
    >
      <path fill="#FFFFFF" stroke="#000000" strokeMiterlimit="10" d="M287.1636658,60.1187096" />
      <path
        fill={active ? inactiveColor : activeColor}
        d="M248.1630554,25.7800827c0,0,32.2069397,14.0977974,40.004425,16.5145607 c7.7974548,2.4167671,22.9403687,2.7188606,34.4670715-2.4167633s61.0236816-26.1816254,61.0236816-26.1816254 s20.9062805-9.8684578,46.5588074-10.3719501c0,0,6.1023865-0.4027944,12.4307556,1.1076841 c6.3283997,1.510478,10.8486633,4.0279422,10.8486633,4.0279422s6.102356,3.8265448,7.4584656,5.7398176 c1.3560791,1.9132729,4.7462769,4.531435,6.8934021,11.3789368s0.3390198,17.1187515-1.0170593,20.2404099 c-1.3560791,3.1216545-5.9893494,19.9383125-30.6248474,46.0192375s-25.200531,23.3620605-25.200531,23.3620605 s-3.2771912,2.6181641-8.9275208,2.9202652c-5.6503601,0.3020935-200.4741211,0.4027939-200.4741211,0.4027939 s-8.927536,0.5034866-16.7250061-6.2433167s-38.7613373-39.7759247-42.1515503-49.0401917 c-3.3901978-9.264267-10.5096283-13.9971008-9.9445953-32.1228409c1.6322632-12.5688744,9.3017883-21.4367962,22.6766968-25.4096012 c0,0,6.7050781-2.4167655,20.8685913-2.148236c14.1635284,0.2685294,35.3334656,7.3845606,35.3334656,7.3845606 L248.1630554,25.7800827z"
      />
      <path
        fill={active ? '#111111' : '#E9E9E9'}
        d="M183.4506989,143.3069l224.3609161-0.2265625c0,0,4.0258789-0.0629425,7.2041931,1.5608215 s4.7321472,3.5118561,5.1700745,4.0279388c0.4378662,0.5160828,3.0935364,4.1915741,3.4890747,5.5384216 c0.3955078,1.3468475,2.2883911,4.6573029,0.5367737,12.3103943c-1.7515869,7.6530914-11.4701843,11.932785-11.4701843,11.932785 l-2.486145,0.7929993c0,0-1.7374878,0.6923065-5.0570679,0.7174835c-3.3195801,0.0251617-216.2701263,0-216.2701263,0 s-2.7866211-0.1018829-4.9138031-0.7842712c-2.127182-0.682373-4.0203857-1.4784851-5.349884-2.3314667 c-1.329483-0.8529816-3.7332001-2.5210114-5.3073273-4.5776367c-1.5741119-2.0566254-1.957016-2.9190674-1.957016-2.9190674 s-1.4039459-2.7295227-1.7123871-4.8524933c-0.3084412-2.1229553-0.3828888-4.3027954-0.0744476-5.9613495 c0.3084412-1.6585693,1.0316925-4.2269745,1.9676514-5.8286743s2.2016449-3.6867523,4.169281-5.3453217 C177.717926,145.7023468,180.3875427,143.7641907,183.4506989,143.3069z"
      />
      <path
        fill={active ? inactiveColor : activeColor }
        d="M184.4842224,204.5204315h228.1235352 c79.9257507,45.6826935,145.7582092,217.2137299,169.0749207,278.7368164 c4.0142212,8.2704773,9.3070679,21.9998474,9.6211548,39.3703003c0.2056274,11.3696899,0.6461792,35.7307129-15.2821655,51.3894043 c-12.0873413,11.8827515-28.4440918,13.6845093-90.6529541,15.574707 c-54.6741028,1.6613159-77.5580139,1.6838989-77.558136,1.6838989 c-79.4087219-0.3119507-119.2870789-0.5523682-135.3571472-0.6097412 c-79.6266632-0.2841797-119.4399567-0.4262695-122.2835236-0.3896484 c-61.9407501,0.798645-99.9188232,2.8825684-124.0302658-20.0186157 c-10.121954-9.6138916-15.3094616-21.2651978-16.7094345-24.6036377 c-9.6514606-23.0153809-4.2756577-44.371521,0.0101967-59.1775513 C24.8471451,433.25177,48.0100021,389.217926,50.241806,385.0228577 c36.7013855-68.9866638,58.4675674-109.0703125,103.5277557-153.1124115 C166.2295074,219.7320251,177.2293549,210.418869,184.4842224,204.5204315z"
      />
    </svg>
  );
};

export default StakingIcon;
