import React from 'react';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import { CallMissedOutgoing } from '@mui/icons-material';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid';
import store from 'store/index';
import state from 'store/index';
import { Addresses } from 'constants/Addresses';
import { NFTProfession, prettyNFTProfessions } from 'models/NFT';
import { GetProfessionIcon } from 'pages/nft/components/nft-body/professions/profession';
import PotionsIcon from 'components/icons/main/PotionsIcon';
import { Chain } from "wagmi";
import { setStakingSelectProfessionNft } from '../../../../store/staking/reducer';

const StakingTableColumns = (chain: Chain): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    width: 60,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'stakingUnlocked',
    headerName: 'Unlocked',
    type: 'boolean',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'stakingStaked',
    headerName: 'Staked',
    type: 'boolean',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'isTraining',
    headerName: 'Training',
    type: 'boolean',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'levels',
    headerName: 'Levels',
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const total = Object.entries(params.row)
        .filter(([h]) => Object.keys(prettyNFTProfessions).includes(h))
        .reduce((n, [, v]) => {
          n += Number(v);
          return n;
        }, 0);
      return <span>{total}</span>;
    },
  },
  ...Object.keys(prettyNFTProfessions).map(p => ({
    field: p,
    width: 70,
    headerName: prettyNFTProfessions[p as NFTProfession],
    renderHeader: (headerParams: GridColumnHeaderParams) => (
      <div style={{ transform: 'scale(0.4)', maxHeight: '56px' }}>{GetProfessionIcon(headerParams.field)}</div>
    ),
    type: 'number',
    renderCell: (params: GridRenderCellParams) => {
      if (params.value > 0) {
        return (
          <Chip
            label={params.value}
            size="small"
            sx={{
              margin: 'auto',
              ...(state.getState().global.secretMode
                ? {
                    backgroundColor:
                      params.value === 20
                        ? Object.values(params.row).filter(v => v === 20).length === 2
                          ? 'blue'
                          : 'green'
                        : params.value > 15
                        ? 'lightgreen'
                        : params.value > 15
                        ? 'yellow'
                        : params.value > 5
                        ? 'orange'
                        : 'red',
                  }
                : {}),
            }}
          />
        );
      }
      return <div style={{ textAlign: 'center', width: '100%' }}>{params.value}</div>;
    },
  })),
  {
    field: 'trainingCompleteAt',
    headerName: 'Complete',
    width: 110,
    align: 'center',
    headerAlign: 'center',
    type: 'dateTime',
    renderCell: params =>
      params.value ? (
        // @ts-ignore
        <Countdown date={params.value} daysInHours>
          <span>Complete</span>
        </Countdown>
      ) : (
        <span>{'---'}</span>
      ),
  },
  { field: 'rawData', hide: true, hideable: false },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    minWidth: 100,
    flex: 1,
    renderCell: params => {
      return (
        <>
          {(params.row.levels === 0 || params.row.levels === 20) &&
            params.row.stakingStaked &&
            !params.row.isTraining && (
              <Tooltip title="Select Profession">
                <IconButton
                  size="small"
                  onClick={() => store.dispatch(setStakingSelectProfessionNft(params.row.rawData))}
                  color="primary"
                >
                  <PotionsIcon />
                </IconButton>
              </Tooltip>
            )}
          <Tooltip title="Character Page">
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to={`/gallery/character/${Addresses[chain.id].NFTs.Wizards}/${params.row.id}`}
            >
              <CallMissedOutgoing />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  },
];

export default StakingTableColumns;
