import { Avatar } from "@mui/material";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Strings from 'utils/Strings';
import { Blockchains as blkchains } from 'constants/filters/Character';
import { Blockchain, CharacterRace } from "models/Filters";
import FilterCard from 'components/common/filter-card';
import FilterContainer from 'components/common/filter-container';
import Circle from 'components/global/circle';
import { ChainList } from "../../../../../constants/wagmi/chains";
import {useAppSelector} from "../../../../../store/hooks";
import { setBlockchains, setRaces } from "../../../../../store/marketplace/reducer";

const Blockchains: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { blockchains } = useAppSelector(state => state.marketplace);

  const onSelectHandler = (key: Blockchain) => {
    const index = blockchains.findIndex(item => item === key);
    if (index === -1) {
      dispatch(setBlockchains(blockchains.concat(key)));
    } else {
      dispatch(setBlockchains(blockchains.slice(0, index).concat(blockchains.slice(index+1, blockchains.length))));
    }
  };

  return (
    <FilterContainer
      className="mt-3"
      title={"Blockchain"}
      count={blockchains.length > 0 ? blockchains.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
          {blkchains.filter(bc => !bc.disabled).map(bc => (
            <FilterCard
              key={bc.type}
              icon={<Avatar sx={{ width: '14px', height: '14px' }} src={ChainList.find(c => c.name === bc.type)?.logo} />}
              text={bc.type}
              onClick={() => onSelectHandler(bc.type)}
              active={blockchains.includes(bc.type)}
              disabled={bc.disabled}
            />
          ))}
        </div>
      }
    />
  );
};
export default Blockchains;
