import React, { FC, Fragment } from 'react';
import { OldAddresses } from '../../../constants/Addresses';
import GlobalAvaxUpdater from './GlobalAvaxUpdater';
import GlobalCosmicUpdater from './GlobalCosmicUpdater';
import GlobalItemUpdater from './GlobalItemUpdater';
import GlobalMagicUpdater from './GlobalMagicUpdater';
import GlobalNFTUpdater from './GlobalNFTUpdater';
import GlobalPairUpdater from './GlobalPairUpdater';

const GlobalUpdater: FC = () => {
  return (
    <Fragment>
      {Object.entries(OldAddresses.lpTokens).map(([symbol, address]) => (
        <GlobalPairUpdater key={symbol} symbol={symbol} address={address} />
      ))}
      <GlobalMagicUpdater />
      <GlobalCosmicUpdater />
      <GlobalAvaxUpdater />
      <GlobalNFTUpdater />
      <GlobalItemUpdater />
    </Fragment>
  );
};

export default GlobalUpdater;
