import React, { FC } from 'react';
import { useSigner } from 'wagmi';
import { CardActions, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { claim } from 'services/StakeNFT';
import CMPButton from 'components/global/button';
import { useAppSelector } from '../../../../store/hooks';
import SummaryCard from '../SummaryCard';

const RewardsSummaryCard: FC = () => {
  const { data } = useSigner();
  const wiz = useAppSelector(state => Object.values(state.wallet.nfts.Wizards || {}));
  const epic = wiz.filter(w => w.totalProfessionPoints >= 20 && w.chestsClaimed == 0);
  const mythical = wiz.filter(w => w.totalProfessionPoints >= 40 && w.chestsClaimed < 2);
  const wizLevels = wiz.map(w => Object.values(w.professions || {}).reduce((p, v) => p + v, 0) || 0);
  const lvl20Chests = wizLevels.reduce((p, v) => (v >= 20 ? p + 1 : p), 0);
  const lvl40Chests = wizLevels.reduce((p, v) => (v >= 40 ? p + 1 : p), 0);
  const handleClaim = async () => {
    if (!data) {
      return;
    }
    const ids = epic
      .map(w => w.tokenId)
      .concat(mythical.map(w => w.tokenId))
      .filter((v, i, s) => s.indexOf(v) === i);
    await claim(ids, data);
  };
  const rows = [
    {
      category: 'Rare',
      earned: lvl20Chests,
      amount: epic.length,
    },
    {
      category: 'Mythical',
      earned: lvl40Chests,
      amount: mythical.length,
    },
  ];
  return (
    <SummaryCard>
      <CardHeader title="Rewards" sx={{ textAlign: 'center', paddingBottom: 0 }} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Chest</TableCell>
              <TableCell align="center">Claimed</TableCell>
              <TableCell align="center">Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <TableRow key={i} component="th" scope="row">
                <TableCell>{r.category}</TableCell>
                <TableCell align="center">{r.earned}</TableCell>
                <TableCell align="center">{r.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions sx={{ marginTop: 'auto' }}>
        <CMPButton fullWidth onClick={handleClaim} disabled={epic.length + mythical.length === 0}>
          Claim
        </CMPButton>
      </CardActions>
    </SummaryCard>
  );
};

export default RewardsSummaryCard;
