import React, { FC } from 'react';
import { Grid } from '@mui/material';
import LeaderboardSummaryCard from './LeaderboardSummaryCard';
import MetricsSummaryCard from './MetricsSummaryCard';
import RewardsSummaryCard from './RewardsSummaryCard';
import TotalsSummaryCard from './TotalsSummaryCard';

const SummaryCards: FC = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'stretch',
        display: 'flex',
        marginTop: 0,
        marginBottom: 3,
      }}
    >
      {[LeaderboardSummaryCard, MetricsSummaryCard, TotalsSummaryCard, RewardsSummaryCard].map((Card, i) => (
        <Grid item key={i} display="flex">
          <Card />
        </Grid>
      ))}
    </Grid>
  );
};

export default SummaryCards;
