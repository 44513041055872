import React from 'react';
import { SxProps, Theme, Typography } from '@mui/material';
import StyledResourceCard from './_styles';

interface IProps {
  icon: React.ReactNode;
  count: number;
  sx?: SxProps<Theme>;
}

const ResourceCard: React.FunctionComponent<IProps> = ({ count, icon, sx }) => {
  return (
    <StyledResourceCard sx={sx}>
      {icon}
      <Typography marginLeft={1} variant="body1" fontSize="18px">
        {count}
      </Typography>
    </StyledResourceCard>
  );
};

export default ResourceCard;
