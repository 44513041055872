import { BigNumber } from 'ethers';
import { Sale } from '../../models/Sale';
import { NFTsState } from '../wallet/types';

export type AutoPlay = boolean | 'hover';

export interface MarketState {
  autoPlay: AutoPlay;
  sales: Sale[];
  nfts: NFTsState;
}

export enum SaleType {
  AUCTION,
  FIXED,
  OFFER,
}

export enum TokenType {
  ERC721,
  ERC1155,
}

export type RawSale = {
  saleType: SaleType;
  seller: string;
  contractAddress: string;
  tokenType: TokenType;
  tokenIds: BigNumber[];
  values: BigNumber[];
  bidToken: string;
  startTime: BigNumber;
  duration: BigNumber;
  extensionDuration: BigNumber;
  endTime: BigNumber;
  bidder: string;
  bidAmount: BigNumber;
};
