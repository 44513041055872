import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dexScreenerApi = createApi({
  reducerPath: 'dexScreenerApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.dexscreener.io/latest/dex/' }),
  endpoints: builder => ({
    fetchTokenData: builder.query<any, string>({
      query: contract => 'tokens/' + contract,
      transformResponse: response => (response as any)?.pairs?.find((p: { dexId: string }) => p.dexId === 'traderjoe') || undefined,
    }),
    fetchPairData: builder.query<any, string>({
      query: contract => `pairs/avalanche/${contract}`,
      transformResponse: response => (response as any)?.pairs?.find((p: { dexId: string }) => p.dexId === 'traderjoe') || undefined,
    }),
  }),
});

export const { useFetchTokenDataQuery, useFetchPairDataQuery } = dexScreenerApi;
