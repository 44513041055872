import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import TickIcon from 'components/icons/main/TickIcon';
import Filter from './_styles';

interface IProps {
  icon?: ReactNode;
  endIcon?: ReactNode;
  text: string;
  active?: boolean;
  onClick?: () => void;
  purpleMode?: boolean;
  w100?: boolean;
  disabled?: boolean;
}

const FilterCard: React.FunctionComponent<IProps> = ({
  icon: Icon,
  endIcon: EndIcon,
  text,
  active,
  purpleMode,
  onClick,
  w100,
  disabled,
}) => {
  return (
    <Filter
      variant="text"
      color="inherit"
      onClick={onClick}
      disableRipple
      disabled={disabled}
      sx={{
        borderColor: active ? (purpleMode ? 'purple' : 'primary.main') : 'transparent',
        width: w100 ? '100%' : undefined,
        maxWidth: w100 ? '100%' : undefined,
        justifyContent: EndIcon ? 'space-between' : undefined,
      }}
    >
      {Icon && (
        <span style={{ width: 'fit-content', height: 'fit-content', display: 'flex' }}>
          {Icon ? active && !purpleMode ? <TickIcon active /> : Icon : null}
        </span>
      )}
      <Typography className="ms-2" fontWeight={600}>
        {text}
      </Typography>
      {EndIcon}
    </Filter>
  );
};
export default FilterCard;
