import React, { useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Theme, useMediaQuery } from '@mui/material';
import { enableSecretMode } from 'store/global/reducer';
import { useAppDispatch } from 'store/hooks';
import ColorPalette from 'utils/ColorPalette';
import Logo from 'components/icons/Logo';
import CMPSidebar, { classes } from './_styles';
import sidebarItems, { ISideBarItem } from './data';
import LeavingPopup from './LeavingPopup';

const SideBar: React.FunctionComponent = () => {
  const location = useLocation();
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const [secretModeClicks, setSecretModeClicks] = useState(0);
  const dispatch = useAppDispatch();
  const [timeout, setSecretTimeout] = useState<NodeJS.Timeout>();
  const [leaving, setLeaving] = useState<Omit<ISideBarItem, 'icon'> | undefined>(undefined);

  const secretMode = () => {
    console.log('Clicked', secretModeClicks);
    clearTimeout(timeout);
    setSecretTimeout(undefined);
    const newCount = secretModeClicks + 1;
    if (newCount > 10) {
      return;
    }
    if (newCount == 10) {
      dispatch(enableSecretMode());
      return;
    }
    setSecretModeClicks(newCount);
    const t = setTimeout(() => {
      setSecretModeClicks(0);
    }, 500);
    setSecretTimeout(t);
  };

  return (
    <CMPSidebar>
      <LeavingPopup destination={leaving} setDestination={setLeaving} />
      {matches && (
        <div className={classes.logo} onClick={secretMode}>
          <Logo />
        </div>
      )}
      <List disablePadding className={clsx({ 'd-flex justify-content-center w-100': !matches })}>
        {sidebarItems
          .filter(i => i.type === 'INTERNAL')
          .map(({ icon: Icon, ...item }) => (
            <ListItemButton
              key={item.path}
              className="d-flex flex-column align-items-center justify-content-center"
              component={Link}
              to={item.path}
              disabled={item.disabled}
              sx={{
                width: matches ? 84 : `${100 / sidebarItems.length}%`,
                maxWidth: 84,
                height: 76,
                backgroundColor: location.pathname.startsWith(item.path) ? 'rgba(255, 255, 255, 0.2)' : undefined,
                ...(location.pathname.startsWith(item.path) ? { pointerEvents: 'none' } : {})
              }}
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                {/* @ts-ignore */}
                <Icon active={location.pathname.startsWith(item.path)} />
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                classes={{ root: 'mb-0' }}
                primaryTypographyProps={{
                  variant: 'body2',
                  sx: { color: ColorPalette.white },
                }}
              />
            </ListItemButton>
          ))}
        {matches && (
          <Divider
            variant="middle"
            sx={{
              borderColor: 'rgba(255, 255, 255, 0.5)',
              marginTop: '0.8rem',
              marginBottom: '0.8rem',
            }}
          />
        )}
        {sidebarItems
          .filter(i => i.type === 'EXTERNAL')
          .map(({ icon: Icon, ...item }) => (
            <ListItemButton
              key={item.path}
              className="d-flex flex-column align-items-center justify-content-center"
              disabled={item.disabled}
              onClick={() => setLeaving(item)}
              sx={{
                width: matches ? 84 : `${100 / sidebarItems.length}%`,
                maxWidth: 84,
                height: 76,
                backgroundColor: location.pathname.startsWith(item.path) ? 'rgba(255, 255, 255, 0.2)' : undefined,
              }}
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                {/* @ts-ignore */}
                <Icon active={location.pathname.startsWith(item.path)} />
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                classes={{ root: 'mb-0' }}
                primaryTypographyProps={{
                  variant: 'body2',
                  sx: { color: ColorPalette.white },
                }}
              />
            </ListItemButton>
          ))}
      </List>
    </CMPSidebar>
  );
};
export default SideBar;
