import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from 'react';
import { BigNumber, utils } from "ethers";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader, SelectChangeEvent, Stack,
  styled, Typography
} from "@mui/material";
import {
  useContract,
  useContractEvent,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useTransaction,
  useWaitForTransaction
} from "wagmi";

import CMPButton from "../../components/global/button";
import { ABIs, Addresses } from "../../constants/Addresses";
import Chains from "../../constants/wagmi/chains";
import useDebounce from "../../hooks/useDebounce";
import { NFT } from "../../models/NFT";
import { setAppLoading } from "../../store/app/reducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const StyledCard = styled(Card)({
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '320px',
  height: `100%`,
});

const rarityMap: { [rarity: string]: string } = {
  Common: "#6c6c6d",
  Uncommon: "#3a9529",
  Rare: "#136ab1",
  Mythical: "#9a01d4"
}

const ToolReRollCard = () => {

  const walletTools = useAppSelector(state => state.wallet.items721.Tools || {});

  const [eligibleTools, setEligibleTools] = useState<NFT[]>([])
  const [selection, setSelection] = useState<NFT | "">("")
  const [newRarity, setNewRarity] = useState<string>("")
  const debouncedTokenId = useDebounce(BigNumber.from(selection ? selection.tokenId : 0), 1000)

  const dispatch = useAppDispatch();

  const call = (func?: Function) => {
    if (func) {
      dispatch(setAppLoading(true));
      func();
    }
  }

  const contract = useContract({
    address: Addresses[Chains.bobaAvax.id].NPCs.ChestRedeemer,
    abi: ABIs.NPCs.ChestRedeemer,
  })

  const { isLoading, refetch } = useContractRead({
    address: Addresses[Chains.bobaAvax.id].NPCs.ChestRedeemer,
    abi: ABIs.NPCs.ChestRedeemer,
    functionName: "reRollEligible",
    chainId: Chains.bobaAvax.id,
    cacheTime: 600_000,
    enabled: Object.values(walletTools).length > 0,
    onSuccess: (allTokenIds) => {
      const sanitized = allTokenIds.map(t => t.toNumber());
      const walletIds = Object.values(walletTools).filter((t => sanitized.includes(t.tokenId)))
      setEligibleTools(walletIds);
    }
  })

  const { config: reRollConfig } = usePrepareContractWrite({
    address: Addresses[Chains.bobaAvax.id].NPCs.ChestRedeemer,
    abi: ABIs.NPCs.ChestRedeemer,
    functionName: 'reRollToolRarity',
    args: [debouncedTokenId],
    chainId: Chains.bobaAvax.id,
    enabled: debouncedTokenId.gt(0) && !isLoading,
    overrides: {
      value: utils.parseEther("0.01")
    }
  })

  const { data: reRollData, write: writeReRoll, isError: isWriteReRollError } = useContractWrite(reRollConfig);

  const { isError: isWaitForTXError } = useWaitForTransaction({
    hash: reRollData?.hash,
    chainId: Chains.bobaAvax.id,
    enabled: !!reRollData,
    onSuccess: async (data) => {
      if (contract) {
        setSelection("")
        await refetch()
        const result = contract.interface.parseLog(data.logs[4])
        setNewRarity(Object.keys(rarityMap)[result.args.newRarity.toNumber()])
      }
    },
    onSettled: () => {
      dispatch(setAppLoading(false))
    }
  })

  const { config: declineConfig } = usePrepareContractWrite({
    address: Addresses[Chains.bobaAvax.id].NPCs.ChestRedeemer,
    abi: ABIs.NPCs.ChestRedeemer,
    functionName: 'declineToolReRoll',
    args: [debouncedTokenId],
    chainId: Chains.bobaAvax.id,
    enabled: debouncedTokenId.gt(0) && !isLoading
  })

  const { data: declineData, write: writeDecline, isError: isWriteDeclineError } = useContractWrite(declineConfig);

  const { isError: isWaitForTX2Error } = useWaitForTransaction({
    hash: declineData?.hash,
    chainId: Chains.bobaAvax.id,
    enabled: !!declineData,
    onSuccess: async (data) => {
      setSelection("")
      await refetch()
    },
    onSettled: () => {
      dispatch(setAppLoading(false))
    }
  })
  const handleChange = (e: SelectChangeEvent<NFT>) => {
    setSelection(e.target.value as NFT)
    setNewRarity("")
  }
  return (
    <StyledCard>
      <CardHeader
        title="Tool Re-roll"
        subheader={`Lets you re-roll tools that were affected by the chest redeemer rarity bug for Mythical Chests`}
        sx={{ textAlign: 'center', maxWidth: 400, paddingBottom: 0 }}
      />
      <CardContent>
        <FormControl fullWidth color="secondary" disabled={isLoading || eligibleTools.length === 0}>
          <InputLabel id="selection-label">
            {Object.values(walletTools).length === 0
              ? "No Tools Owned"
              : eligibleTools.length === 0
                ? "No Eligible Tools"
                : "Token ID"
            }
          </InputLabel>
          <Select
            labelId="selection-label"
            value={selection ? selection : ""}
            label={Object.values(walletTools).length === 0
              ? "No Tools Owned"
              : eligibleTools.length === 0
                ? "No Eligible Tools"
                : "Token ID"
            }
            renderValue={v => (
              <Stack direction="row" justifyContent="space-between" width={1}>
                <Typography>#{v.tokenId}</Typography>
                <Typography color={rarityMap[v?.attributes?.rarity]}>{v?.attributes?.rarity}</Typography>
              </Stack>
            )}
            onChange={handleChange}
          >
            {eligibleTools.map(n => (
              <MenuItem key={n.tokenId} value={n as any}>
                <Stack direction="row" justifyContent="space-between" width={1}>
                  <Typography>#{n.tokenId}</Typography>
                  <Typography color={rarityMap[n.attributes.rarity]}>{n.attributes.rarity}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(isWriteReRollError || isWriteDeclineError || isWaitForTXError || isWaitForTX2Error) && (
          <Typography color="red">Error calling contract</Typography>
        )}
        {newRarity && (
          <Stack direction="row" justifyContent="center" width={1} mt={2}>
            <Typography marginRight={1}>New Rarity:</Typography>
            <Typography color={rarityMap[newRarity]}>{newRarity}</Typography>
          </Stack>
        )}
      </CardContent>
      <CardActions sx={{ marginTop: 'auto' }}>
        <CMPButton fullWidth disabled={eligibleTools.length === 0 || !selection} onClick={() => call(writeDecline)}>
          Decline
        </CMPButton>
        <CMPButton fullWidth disabled={eligibleTools.length === 0 || !selection} onClick={() => call(writeReRoll)}>
          Re-roll
        </CMPButton>
      </CardActions>
    </StyledCard>
  );
};

export default ToolReRollCard;
