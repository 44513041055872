import React, { useEffect, useState } from 'react';
import { useAccount, useSigner } from 'wagmi';
import { Grid, Typography } from '@mui/material';
import { StartTraining, CancelTraining, FinishTraining } from 'services/StakeNFT';
import { GetProfessionFromId, NFTProfession, GetPrettyProfessionFromId, GetProfessionId } from 'models/NFT';
import CMPDropdown, { IDropDownItem } from 'components/common/dropdown';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectStakingSelectProfessionNft } from '../../../store/staking/hooks';
import { setStakingSelectProfessionNft } from '../../../store/staking/reducer';

const time = [5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79];

const SelectProfessionDialog = () => {
  const nft = useAppSelector(selectStakingSelectProfessionNft);
  const { data } = useSigner();
  const [value, setValue] = useState<IDropDownItem<number>>();
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const dispatch = useAppDispatch();
  const options = (): number[] => {
    if (!nft) {
      return [];
    }
    const allOptions = Array.from(Array(12).keys());
    if (!nft.professions) {
      return [];
    }

    const levels = Object.values(nft.professions).filter(n => n > 0);

    switch (levels.length) {
      case 0: {
        return allOptions;
      }
      case 1: {
        const profData = Object.entries(nft.professions).find(p => p[1] === levels[0]);
        if (!profData) {
          return [];
        }
        const id = GetProfessionId(profData[0] as NFTProfession);
        if (levels[0] !== 20) {
          return id !== null ? [id] : [];
        }
        return allOptions.filter(n => n !== id);
      }
      case 2: {
        const unfinished = levels.filter(n => n !== 20);
        if (unfinished.length == 0) {
          return [];
        }
        const remaining = Object.entries(nft.professions).find(p => p[1] === unfinished[0]);
        if (!remaining) {
          return [];
        }
        const rId = GetProfessionId(remaining[0] as NFTProfession);
        return rId !== null ? [rId] : [];
      }
      default: {
        return [];
      }
    }
  };

  const getLevel = (): number => {
    let level = -1;
    if (!nft || !nft.professions) {
      return level;
    }
    let p: NFTProfession | null = null;
    if (options().length === 1) {
      p = GetProfessionFromId(options()[0]);
    } else if (value) {
      p = GetProfessionFromId(value.key);
    }
    if (p) {
      level = nft.professions[p];
    }
    return level;
  };

  const handleTraining = async () => {
    if (!data || !nft) {
      return;
    }
    let success = false;
    if (nft.training) {
      if (Date.parse(String(nft.training.completeAt)) > Date.now()) {
        success = await CancelTraining(nft, data);
      } else {
        success = await FinishTraining(nft, data);
      }
    } else if (options().length === 1) {
      success = await StartTraining(nft, options()[0], data);
    } else if (value) {
      success = await StartTraining(nft, value.key, data);
    }
  };

  const midTraining = nft && nft.training && Date.parse(String(nft.training.completeAt)) > Date.now();

  useEffect(() => {
    const updateTime = () => {
      if (!nft || !nft.training?.completeAt || timeRemaining === 'Complete') {
        return;
      }
      const t = Date.parse(String(nft.training.completeAt));
      const distance = t - Date.now();
      if (distance <= 0) {
        if (timeRemaining !== 'Complete') {
          setTimeRemaining('Complete');
          return;
        }
      }
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const pad = (n: number) => {
        if (n < 10) {
          return `0${n}`;
        }
        return n.toString();
      };
      const formatted = `${days ? pad(days) + ':' : ''}${days || hours ? pad(hours) + ':' : ''}${
        days || hours || minutes ? pad(minutes) + ':' : ''
      }${days || hours || minutes || seconds ? pad(seconds) : ''}`;
      if (timeRemaining !== formatted) {
        setTimeRemaining(formatted);
      }
    };
    const i = setInterval(updateTime, 500);
    return () => {
      clearInterval(i);
    };
  }, [timeRemaining]);

  const handleClose = () => {
    dispatch(setStakingSelectProfessionNft());
  };
  return (
    <DialogModal title={`${nft?.training ? 'Current' : 'Next'} Objective`} open={!!nft} onClose={handleClose}>
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={6} sx={{ textAlign: 'end', paddingRight: '1rem' }}>
          <Typography variant="h6">Objective:</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'start', paddingLeft: '1rem' }}>
          {nft?.training ? (
            <Typography variant="h6">{GetPrettyProfessionFromId(nft.training.skillId)}</Typography>
          ) : options().length === 1 ? (
            <Typography variant="h6">{GetPrettyProfessionFromId(options()[0])}</Typography>
          ) : (
            <CMPDropdown
              items={options().map((o, i) => ({
                key: o,
                text: GetPrettyProfessionFromId(options()[i]) as string,
              }))}
              selected={value}
              onSelect={setValue}
            />
          )}
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'end', paddingRight: '1rem' }}>
          <Typography variant="h6">Skill Level:</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'start', paddingLeft: '1rem' }}>
          <Typography variant="h6">
            {nft?.training
              ? `${nft.training.level - 1} -> ${nft.training.level}`
              : getLevel() < 0
              ? '--'
              : `${getLevel()} -> ${getLevel() + 1}`}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'end', paddingRight: '1rem' }}>
          <Typography variant="h6"> Training Time:</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'start', paddingLeft: '1rem' }}>
          <Typography variant="h6">
            {nft?.training
              ? `${time[nft.training.level - 1]} Hours`
              : getLevel() < 0
              ? '--'
              : `${time[getLevel()]} Hours`}
          </Typography>
        </Grid>
        {nft?.training && (
          <Grid item xs={6} sx={{ textAlign: 'end', paddingRight: '1rem' }}>
            <Typography variant="h6"> Time Remaining:</Typography>
          </Grid>
        )}
        {nft?.training && (
          <Grid item xs={6} sx={{ textAlign: 'start', paddingLeft: '1rem' }}>
            <Typography variant="h6">{timeRemaining}</Typography>
          </Grid>
        )}
        <Grid item xs={6} sx={{ textAlign: 'end', paddingRight: '1rem' }}>
          <Typography variant="h6">Cost:</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'start', paddingLeft: '1rem' }}>
          <Typography variant="h6">{getLevel() >= 0 || nft?.training ? `90 MAGICK` : '--'}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'end', paddingRight: '1rem', paddingBottom: '2rem' }}>
          <Typography variant="h6">Reward:</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'start', paddingLeft: '1rem', paddingBottom: '2rem' }}>
          <Typography variant="h6">{getLevel() >= 0 || nft?.training ? `+1 MAGICK/day` : '--'}</Typography>
        </Grid>
      </Grid>
      {midTraining && (
        <Typography sx={{ textAlign: 'center', fontWeight: 'bold', color: 'red' }}>
          WARNING: CANCELLING TRAINING DOES NOT RETURN FUNDS
        </Typography>
      )}
      <CMPButton
        fullWidth
        sx={{
          minHeight: 56,
          backgroundColor: midTraining ? 'red' : '',
          '&:hover': {
            backgroundColor: 'darkred',
          },
        }}
        disabled={!nft?.training && options().length > 1 && !value}
        onClick={handleTraining}
      >
        {nft?.training ? (midTraining ? 'Cancel' : 'Complete') : 'Start'} Training
      </CMPButton>
    </DialogModal>
  );
};

export default SelectProfessionDialog;
