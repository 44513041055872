import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSigner } from 'wagmi';
import { utils } from 'ethers';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid, Link,
  Stack,
  styled,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ButtonBorder from 'assets/images/button-border.svg';
import Divider from 'assets/images/divider.svg';
import ElfFemale from 'assets/images/nfts/elf-3511-crop.png';
import ElfMale from 'assets/images/nfts/elf-5039-crop.png';
import PriceBox from 'assets/images/pricebox.svg';
import CoinUSDC from 'assets/images/tokens/usdc.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectMintElves,
  selectMintElvesPending,
  selectMintElvesTicketsSelected,
  selectMintElvesToMint,
} from 'store/mint/hooks';
import { setMintElvesDetails, setMintElvesToMint } from 'store/mint/reducer';
import { BnToLocale } from 'utils/BigNumbers';
import { Buy } from 'services/Mint';
import Div from 'components/global/div';
import CMPImage from 'components/global/image';
import GetLogger from 'components/global/Logger';
import { newBalance } from "../../../store/wallet/reducer";
import ElvesMintReceiptModal from '../modal/ElvesMintReceiptModal';
import ArrowButton from './ArrowBtn';
import CosmicTicketStack from './CosmicTicketStack';

const StyledPayCard = styled(Div)(({ theme }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  padding: '11px 11px 18px 11px',
}));

const StyledPayCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#000',
  width: '100%',
  height: '100%',
}));

const StyledMintBtnContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: '14px 32px 21px 32px',
}));

const StyledMintBtn = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: 0,
  backgroundColor: '#000',
  color: theme.palette.golden,
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: 1,
  '&:disabled': {
    backgroundColor: '#00000020',
  },
  '&:hover': {
    backgroundColor: '#15120d',
  },
}));

const StyledMintInputContainer = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  border: `2px solid ${theme.palette.golden}`,
  overflow: 'hidden',
  display: 'flex',
  borderRadius: 8,
  margin: '5px auto 10px auto',
}));

const StyledMintInput = styled(TextField)(({ theme }) => ({
  '& .MuiInput-root': {
    '&:hover:before': {
      borderBottom: 0,
    },
    '& .MuiInput-input': {
      height: 32,
      width: 64,
      background: '#fff',
      color: '#000',
      textAlign: 'center',
      padding: 0,
    },
    '&::before': {
      borderBottom: 0,
    },
    '&::after': {
      borderBottom: 0,
    },
  },
}));

interface IProps {
  onConfirm: () => void;
}

const ElvesMint: React.FunctionComponent<IProps> = ({ onConfirm }) => {
  const log = GetLogger('Elves Mint Card');
  const { data } = useSigner();

  const { USDC, lMAGIC } = useAppSelector(state => state.wallet.tokens);
  const usdcBalance = USDC || newBalance()
  const lmagicBalance = lMAGIC || newBalance();
  const eTicketsSelected = useAppSelector(selectMintElvesTicketsSelected);
  const globalElves = useAppSelector(state => state.global.nfts.Elves);
  const [totalMinted, setTotalMinted] = useState<number>(0)

  useEffect(() => {
    if (!globalElves) {
      return;
    }
    const sorted = Object.values(globalElves).sort((a, b) => (a.tokenId - b.tokenId))
    const v = sorted[sorted.length - 1].tokenId;
    if (v !== totalMinted) {
      setTotalMinted(v);
    }
  }, [globalElves, totalMinted]);

  const pending = useAppSelector(selectMintElvesPending);
  const count = useAppSelector(selectMintElvesToMint);
  const elves = useAppSelector(selectMintElves);
  const dispatch = useAppDispatch();
  // log.debug('Elves Tickets in wallet:', eTickets);
  const [confirm, setConfirm] = useState(false);
  const totalTickets = useAppSelector(state => Object.values(state.wallet.items721.ElvesTicket || {}));
  const baseCost = useMemo(() => {
    if (elves.price.eq(0)) {
      return 95;
    }
    return Math.round(Number(utils.formatUnits(elves.price, '6')));
  }, [elves.price]);

  const hiddenSm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const theme = useTheme();

  const max = useMemo(() => {
    const balance = Number(utils.formatUnits(usdcBalance.balance.add(elves.credits), '6'))
    const baseMax = Math.floor(balance / baseCost);
    if (baseMax < 1) {
      return 0;
    }
    let discount = 0;
    Object.entries(eTicketsSelected).map(([d, c]) => {
      switch (d) {
        case '5%':
          discount += (baseCost * (0.05 * c)) / baseMax;
          break;
        case '10%':
          discount += (baseCost * (0.1 * c)) / baseMax;
          break;
        case '15%':
          discount += (baseCost * (0.15 * c)) / baseMax;
          break;
        case '20%':
          discount += (baseCost * (0.2 * c)) / baseMax;
          break;
      }
    });
    let totalMax = Math.floor(balance / (baseCost - discount));
    if (totalMax > 50) {
      totalMax = 50;
    }
    if (totalMax < count && totalMax > 0) {
      dispatch(setMintElvesToMint(totalMax));
    }
    return totalMax;
  }, [baseCost, usdcBalance, elves.credits]);

  const totalETicketsSelected = () => Object.values(eTicketsSelected).reduce((n, p) => n + p, 0);

  const getTicketByDiscount = (discount: number) => {
    const discountPercent = discount.toString() + '%';
    const tickets = totalTickets.filter(e => e.attributes.discount === discountPercent);
    if (tickets.length > 0) return true;
    else return false;
  };


  const handleMint = async () => {
    if (!data) {
      return;
    }
    const success = await Buy(data);
    if (success) {
      const fresh = { ticketsSelected: { '5%': 0, '10%': 0, '15%': 0, '20%': 0 }, toMint: 0 };
      dispatch(setMintElvesDetails(fresh));
      setConfirm(false);
      onConfirm();
    }
  };

  const increaseCount = () => {
    if (count < max) {
      dispatch(setMintElvesToMint(count + 1));
    }
  };

  const decreaseCount = () => {
    if (count > 0) {
      dispatch(setMintElvesToMint(count - 1));
    }
  };

  const setQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    const input = Number(e.target.value);
    if (input > max) {
      dispatch(setMintElvesToMint(max));
      return;
    }
    if (input < 1) {
      dispatch(setMintElvesToMint(1));
      return;
    }
    if (input < totalETicketsSelected()) {
      dispatch(setMintElvesToMint(totalETicketsSelected()));
      return;
    }
    dispatch(setMintElvesToMint(Math.round(input)));
  };

  return (
    <Card
      sx={{
        borderRadius: '16px',
        background: theme.palette.mintBg,
        height: '100%',
      }}
    >
      <CardContent sx={{ padding: 5, height: '100%' }}>
        <Grid container spacing={8} sx={{ alignItems: 'center', height: '100%', marginBottom: 2 }}>
          <Grid item xs={12} md={3} hidden={hiddenSm}>
            <CMPImage
              asset={{
                src: ElfMale,
                alt: 'male',
              }}
              width="100%"
              height="100%"
              style={{ maxWidth: 260 }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack direction="column" spacing={5} sx={{ width: '100%' }}>
              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>Elves Mint</Typography>
              <CMPImage
                asset={{
                  src: Divider,
                  alt: 'devider',
                }}
                style={{ maxWidth: 380, marginLeft: 'auto', marginRight: 'auto' }}
              />
              <Typography sx={{ fontSize: 18, fontWeight: 'medium' }}>
                Total Elves Minted: {totalMinted === 0 ? '???' : totalMinted.toLocaleString()}/{(10000).toLocaleString()}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ position: 'relative', maxWidth: 212 }}>
                  <CMPImage
                    asset={{
                      src: PriceBox,
                      alt: 'pricebox',
                    }}
                    width="100%"
                  />
                  <StyledPayCard>
                    <StyledPayCardContent>
                      <Typography sx={{ fontSize: 18, fontWeight: 'medium', color: theme.palette.golden }}>
                        Price
                      </Typography>
                      <CMPImage
                        asset={{
                          src: CoinUSDC,
                          alt: 'usdc',
                        }}
                        width={50}
                        height={50}
                        style={{ margin: '10px 0' }}
                      />
                      <Typography sx={{ fontSize: 18, fontWeight: 'medium', lineHeight: 1.1 }}>
                        <label style={{ color: theme.palette.golden }}>USDC</label>
                        <br />
                        {baseCost}
                      </Typography>
                    </StyledPayCardContent>
                  </StyledPayCard>
                </Box>
                <Typography sx={{ fontSize: 16 }}>Balance: {BnToLocale(usdcBalance.balance, '6')}</Typography>
                {elves.credits.gt(0) && (
                  <Typography sx={{ fontSize: 16 }}>Credits: {BnToLocale(elves.credits, '6')}</Typography>
                )}
              </Box>
              {totalTickets.length > 0 && (
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="center"
                  alignItems="center"
                  spacing={1.25}
                >
                  <Typography sx={{ fontSize: 18, fontWeight: 'bold', width: 200 }}>
                    Select Cosmic <br /> Tickets To Use
                  </Typography>
                  <Grid container spacing={1.5} sx={{ justifyContent: 'center' }}>
                    {[5, 10, 15, 20].map(n => {
                      if (getTicketByDiscount(n))
                        return (
                          <Grid item xs={6} sm={3} key={n}>
                            <CosmicTicketStack discount={`${n}%`} max={max} totalSelected={totalETicketsSelected()} />
                          </Grid>
                        );
                    })}
                  </Grid>
                </Stack>
              )}
              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" spacing={4.5}>
                <Div>
                  <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Quantity:</Typography>
                  <StyledMintInputContainer>
                    <StyledMintInput
                      size="small"
                      type="number"
                      value={count.toString()}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        min: 0,
                        max: 50,
                        step: 1,
                      }}
                      variant="standard"
                      onChange={setQuantity}
                    />
                    <Stack
                      direction="column"
                      sx={{ alignItems: 'center', justifyContent: 'center', width: 24, height: 32 }}
                    >
                      <ArrowButton
                        direction="up"
                        onClick={increaseCount}
                        disabled={count >= max}
                        style={{ borderWidth: '0 0 1px 0' }}
                      />
                      <ArrowButton
                        direction="down"
                        onClick={decreaseCount}
                        disabled={count === 1 || count === totalETicketsSelected()}
                        style={{ borderWidth: '1px 0 0 0' }}
                      />
                    </Stack>
                  </StyledMintInputContainer>
                  <Typography sx={{ fontSize: 16, userSelect: 'none' }}>
                    {max === 0
                      ? `Not enough USDC`
                      : `Max ${max}`}
                  </Typography>
                </Div>
                <Box sx={{ position: 'relative' }}>
                  <CMPImage
                    asset={{
                      src: ButtonBorder,
                      alt: 'button border',
                    }}
                    width="240px"
                  />
                  <StyledMintBtnContainer>
                    <StyledMintBtn
                      onClick={() => {
                        setConfirm(true);
                      }}
                      disabled={count === 0 || count > max || pending.length > 0}
                    >
                      BUY
                    </StyledMintBtn>
                  </StyledMintBtnContainer>
                </Box>
              </Stack>
              <Div>
                <Typography sx={{ fontSize: 14, fontWeight: 'light', wordBreak: 'break-word' }}>
                  Learn more about Elves minting rewards and incentives{' '}
                  <Link
                    href="https://medium.com/@thecosmicuniverse/new-elves-reawakening-nft-mint-incentives-8f2d48a65abb"
                  >
                    here
                  </Link>
                  <br />
                  <br />
                  <b>Elves Smart Contract Address:</b> 0x9a337A6F883De95f49e05CD7D801d475a40a9C70
                </Typography>
              </Div>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} hidden={hiddenSm}>
            <CMPImage
              asset={{
                src: ElfFemale,
                alt: 'female',
              }}
              width="100%"
              height="100%"
              style={{ maxWidth: 260 }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <ElvesMintReceiptModal open={confirm} onMint={handleMint} onClose={() => setConfirm(false)} />
    </Card>
  );
};

export default ElvesMint;
