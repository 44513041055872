import React, { EventHandler, FC, CSSProperties } from 'react';
import { Box, styled } from '@mui/material';
import ArrowDown from 'assets/images/arrow-down.svg';
import ArrowUp from 'assets/images/arrow-up.svg';
import CMPImage from 'components/global/image';

const StyledArrowButton = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: '#231f20',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: '1px solid',
  borderColor: theme.palette.golden,
}));

const directionImages = {
  up: ArrowUp,
  down: ArrowDown,
};

interface Props {
  disabled?: boolean;
  direction: 'up' | 'down';
  onClick?: EventHandler<any>;
  style?: CSSProperties;
}

const ArrowButton: FC<Props> = ({ disabled, direction, onClick, style }) => {
  return (
    <StyledArrowButton
      sx={{
        opacity: disabled ? 0.4 : 1,
        ...(disabled ? { pointerEvents: 'none' } : {}),
        borderRadius: direction === 'up' ? '5px 5px 0 0' : '0 0 5px 5px',
        marginBottom: direction === 'up' ? '-1px' : '',
      }}
      style={style}
      onClick={onClick}
    >
      <CMPImage asset={{ src: directionImages[direction], alt: direction }} width="10" />
    </StyledArrowButton>
  );
};

export default ArrowButton;
