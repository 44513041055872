import React from 'react';
import { NFT } from 'models/NFT';

interface IActionButtonProps {
  matches?: boolean;
  data: NFT;
  onRefresh?: () => void;
  withTopMargin?: boolean;
  roundedButton?: boolean;
}

const ActionButton: React.FunctionComponent<IActionButtonProps> = ({
  data,
  matches,
  onRefresh,
  withTopMargin = true,
  roundedButton = true,
}) => {
  return <></>;
};
export default ActionButton;
