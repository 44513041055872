import { Card, styled } from '@mui/material';

const CMPCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 340,
  borderBottomLeftRadius: '25px',
  borderBottomRightRadius: '25px',
  borderTopLeftRadius: '38px',
  borderTopRightRadius: '38px',
  background: theme.palette.filterBg,
  border: `2px solid ${theme.palette.golden}`,
  overflow: 'hidden',
  transitionDuration: '150ms',
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',
  ['&:hover']: {
    transform: `scale(1.05)`
  },
  ['& .MuiCardMedia-root']: {
    objectFit: 'cover',
    padding: 0,
    background: theme.palette.filterBg,

  },
  ['& .MuiCardContent-root']: {
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
    borderTopLeftRadius: 50,
    borderTopRightRadius: '50px',
    borderTop: 0,
    borderRight: 1,
    borderLeft: 1,
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.mode === 'light' ? theme.palette.divider : theme.palette.filterBg,
  },
}));
export default CMPCard;
