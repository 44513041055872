import React, { FC } from 'react';
import { CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';
import SummaryCard from '../SummaryCard';

const TotalsSummaryCard: FC = () => {
  const allWiz = useAppSelector(state => Object.values(state.wallet.nfts.Wizards || {}));
  const stakedWiz = useAppSelector(state => Object.values(state.staking.staked) || {});

  const wiz = stakedWiz.concat(allWiz).filter((v, i, s) => i === s.findIndex(t => t.id === v.id));
  const unlocked = wiz.reduce((p, v) => (v.staking?.unlocked ? p + 1 : p), 0);
  const staked = wiz.reduce((p, v) => (v.staking?.staked ? p + 1 : p), 0);
  const training = wiz.reduce((p, v) => (v.training ? p + 1 : p), 0);
  const rows = [
    {
      category: 'Unlocked',
      amount: unlocked,
      percent: ((unlocked / wiz.length) * 100).toFixed(2),
    },
    {
      category: 'Staked',
      amount: `${staked} / ${wiz.length}`,
      percent: ((staked / wiz.length) * 100).toFixed(2),
    },
    {
      category: 'Training',
      amount: `${training} / ${wiz.length}`,
      percent: ((training / wiz.length) * 100).toFixed(2),
    },
  ];
  return (
    <SummaryCard>
      <CardHeader title="Totals" sx={{ textAlign: 'center', paddingBottom: 0 }} />
      <CardContent sx={{ marginBottom: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="right">Percent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <TableRow key={i} component="th" scope="row">
                <TableCell>{r.category}</TableCell>
                <TableCell align="center">{r.amount}</TableCell>
                <TableCell align="right">{r.percent}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </SummaryCard>
  );
};

export default TotalsSummaryCard;
