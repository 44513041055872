import React from 'react';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import Strings from 'utils/Strings';
import { NftSectionContainer } from 'pages/nft/_styles';
import Div from 'components/global/div';
import { NFTAttributes } from '../../../../../models/NFT';
import NftAttribute from './attribute';

interface IProps {
  subCategory: string;
  attributes: NFTAttributes;
}

const NftAttributes: React.FunctionComponent<IProps> = ({ subCategory, attributes }) => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  if (!attributes || Object.keys(attributes).length === 0) {
    return null;
  }

  return (
    <NftSectionContainer>
      <Typography variant="h5" fontWeight="bold">
        {Strings.attrAndRarities}
      </Typography>
      <Div className="d-flex flex-wrap" sx={{ width: '100%' }}>
        {Object.entries(attributes).map(([attribute, value]) => (
          <NftAttribute
            key={attribute}
            rootSx={{
              width: matches ? '50%' : '25%',
              mt: 4,
              justifyContent: 'flex-start',
            }}
            subCategory={subCategory}
            gender={attributes.gender || attributes.Gender}
            title={value}
            value={attribute}
          />
        ))}
      </Div>
    </NftSectionContainer>
  );
};

export default NftAttributes;
