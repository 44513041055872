import React, { FC } from 'react';
import { utils } from 'ethers';
import { Avatar, Grid, Theme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import CosmicUSDCPairPng from 'assets/images/tokens/COSMIC-USDC_Pair-512.png';
import MagicAvaxPairPng from 'assets/images/tokens/MAGIC-AVAX-GFX.png';
import MagicCosmicPairPng from 'assets/images/tokens/MAGIC-COSMIC_Pair-512.png';
import MagicUSDCPairPng from 'assets/images/tokens/MAGIC-USDC_Pair-512.png';
import { Pool } from '../../../../store/farm/types';
import { CleanLocaleBN } from '../../functions';

interface PoolIconProps {
  name: string;
}

const PoolIcon: FC<PoolIconProps> = ({ name }) => {
  switch (name) {
    case 'MAGICK_AVAX': {
      return (
        <Avatar sx={{ width: '128px', height: '128px', borderRadius: 0, marginBottom: 2 }} src={MagicAvaxPairPng} />
      );
    }
    default:
      return <Avatar
        sx={{ width: '128px', height: '128px', borderRadius: 0, marginBottom: 2 }}
        src={MagicUSDCPairPng}
      />;
  }
};

interface PoolSummaryProps {
  pool: Pool;
}

const PoolSummary: FC<PoolSummaryProps> = ({ pool }) => {
  const hiddenLg = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'), {
    noSsr: true,
  });
  const hiddenMd = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const hiddenSm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  return (
    <Grid
      container
      sx={{
        width: 1,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <PoolIcon name={pool.name} />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Typography variant="h5" color="primary">
          {pool.cleanName}
        </Typography>
      </Grid>
      <Grid item xs={4} md={3} lg={2} hidden={hiddenSm}>
        <Typography color="primary">Earned</Typography>
        <Typography variant="h6">{CleanLocaleBN(pool.earned)}</Typography>
      </Grid>
      <Grid item xs={3} lg={2} hidden={pool.apr === 0}>
        <Typography color="primary">APR</Typography>
        <Typography variant="h6">{pool.apr.toFixed(2)}%</Typography>
      </Grid>
      <Grid item xs={2} hidden={hiddenLg}>
        <Typography color="primary">Liquidity</Typography>
        <Typography variant="h6">${pool.liquidity.toLocaleString()}</Typography>
      </Grid>
      <Grid item xs={2} hidden={hiddenLg || pool.emissionRate.eq(0)}>
        <Typography color="primary">Emission Share</Typography>
        <Typography variant="h6">{Number(utils.formatEther(pool.emissionRate)) * 200}%</Typography>
      </Grid>
    </Grid>
  );
};

export default PoolSummary;
