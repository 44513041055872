import { PaletteMode } from "@mui/material";
import store from 'store';
import Utils from 'libs/Utils';
import LocalStorageKey from 'constants/LocalStorageKey';

export interface IAppConfig {
  theme: PaletteMode;
}

export default class AppConfig {
  theme: PaletteMode;
  constructor(props?: IAppConfig) {
    this.theme = (props && props.theme) || store.getState().app.appTheme;
  }

  getFromLocalStorage(): IAppConfig | null {
    const appConfig = localStorage.getItem(LocalStorageKey.AppConfig);
    if (appConfig) {
      return JSON.parse(appConfig) as IAppConfig;
    }
    return null;
  }

  writeToLocalStorage(): void {
    window.localStorage.setItem(
      LocalStorageKey.AppConfig,
      JSON.stringify({
        theme: this.theme,
      }),
    );
  }

  readFromLocalStorage(): AppConfig | null {
    const appConfig = this.getFromLocalStorage();
    if (appConfig) {
      const { theme } = appConfig;
      if (theme) {
        this.theme = theme.toLowerCase() as PaletteMode;
      }
      return this;
    }
    this.writeToLocalStorage();
    return null;
  }

  setToStore(): void {
    Utils.changeTheme(this.theme);
  }
}
