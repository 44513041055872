import React, { FC } from 'react';
import { useAccount } from 'wagmi';
import { CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';
import SummaryCard from '../SummaryCard';

const MetricsSummaryCard: FC = () => {
  const wiz = useAppSelector(state => Object.values(state.wallet.nfts.Wizards || {}));
  const wizLevels = wiz.map(w => Object.values(w.professions || {}).reduce((p, v) => p + v, 0) || 0);
  const totalLevels = wizLevels.reduce((p, v) => p + v, 0);
  const wizUnlocked = wiz.reduce((p, v) => (v.staking?.unlocked ? p + 1 : p), 0);
  const rows = [
    {
      category: 'MAGICK Spent',
      amount: (totalLevels * 90 + wizUnlocked * 20).toLocaleString(),
    },
    {
      category: 'Levels Gained',
      amount: `${totalLevels} of ${wizLevels.length * 40}`,
    },
    {
      category: 'Levels Remaining',
      amount: `${wizLevels.length * 40 - totalLevels} of ${wizLevels.length * 40}`,
    },
  ];
  return (
    <SummaryCard>
      <CardHeader title="Metrics" sx={{ textAlign: 'center', paddingBottom: 0 }} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <TableRow key={i} component="th" scope="row">
                <TableCell>{r.category}</TableCell>
                <TableCell align="right">{r.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </SummaryCard>
  );
};

export default MetricsSummaryCard;
