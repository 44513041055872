import { Types } from "connectkit";
import React, { FC } from "react";
import { useAppSelector } from "../../../../store/hooks";
import store from 'store'
const ProfileAvatar: FC<Types.CustomAvatarProps> = ({ address, ensImage, ensName, size, radius }) => {
  const { Wizards, Elves } = store.getState().wallet.nfts

  const elfAvatar = Object.values(Elves || {}).length > 0 ? Object.values(Elves)[0].image : undefined;
  const wizardAvatar = Object.values(Wizards || {}).length > 0 ? Object.values(Wizards)[0].image : undefined;
  const avatar = elfAvatar || wizardAvatar || ensImage;
  return (
    <div
      style={{
        overflow: "hidden",
        borderRadius: radius,
        height: size,
        width: size,
        background: `#000`, // your function here
      }}
    >
      {avatar && <img src={avatar} alt={ensName ?? address} width="100%" height="100%" />}
    </div>
  );
};

export default ProfileAvatar;