import React, { ChangeEvent, FC, useState } from 'react';
import { useAccount, useSigner } from 'wagmi';
import { BigNumber, utils } from 'ethers';
import { Alert, Grid, TextField } from '@mui/material';
import { StakeLP } from 'services/Farms';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import { Pool } from '../../../../store/farm/types';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  pool: Pool;
}

const AddStakeModal: FC<Props> = ({ open, setOpen, pool }) => {
  const { data } = useSigner();
  const [amount, setAmount] = useState<string>('0.0');
  const handleStake = async () => {
    if (!data) {
      return;
    }
    await StakeLP(pool.pid, pool.token, amount, data);
  };
  return (
    <DialogModal title={`Stake ${pool?.cleanName}`} open={open} onClose={() => setOpen(false)}>
      <Alert severity="info" sx={{ justifyContent: 'center', marginBottom: '0.25rem' }}>
        Deposit Fee: {pool.depFee && pool.depFee.toNumber()}%
      </Alert>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Quantity"
            variant="standard"
            value={amount}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setAmount(event.target.value || '0.0')}
            type="number"
            inputProps={{
              step: 0.001,
              max: utils.formatEther(pool?.remainBalance || BigNumber.from(0)),
              min: '0.0',
            }}
            helperText={`Balance: ${utils.formatEther(pool?.remainBalance || BigNumber.from(0))}`}
          />
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CMPButton onClick={() => setAmount(utils.formatEther(pool.remainBalance.div(2)))}>½</CMPButton>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CMPButton onClick={() => setAmount(utils.formatEther(pool.remainBalance))}>Max</CMPButton>
        </Grid>

        <Grid item xs={12}>
          <CMPButton fullWidth disabled={utils.parseEther(amount).eq(0)} onClick={handleStake}>
            Stake
          </CMPButton>
        </Grid>
      </Grid>
    </DialogModal>
  );
};

export default AddStakeModal;
