import { styled } from '@mui/material';

export const classes = {
  logo: 'logo',
};

const CMPSidebar = styled('nav')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: 76,
  zIndex: 1000,
  background: '#000000',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
    top: 0,
    left: 0,
    bottom: 0,
    width: 84,
    height: '100%',
    background: '#000000',
  },
  [`& .${classes.logo}`]: {
    position: 'absolute',
    top: 16,
  },
}));
export default CMPSidebar;
