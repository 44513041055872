import React, { FC } from 'react';
import DialogModal from 'components/global/dialog-modal';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SwapIFrameModal: FC<Props> = ({ open, setOpen }) => {
  const src = `https://traderjoe.xyz/trade`;
  return (
    <DialogModal title={`Swap`} open={open} onClose={() => setOpen(false)}>
      <iframe
        src={src}
        height="660px"
        width="100%"
        style={{
          border: 0,
          margin: '0 auto',
          display: 'block',
          borderRadius: '10px',
          maxWidth: '600px',
          minWidth: '300px',
        }}
      />
    </DialogModal>
  );
};

export default SwapIFrameModal;
