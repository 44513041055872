import { useState } from 'react';
import { NFTState } from 'store/wallet/types';

function usePagination(data: NFTState, itemsPerPage: number) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(Object.keys(data).length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;

    const newData: NFTState = {};
    Object.entries(data).map(([key, item], idx) => {
      if (idx >= begin && idx < end) {
        newData[key] = item;
      }
    });
    return newData;
  }

  function next() {
    setCurrentPage(Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default usePagination;
