import React from 'react';
import { CustomIconProps, setColor } from '..';

const NothingFoundIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg id="box-search" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 48 48">
      <path id="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0" />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M32.4,9.88a2.9,2.9,0,0,1-1.5,2.56l-3.48,1.88L24.46,15.9l-6.14,3.32a4.431,4.431,0,0,1-4.24,0L1.5,12.44a2.935,2.935,0,0,1,0-5.12L5.44,5.2,8.58,3.5,14.08.54a4.431,4.431,0,0,1,4.24,0L30.9,7.32A2.9,2.9,0,0,1,32.4,9.88Z"
        transform="translate(7.8 4)"
        fill={iconColor}
        opacity="0.4"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M15.86,6.171,4.16.311a2.853,2.853,0,0,0-2.8.12A2.826,2.826,0,0,0,0,2.871v11.06a5,5,0,0,0,2.78,4.5l11.7,5.84a2.864,2.864,0,0,0,1.28.3,2.729,2.729,0,0,0,1.5-.44,2.826,2.826,0,0,0,1.36-2.44V10.631A4.926,4.926,0,0,0,15.86,6.171Z"
        transform="translate(3.94 19.409)"
        fill={iconColor}
        opacity="0.4"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M18.64,2.877v11.06a4.987,4.987,0,0,1-2.78,4.48l-11.7,5.86A2.886,2.886,0,0,1,0,21.7V10.657a5,5,0,0,1,2.78-4.5l4.3-2.14,3-1.5,4.4-2.2a2.8,2.8,0,0,1,2.8.12A2.87,2.87,0,0,1,18.64,2.877Z"
        transform="translate(25.42 19.423)"
        fill={iconColor}
        opacity="0.4"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M15.54,13.34l-1.52-1.52a7.513,7.513,0,0,0,1.26-4.18,7.64,7.64,0,1,0-7.64,7.64,7.513,7.513,0,0,0,4.18-1.26l1.52,1.52a1.556,1.556,0,1,0,2.2-2.2Z"
        transform="translate(29.66 29.94)"
        fill={iconColor}
      />
    </svg>
  );
};
export default NothingFoundIcon;
