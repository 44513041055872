// prettier-ignore
const attributes: { [index: string]: { [index: string]: { [index: string]: number } } } =  {
  'Male': {
    'skin tone': {
      'Bronze': 40,
      'Light Gold': 35,
      'Silver': 21,
      'Indigo': 3,
      'Pastel Blue': 1,
    },
    'headwear': {
      'Arcana': 20,
      'Blue-Gray Arcana': 15,
      'Fancy Silver': 12.5,
      'Ancient Steel': 12.3,
      'None': 10,
      'Yellow V-Style': 10,
      'Blue Arcana': 8,
      'Braided Headband': 7,
      'Forest Crown': 5,
      'Eggplant V-Style': 0.2,
    },
    'hair': {
      'Classic Blond': 15,
      'Classic Dark Brown': 15,
      'Classic White': 15,
      'White Manbun': 11,
      'Blond': 7,
      'Bluish White Male': 7,
      'Black Manbun': 6,
      'Blond Manbun': 6,
      'Short Blond': 4,
      'Long Silver': 3,
      'Short White': 3,
      'White': 3,
      'Long Manbun': 2,
      'Long Black Male': 0.5,
      'Long Blond Male': 0.5,
      'Long Silver Male': 0.5,
      'Short Silver': 0.5,
      'Sterling Silver Male': 0.5,
      'Short White Male': 0.3,
      'Short Blue Male': 0.2,
    },
    'eyebrows': {
      'Black': 30,
      'White': 30,
      'Silver': 10,
      'Gold': 10,
      'Gray': 6.3,
      'Light Blue': 5,
      'Brown': 4,
      'Light Brown': 3,
      'Amber Red': 1,
      'Dark Indigo': 0.3,
      'Light Indigo': 0.2,
      'Purple': 0.2,
    },
    'eyes': {
      'Silver': 14.3,
      'Orange on Blue': 14,
      'Spice Blue': 13,
      'Violet': 13,
      'Brown': 10,
      'Green': 10,
      'Deep Blue': 8,
      'Light Blue': 8,
      'Light Green': 8,
      'White on Black': 1,
      'Blue on Black': 0.5,
      'Green on Black': 0.2,
    },
    'ears': {
      'Short': 70,
      'Medium': 25,
      'Long': 5,
    },
    'mouth': {
      'Brown': 25,
      'Dark Blue': 25,
      'Gray': 25,
      'Neutral': 10,
      'Orange': 10,
      'Purple-Gray': 4,
      'Light Blue': 0.5,
      'Teal': 0.3,
      'Cherry': 0.2,
    },
    'attire': {
      'Distinguished Forester': 12,
      'Vanilla': 12,
      'Aqua Ambassador': 11,
      'Forest Dignitary': 11,
      'Slate': 10.5,
      'Elegant': 9,
      'Elegant Emissary': 9,
      'Elf Ambassador': 7,
      'Royal Dignitary ': 6,
      'Ceremonial Royal': 5.5,
      'Royal Chic': 4,
      'Fancy': 3,
    },
    'jewelry': {
      'None': 50,
      'Trilogy Amulet': 20,
      'Golden Bracelets': 15,
      'Fancy Bracelets': 12,
      'Prime Trilogy Amulet': 3,
    },
    'staff': {
      'Forest Harnesser': 33,
      'Wrapped Amethyst': 19,
      'Winged Glory': 16,
      'Wrapped Ivory': 15,
      'Arctic': 13,
      'Solar Staff': 4,
    }
  },
  'Female': {
    'skin tone': {
      'Bronze': 40,
      'Light Gold': 35,
      'Silver': 21,
      'Indigo': 3,
      'Pastel Blue': 1,
    },
    'headwear': {
      'Silver Tiara': 15,
      'Fancy Silver': 15,
      'Wavy Jeweled Tiara': 12,
      'Golden Tiara': 10,
      'Silver Wavy Jeweled Tiara': 10,
      'Silver Jeweled Tiara': 6,
      'Jeweled Tiara': 5.5,
      'Phoenix Tiara': 5,
      'Silver Cosmic Tiara': 5,
      'Ornate Jeweled Tiara': 4,
      'Cosmic Tiara': 3,
      'Forest Crown': 3,
      'Braided Headband': 2,
      'Feather Headband': 2,
      'Golden Lotus Tiara': 1,
      'Silver Phoenix Tiara': 1,
      'Silver Ornate Jeweled Tiara': 0.5,
    },
    'hair': {
      'Braided Blonde': 15,
      'Braided Dark Brown': 15,
      'Braided Platinum Blonde': 15,
      'Blonde Bun': 13,
      'Black Bun': 10,
      'White Bun': 10,
      'Short Blue': 5.5,
      'Short White': 5.5,
      'Gold': 4,
      'Bluish White': 2,
      'Long Blue': 1,
      'Sterling Silver': 1,
      'White': 1,
      'Auburn': 0.5,
      'Long Black': 0.3,
      'Long Blonde': 0.3,
      'Platinum Blonde': 0.3,
      'Blonde': 0.2,
      'Long Silver': 0.2,
      'Silver': 0.2,
    },
    'eyebrows': {
      'White': 14,
      'Light Blue': 14,
      'Black': 11,
      'Gold': 11,
      'Silver': 11,
      'Light Brown': 11,
      'Dark Indigo': 8.5,
      'Gray': 6,
      'Brown': 4,
      'Light Indigo': 3.5,
      'Amber Red': 3,
      'Purple': 3,
    },
    'eyes': {
      'Silver': 14.3,
      'Orange on Blue': 14,
      'Spice Blue': 13,
      'Violet': 13,
      'Brown': 10,
      'Green': 10,
      'Deep Blue': 8,
      'Light Blue': 8,
      'Light Green': 8,
      'White on Black': 1,
      'Blue on Black': 0.5,
      'Green on Black': 0.2,
    },
    'ears': {
      'Short': 70,
      'Medium': 25,
      'Long': 5,
    },
    'mouth': {
      'Purple': 15,
      'Pink': 14,
      'Gold': 12,
      'Bronze': 11,
      'Deep Blue': 10,
      'Silver': 10,
      'Red': 9,
      'Black': 8,
      'Metallic Purple': 8,
      'Violet': 3,
    },
    'attire': {
      'Teal and Gold': 9,
      'Blue and White': 8,
      'Silver': 8,
      'Silver Teal': 8,
      'Sky Blue': 8,
      'Village Outfit': 8,
      'Light Purple': 7,
      'Silver Toga': 7,
      'White and Gold': 7,
      'Pink Shirt': 6,
      'Ivory Toga': 5.5,
      'Royal Ambassador': 5.5,
      'Red Toga': 3,
      'Turquoise and Gold': 3,
      'Green and Gold': 2,
      'White': 2,
      'Elegant White': 1.5,
      'Dark Blue and Yellow': 1,
      'Natural Nature': 0.2,
      'Royal Priestess': 0.2,
      'Forest Green': 0.1,
    },
    'jewelry': {
      'None': 50,
      'Short Female Cuffs': 10,
      'Sophisticated Necklace': 10,
      'Fire Amulet': 9,
      'Golden Bracelet': 5.5,
      'Long Female Cuffs': 5.5,
      'Fancy Necklace': 3,
      'Rune Necklace': 2.5,
      'Handmade Bracelet': 2,
      'Fierce Necklace': 1,
      'Thin Golden Bracelet': 1,
      'Engraved Bracelet': 0.5,
    },
    'staff': {
      'Forest Walker': 30,
      'Magenta Fire': 20,
      'Nature Nurturer': 19,
      'Cosmic Manifester': 18,
      'Angel Aura': 10,
      'Channeled Gaia': 3,
    }
  }
}

export default attributes;
