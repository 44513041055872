import { SyntheticEvent } from 'react';
import * as React from 'react';
import {
  Avatar,
  CardActions,
  CardContent,
  CardContentProps,
  CardMedia,
  CardMediaProps,
  CardProps,
  TypographyProps
} from "@mui/material";
import { CardBody, CardDescription, CardProfessions, IAsset } from 'models/@types';
import CMPCardContent from 'components/global/card-content';
import { Chain, useNetwork } from "wagmi";
import { ChainList } from "../../../constants/wagmi/chains";
import CMPCard from './_styles';

interface IProps extends CardProps {
  asset: IAsset;
  title: string;
  name?: string;
  rank?: number;
  balance?: number;
  body?: CardBody;
  description?: CardDescription;
  contentProps?: CardContentProps;
  mediaProps?: CardMediaProps<'img'>;
  titleProps?: TypographyProps<'div'>;
  action?: React.ReactNode;
  professions?: CardProfessions;
  largePhoto?: boolean;
  chain?: Chain;
}

const Card: React.FunctionComponent<IProps> = ({
  asset,
  title,
  name,
  body,
  description,
  contentProps,
  mediaProps,
  titleProps,
  rank,
  balance,
  action,
  professions,
  largePhoto,
  chain,
  ...props
}) => {

  const matchedChain = ChainList.find(c => c.id === chain?.id)
  const onElvesError = (event: SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    const gender = title.split(' ')[1];
    if (img.src.includes('/elves/')) {
      img.src = `https://images.cosmicuniverse.io/elves/${gender.toString()}`;
    }
  };

  return (
    <CMPCard {...props} sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {matchedChain && <Avatar src={matchedChain.logo} sx={{ position: 'absolute', top: 8, right: 8 }} />}

      <CardMedia
        component={asset.src.endsWith('.mp4') ? 'video' : 'img'}
        image={asset.src}
        alt={asset.alt}
        {...mediaProps}
        autoPlay
        sx={{ borderTopLeftRadius: '24px', borderTopRightRadius: '24px', maxHeight: largePhoto ? '500' : '300', backgroundColor: 'black' }}
        onError={onElvesError}
      />
      <CardContent
        sx={{
          padding: '12px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          paddingBottom: 0,
          borderBottom: 0,
        }}
        {...contentProps}
      >
        <CMPCardContent
          body={body}
          description={description}
          title={title}
          name={name}
          titleProps={titleProps}
          rank={rank}
          balance={balance}
          professions={professions}
        />
      </CardContent>
      {action && <CardActions sx={{ padding: 0, paddingBottom: 1, marginTop: 'auto' }}>{action}</CardActions>}
    </CMPCard>
  );
};

export default Card;
