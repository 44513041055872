import { configureChains, createClient } from 'wagmi';

import type { Chain } from 'wagmi';
import { ReactNode } from 'react';
import { providers } from 'ethers';
import { Provider, setMulticallAddress } from 'ethers-multicall';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { LedgerConnector } from 'wagmi/connectors/ledger';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { getDefaultClient } from 'connectkit';
import { avalanche as Avalanche, avalancheFuji as AvalancheFuji } from 'wagmi/chains';
type NativeCurrency = {
  decimals: number;
  name: string;
  symbol: string;
  logo: ReactNode;
};


const bobaAvax = {
  id: 43_288,
  name: 'BobaAvax',
  logo: 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/token/boba.jpg',
  network: 'boba',
  nativeCurrency: {
    decimals: 18,
    name: 'Boba',
    symbol: 'BOBA',
    logo: 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/token/boba.jpg',
  },
  rpcUrls: {
    default: {
      http: ['https://replica.avax.boba.network']
    },
  },
  blockExplorers: {
    default: { name: 'BlockExplorer', url: 'https://blockexplorer.avax.boba.network/' },
  },
  testnet: false,
  contracts: {
    multicall3: {
      address: "0xD4B6292323C6332197e4aAb674a78C2608a6ee62",
      blockCreated: 41826
    }
  }
} as const;

const avalanche = {
  ...Avalanche,
  logo: "https://cryptologos.cc/logos/avalanche-avax-logo.png",
  rpcUrls: {
    default: {
      http: ['https://nd-222-006-991.p2pify.com/23c9178be0e26e0573761002de8bfaa8/ext/bc/C/rpc']
    }
  }
} as const;

const avalancheFuji = {
  ...AvalancheFuji,
  logo: "https://cryptologos.cc/logos/avalanche-avax-logo.png",
  rpcUrls: {
    default: {
      http: ['https://api.avax-test.network/ext/bc/C/rpc']
    }
  }
} as const;

const Chains = { avalanche, bobaAvax, avalancheFuji };

const SupportedChainIDs = Object.values(Chains).map(c => c.id)
export type SupportedChainID = typeof SupportedChainIDs[number]

export const ChainList = Object.values(Chains);

export const networkBaseProviders = Object.values(Chains).reduce((o, chain) => {
  o[chain.id] = new providers.StaticJsonRpcProvider(chain.rpcUrls.default.http[0]);
  return o;
}, {} as { [chainId: number]: providers.StaticJsonRpcProvider });

export const networkProviders: { [p: number]: Provider } = Object.values(Chains).reduce((o, chain) => {
  setMulticallAddress(Chains.bobaAvax.id, '0x03A37A09be3E90bE403e263238c1aCb14a341DEf');
  o[chain.id] = new Provider(new providers.JsonRpcProvider(chain.rpcUrls.default.http[0]), chain.id);
  return o;
}, {} as { [chainId: number]: Provider });

const { chains, provider } = configureChains(Object.values(Chains) as any, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (
        !Object.values(Chains)
          .map(c => c.id)
          .includes(chain.id)
      )
        return null;
      return {
        http: chain.rpcUrls.default.http[0]
      };
    },
    weight: 1,
  }),
  publicProvider({ weight: 2 }),
], { pollingInterval: 5_000 });

const appName = 'Cosmic Universe';

export const client = createClient(
  getDefaultClient({
    autoConnect: true,
    appName,
    chains,
    connectors: [
      new InjectedConnector({
        chains,
        options: {
          name: 'Browser Wallet',
          shimDisconnect: true,
        },
      }),
      new MetaMaskConnector({ chains }),
      new LedgerConnector({ chains }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName,
          headlessMode: true,
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: false,
        },
      }),
    ],
    provider,
  }),
);

export default Chains;
