import React from 'react';
import useTitle from 'hooks/useTitle';
import ProfileContainer from './_style';
import ProfileItems from './components/profile-items';
import ProfileSummary from './components/profile-summary';

const ProfilePage = () => {
  useTitle({ title: 'Profile' });

  return (
    <ProfileContainer>
      <ProfileSummary />
      <ProfileItems />
    </ProfileContainer>
  );
};

export default ProfilePage;
