import { LinearProgress, linearProgressClasses, styled } from '@mui/material';
import ColorPalette from 'utils/ColorPalette';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.filterCard,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: ColorPalette.active,
  },
}));

export default BorderLinearProgress;
