import React, { useEffect, useState } from "react";
import { useAccount, useContractReads } from "wagmi";
import { DEV_TEST_ADDRESS, FILLER_ADDRESS } from 'constants/Statics';
// import GetLogger from 'components/global/Logger';
import { ABIs, Addresses } from "../../constants/Addresses";
import { useWagmi } from "../../hooks/wagmi";
import { useAppDispatch, useAppSelector } from '../hooks';
import { setMintElvesDetails, setMintElvesRefetch, setMintMinting, setMintReady, setMintTicketsLeft } from "./reducer";
import Chains from '../../constants/wagmi/chains';

const MintUpdater = (): null => {
  // const log = GetLogger('Mint Updater');
  const { address, isCoreChain } = useWagmi();
  const wallet = DEV_TEST_ADDRESS || address || FILLER_ADDRESS;
  const mint = useAppSelector(state => state.mint)
  const dispatch = useAppDispatch();

  const elvesTicketContract = {
    address: Addresses[Chains.avalanche.id].Items721.ElvesTicket,
    abi: ABIs.Items721.ElvesTicket,
    chainId: 43114
  }

  useContractReads({
    contracts: [
      { ...elvesTicketContract, functionName: "lastTokenId" },
      { ...elvesTicketContract, functionName: "revealsPendingOf", args: [wallet] }
    ],
    allowFailure: true,
    onSuccess: ([lastTokenId, [pending, isReady]]) => {
      const remaining = 5_000 - lastTokenId.toNumber();
      if (remaining !== mint.ticketsLeft) {
        dispatch(setMintTicketsLeft(remaining));
      }
      if (pending.length !== mint.minting.length) {
        dispatch(setMintMinting(pending.map((p: any) => p.toNumber())));
      }
      if (isReady !== mint.ready) {
        dispatch(setMintReady(isReady));
      }
    },
    enabled: isCoreChain
  })

  const elvesMinterContract = {
    address: Addresses[Chains.avalanche.id].NPCs.ElvesMinter,
    abi: ABIs.NPCs.ElvesMinter,
    chainId: 43114
  }

  const { refetch } = useContractReads({
    contracts: [
      { ...elvesMinterContract, functionName: "lastTokenId" },
      { ...elvesMinterContract, functionName: "pendingMintsOf", args: [wallet] },
      { ...elvesMinterContract, functionName: "price" },
      { ...elvesMinterContract, functionName: "creditOf", args: [wallet] },
    ],
    allowFailure: true,
    onSuccess: ([lastTokenId, [pending, isReady], price, credits]) => {
      const remaining = 10_000 - lastTokenId.toNumber();
      const updates = { ...mint.elves };
      let changed = false;
      if (remaining !== updates.remaining) {
        updates.remaining = remaining;
        changed = true;
      }
      if (pending.length !== updates.pending.length) {
        updates.pending = pending.map((p: any) => p.toNumber());
        changed = true;
      }
      if (isReady !== updates.ready) {
        updates.ready = isReady;
        changed = true;
      }
      if (!updates.price.eq(price)) {
        updates.price = price;
        changed = true;
      }
      if (!updates.credits.eq(credits)) {
        updates.credits = credits;
        changed = true;
      }
      if (changed) {
        dispatch(setMintElvesDetails(updates));
      }
    },
    enabled: isCoreChain
  })

  useEffect(() => {
    if (mint.elves.refetch) {
      refetch();
      dispatch(setMintElvesRefetch(false))
    }
  }, [mint.elves.refetch])
  return null;
};

export default MintUpdater;
